<template>
  <div>
    <Teleport to="body">
      <div class="fixed top-0 z-[999] flex h-full w-full items-center justify-center">
        <div class="absolute inset-0 z-20 h-full w-full bg-black opacity-60"></div>

        <div
          ref="contextMenu"
          class="bg-light-300 rounded-medium absolute z-20 flex h-[calc(100vh_-_96px)] max-h-[760px] min-h-[174px] w-[calc(100vw_-_128px)] max-w-[600px] flex-col justify-between">
          <div
            class="rounded-t-medium border-b-dark-200/10 bg-light-100 text-light-450 text-16 mb-16 flex h-[56px] w-full items-center border-b pl-16 pr-8">
            <div class="flex w-full items-center justify-between">
              <span class="text-16 text-dark-400 font-medium">Customize Menu</span>
              <GButtonV2
                button-type="button"
                only-icon="close"
                size="semi-medium"
                type="tertiary"
                :light-mode="true"
                @click="$emit('close')"></GButtonV2>
            </div>
          </div>

          <div class="flex w-full flex-1 flex-col items-center overflow-auto">
            <div class="mb-16 flex w-full justify-end gap-8 px-16">
              <input
                v-model="input"
                placeholder="Enter menu name..."
                type="text"
                class="text-14 bg-light-100 rounded-medium text-dark-400 focus:border-light-450 h-[40px] w-full border border-solid border-transparent px-16 outline-none"
                @keyup.enter="handleAddMenu" />

              <GButtonV2 button-type="button" button-width="76px" type="tertiary" click="handleAddMenu">
                Add
              </GButtonV2>
            </div>

            <perfect-scrollbar class="w-full px-16 pb-4">
              <DropZone :drop-zone-id="idDropZoneDefault" />
              <MenuItem
                v-for="item in dataMenu?.['ROOT']?.childrens"
                :key="item"
                :data="dataMenu"
                :first-item-id="item"
                :width="568"
                @delete-item="handleDelete"
                @duplicate-item="handleDuplicate"
                @open-setting="openEditMenu">
              </MenuItem>
            </perfect-scrollbar>
          </div>

          <div
            class="bg-light-100 rounded-b-medium border-t-dark-200/10 flex h-[72px] items-center justify-end border-t !px-16">
            <GButtonV2 button-type="button" type="secondary" :light-mode="true" @click="$emit('close')"
              >Cancel</GButtonV2
            >
            <GButtonV2 ref="focusRef" button-type="button" class="ml-8" type="primary" @click="save"> Save </GButtonV2>
          </div>
        </div>

        <template v-if="showEditMenu">
          <EditMenu
            :id="editMenu.id"
            :pages="pages"
            :sections="sections"
            :title="editMenu.title"
            :link="editMenu.link"
            @change-keyword="(val) => $emit('change-keyword', val)"
            @load-more="$emit('load-more')"
            @close-setting="closeSetting"
            @save-setting="saveSetting" />
        </template>
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, reactive, ref, watch } from 'vue';
import { useOutsideClick } from '@gem/uikit';
import type { Data, Menu, Store, SubMenu } from '../../stores/menu';
import ID from '../../utils/id';
import DropZone from './DropZone.vue';
import EditMenu from './EditMenu.vue';
import MenuItem from './MenuItem.vue';
import type { PageLinkData } from './types';

const store = inject<Store>('store');
const contextMenu = ref<HTMLElement | undefined>();

const emits = defineEmits<{
  (e: 'save', dataMenu: Data): void;
  (e: 'change-keyword', input: string): void;
  (e: 'load-more'): void;
  (e: 'close'): void;
}>();

defineProps<{
  pages?: PageLinkData[];
  sections?: PageLinkData[];
}>();

const data = computed(() => store?.getters.getDataMenu());
const dataMenu = ref<Data>(JSON.parse(JSON.stringify(data.value)));
const isDragEl = computed(() => store?.getters.getItemDragIsDrag());
const idDragEl = computed(() => store?.getters.getItemDragId() ?? '');
const idDropZone = computed(() => store?.getters.getDropZoneId() ?? '');
const isChildren = computed(() => store?.getters.getItemDragIsChildren());
const root = ref(dataMenu.value['ROOT'].childrens);
const input = ref('');
const idDropZoneDefault = ID();
const showEditMenu = ref(false);
const editMenu = reactive({
  id: '',
  title: '',
  link: '',
});

watch(isDragEl, (newVal, oldVal) => {
  if (newVal === oldVal) return;
  if (newVal === false) {
    handleMoveMenu(dataMenu.value, idDragEl.value, idDropZone.value, isChildren.value);
  }
});

const closeContextMenu = () => {
  // emits('close');
};
useOutsideClick(contextMenu, closeContextMenu, { detectIframe: true });
// Create
const handleAddMenu = () => {
  const id = ID();

  const newMenu: SubMenu = {
    id: id,
    title: input.value ? input.value : 'blank',
    link: '',
    childrens: [],
  };

  dataMenu.value['ROOT'].childrens.push(id);
  dataMenu.value[id] = newMenu;
  input.value = '';
};

// Append
const handleMoveMenu = (data: Data, id: string, toId: string, isChildren?: boolean) => {
  if (toId) {
    const parent = Object.entries(data).find(([, value]) => value?.childrens?.includes(id));
    const parentToId = Object.entries(data).find(([, value]) => value?.childrens?.includes(toId));
    const positionToId = getPositionId(data, toId) ?? 0;
    const itemToId = data[toId];

    if (data['ROOT'].childrens.indexOf(id) !== -1) {
      // Check id in ROOT
      const positionIdRoot = data['ROOT'].childrens.indexOf(toId);

      if (isChildren) {
        if (positionIdRoot !== -1) {
          data['ROOT'].childrens = data['ROOT'].childrens.filter((child) => child !== id);
          itemToId.childrens?.splice(0, 0, id);
        } else {
          if (parent && parentToId) {
            const [, parentValue] = parent;

            parentValue.childrens = parentValue.childrens?.filter((child) => child !== id);
            itemToId.childrens?.splice(positionToId, 0, id);
          }
        }
      } else {
        const positionId = data['ROOT'].childrens.indexOf(id);

        if (positionIdRoot !== -1) {
          const position = positionId < positionIdRoot ? positionIdRoot : positionIdRoot + 1;

          data['ROOT'].childrens = data['ROOT'].childrens.filter((child) => child !== id);
          data['ROOT'].childrens.splice(position, 0, id);
        } else if (toId === idDropZoneDefault) {
          // First menu dropdown outside component item
          data['ROOT'].childrens = data['ROOT'].childrens.filter((child) => child !== id);
          data['ROOT'].childrens.splice(0, 0, id);
        } else {
          if (parentToId) {
            const [, parentToIdValue] = parentToId;

            data['ROOT'].childrens = data['ROOT'].childrens.filter((child) => child !== id);
            parentToIdValue.childrens.splice(positionToId + 1, 0, id);
          }
        }
      }
    } else {
      if (toId === idDropZoneDefault && parent) {
        const [, parentValue] = parent;

        parentValue.childrens = parentValue.childrens?.filter((child) => child !== id);
        data['ROOT'].childrens.splice(0, 0, id);
      } else if (parent && parentToId) {
        const [, parentValue] = parent;
        const [, parentToIdValue] = parentToId;
        const positionId = getPositionId(data, id) ?? 0;
        if (isChildren) {
          if (data['ROOT'].childrens.indexOf(id) !== -1) {
            parentValue.childrens = parentValue.childrens?.filter((child) => child !== id);
            data['ROOT'].childrens.splice(positionToId + 1, 0, id);
          } else {
            parentValue.childrens = parentValue.childrens?.filter((child) => child !== id);
            itemToId.childrens?.splice(0, 0, id);
          }
        } else {
          let position = 0;
          if (parentToIdValue.childrens?.indexOf(id) !== -1 && parentToIdValue.childrens?.indexOf(id) !== -1) {
            // 2 Item in childrens
            position = positionId < positionToId ? positionToId : positionToId + 1;
          } else {
            position = positionToId + 1;
          }

          parentValue.childrens = parentValue.childrens?.filter((child) => child !== id);
          parentToIdValue.childrens?.splice(position, 0, id);
        }
      }
    }
  }

  store?.actions?.setDropZoneId('');
};

const getPositionId = (data: Menu, id: string) => {
  const parent = Object.entries(data).find(([, value]) => value?.childrens?.includes(id));

  if (parent) {
    const [, parentValue] = parent;
    return parentValue.childrens?.indexOf(id);
  }
};

// Delete
const handleDelete = (id: string) => {
  handleDeleteMenu(id, dataMenu.value);
};

const handleDeleteMenu = (id: string, data: Data) => {
  const parent = Object.entries(data).find(([, value]) => value?.childrens?.includes(id));

  if (parent) {
    const [, parentValue] = parent;
    parentValue.childrens = parentValue.childrens?.filter((child) => child !== id);
  }
  removeItem(id, data);

  if (root.value.indexOf(id) !== -1) {
    root.value.splice(root.value.indexOf(id), 1);
  }
};

const removeItem = (id: string, data: Menu) => {
  const item = data[id];

  if (item) {
    delete data[id];
    if (item.childrens?.length) {
      item.childrens.map((child: string) => removeItem(child, data));
    }
  }
};

// Duplicate
const handleDuplicate = (id: string) => {
  handleDuplicateMenu(dataMenu.value, id);
};

const handleDuplicateMenu = (data: Menu, id: string) => {
  const parent = Object.entries(data).find(([, value]) => value?.childrens?.includes(id));
  const item = dataMenu.value[id];
  const idDuplicate = ID();

  const itemDuplicate: SubMenu = {
    id: idDuplicate,
    title: item.title + ' copy',
    link: item.link ?? '',
    childrens: [],
  };

  if (parent) {
    // Duplicate id in parent
    const [, parentValue] = parent;
    const positionId = getPositionId(data, id) ?? 0;
    parentValue.childrens?.splice(positionId + 1, 0, idDuplicate);
    const dataCopy = JSON.parse(JSON.stringify(dataMenu.value));

    // Duplicate id childrens
    if (item.childrens && item.childrens.length > 0) {
      const arr: string[] = [];
      for (const i of item.childrens) {
        const newID = changeIdDuplicate(i, data, dataCopy);
        if (newID) arr.push(newID);
      }
      dataMenu.value = dataCopy;

      if (arr.length) {
        itemDuplicate.childrens = arr;
      }

      dataMenu.value[idDuplicate] = itemDuplicate;
    } else {
      dataMenu.value[idDuplicate] = itemDuplicate;
    }
  }
};

const changeIdDuplicate = (id: string, data: Menu, dataCopy: Menu) => {
  const item = data[id];

  if (item) {
    const newId = ID();

    const newItem: SubMenu = {
      id: newId,
      title: item.title,
      link: item.link ?? '',
      childrens: [],
    };

    if (item.childrens?.length) {
      const ids: string[] = [];
      item.childrens.map((child: string) => {
        const newChildID = changeIdDuplicate(child, data, dataCopy);
        if (newChildID) ids.push(newChildID);
      });

      if (ids.length > 0) {
        newItem.childrens = ids;
      }
    }

    dataCopy[newId] = newItem;
    return newId;
  }
};

// Edit Menu
const openEditMenu = (value: { title: string; link: string; id: string }) => {
  editMenu.id = value.id;
  editMenu.title = value.title;
  editMenu.link = value.link;
  showEditMenu.value = true;
};

const closeSetting = () => {
  showEditMenu.value = false;
};

const saveSetting = (value: { id: string; title: string; link: string }) => {
  dataMenu.value[value.id].title = value.title;
  dataMenu.value[value.id].link = value.link;
  showEditMenu.value = false;
};

// Save
const save = () => {
  store?.actions.setDataMenu(dataMenu.value);
  emits('save', dataMenu.value);
};
</script>
