<script lang="ts" setup>
import { computed } from 'vue';
import type { ScreenType } from '../../../types';
import OneColSvg from '../icons/OneCol.vue';
import ContentLeftSvg from '../icons/ContentLeft.vue';
import ContentRightSvg from '../icons/ContentRight.vue';

type LayoutValue = {
  display?: string;
  cols?: number[];
};

const props = withDefaults(
  defineProps<{
    value?: LayoutValue;
    fallback?: LayoutValue;
    maxCol?: number;
    hasSwapted?: boolean;
    device?: ScreenType;
  }>(),
  {
    maxCol: 1,
  },
);

const columnWidth = computed(() => props.value?.display);
const emit = defineEmits<{
  (e: 'change', value: number): void;
  (e: 'swap-order', contentSide: 'left' | 'right'): void;
}>();

const handleChangeLayout = (cols: number) => {
  emit('change', cols);
};
const swapOrder = (contentSide: 'left' | 'right') => {
  emit('swap-order', contentSide);
};
const swapted = computed(() => props.hasSwapted);

const layoutData = computed(() => [
  {
    active: props.value?.cols?.length === 1 && columnWidth.value === 'fill',
    icon: OneColSvg,
    name: 'Content center',
    onClick: () => {
      handleChangeLayout(1);
    },
  },
  {
    active: props.value?.cols?.length === 2 && !swapted.value && columnWidth.value === 'fill',
    icon: ContentLeftSvg,
    hidden: props.maxCol <= 1 && props.device !== 'desktop',
    name: 'Content left',
    onClick: () => {
      handleChangeLayout(2);
      swapOrder('left');
    },
  },
  {
    active: props.value?.cols?.length === 2 && swapted.value && columnWidth.value === 'fill',
    icon: ContentRightSvg,
    hidden: props.maxCol <= 1 && props.device !== 'desktop',
    name: 'Content right',
    onClick: () => {
      handleChangeLayout(2);
      swapOrder('right');
    },
  },
]);
</script>

<template>
  <slot></slot>
  <div class="grid w-full grid-cols-3 gap-[13px]">
    <div v-for="(item, index) in layoutData" v-show="!item.hidden" :key="index">
      <g-tooltip placement="top" class="w-full">
        <div
          class="bg-dark-400 [&_svg_.trunk]:hover:!fill-light-200 [&_svg_.sub]:hover:!fill-light-500 [&_svg_.background]:hover:!fill-dark-50 group flex h-[58px] w-full cursor-pointer items-center justify-center overflow-hidden rounded-xl border-[1px] border-transparent"
          :class="{
            'border-primary-300 active [&_svg_.trunk]:!fill-light-200 [&_svg_.sub]:!fill-light-500 [&_svg_.background]:!fill-dark-50':
              item.active,
          }"
          @click="!item.active && item.onClick()">
          <component :is="item.icon" />
        </div>
        <template #content>
          <div class="text-light-100 text-12">{{ item.name }}</div>
        </template>
      </g-tooltip>
    </div>
  </div>
</template>
