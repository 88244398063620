<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="gemx-control gemx-control-input-number">
      <div class="gemx-control-input-number_container">
        <input
          v-model="val"
          type="number"
          class="caret-primary-300 text-12 text-light-450 placeholder:text-text-dark-100 focus:border-primary-300 disabled:border-dark-200 disabled:text-text-dark-100 bg-dark-400 hover:bg-dark-200 h-32 w-full rounded-xl border border-transparent px-8 outline-none transition duration-200 hover:border-transparent disabled:cursor-not-allowed"
          :placeholder="placeholder"
          :disabled="readonly"
          :class="[inputClass]"
          @input="onChange"
          @change="change"
          @blur="blur" />
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

type PropsType = {
  id?: string;
  value?: number;
  placeholder?: string;
  readonly?: boolean;
  min?: number;
  max?: number;
  inputClass?: string;
  controlChange?: (id: string, value?: any) => void;
};

const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  readonly: false,
  placeholder: '',
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
}>();

const val = ref<number>(props.value ?? 0);

watch(
  () => props.value,
  (v) => {
    if (v !== undefined) {
      val.value = v;
    }
  },
);

watch(
  () => props.value,
  (newPropValue) => {
    val.value = newPropValue || 0;
  },
);

const checkRangeValue = (value: number): number => {
  if (value > props.max) return props.max;
  if (value < props.min) return props.min;
  return value;
};

const onChange = () => {
  if (val.value > props.max || props.min > val.value) {
    return;
  }
  emit('controlOnChange', props.id, checkRangeValue(val.value));
};
const change = () => {
  if (val.value > props.max || props.min > val.value) {
    return;
  }
  props.controlChange?.(props.id, checkRangeValue(val.value));
};
const blur = () => {
  val.value = checkRangeValue(val.value);
};
</script>

<style lang="scss" scoped>
$background: #242424;
$backgroundHover: #424242;
$text: #e2e2e2;
$textShade2: #7f7f7f;
$textShade3: #dadada;

$blue: #839bc0;

.gemx-control-input-number {
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-moz-placeholder,
  :-ms-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $textShade2;
  }

  .gemx-control-input-number_container {
    position: relative;
  }

  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  button[disabled] {
    border: none;
    background-color: #f0f3f6;

    svg path {
      fill: #c5cbd7;
    }

    &:hover {
      cursor: not-allowed;
      background-color: #f0f3f6;

      svg path {
        fill: #c5cbd7;
      }
    }
  }
}
</style>
