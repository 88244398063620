<script lang="ts" setup>
import type { GradientPoint, GradientType } from '../../types';
import type { THEME_DARK, THEME_LIGHT } from '../../const';

import { ref, computed, onMounted, onUnmounted } from 'vue';

import { Color } from '../../Color';
import Point from './Point.vue';
import { strictInject } from '../../helpers';
import { GRADIENT_PROVIDE_KEY } from '../../composables/useGradientColor';

const DEFAULT_POINT_COLOR = '#FFFFFF';

const props = defineProps<{
  theme: typeof THEME_DARK | typeof THEME_LIGHT;
  points?: Map<string, GradientPoint>;
  type?: GradientType;
  angle?: number;
}>();

const emit = defineEmits<{
  (e: 'show-warning-message'): void;
}>();

const { addPoint, removePoint, selectedPointID } = strictInject(GRADIENT_PROVIDE_KEY);

const previewArea = ref<HTMLDivElement>();
const showDeletedColor = ref<boolean>(true);
const orderedPoints = computed(() => {
  return [...(props.points?.values() ?? [])].sort((a, b) => a.position - b.position);
});
const gradient = computed(() => {
  return orderedPoints.value.map((item) => `${item.color.getColor()} ${item.position}%`).join(', ');
});
const controlStyle = computed(() => {
  return { backgroundImage: `linear-gradient(${props.angle}deg, ${gradient.value})` };
});

const onAddColor = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();

  if (!e.target || !(e.target as HTMLElement).classList.contains('preview-area')) {
    return;
  }

  if (!props.points || props.points.size >= 5) {
    emit('show-warning-message');
    return;
  }

  const ADDITIONAL_HEIGHT_TO_EXPAND_CLICK_AREA = 10;
  const rect = previewArea.value?.getBoundingClientRect();
  if (rect) {
    const x = e.clientX - rect.x;
    const y = e.clientY - rect.y;
    if (
      y < 0 - ADDITIONAL_HEIGHT_TO_EXPAND_CLICK_AREA ||
      y > rect.height + ADDITIONAL_HEIGHT_TO_EXPAND_CLICK_AREA ||
      x < 0 ||
      x > rect.width
    ) {
      return;
    }

    const position = Math.round((x * 100) / rect.width);
    const point: GradientPoint = {
      position,
      color: Color.fromString(DEFAULT_POINT_COLOR),
    };
    const higher = orderedPoints.value.findIndex((v) => v.position > position);
    if (higher === 0) {
      point.color = orderedPoints.value[0].color;
    } else if (higher === -1) {
      if (orderedPoints.value.length > 0) {
        point.color = orderedPoints.value[orderedPoints.value.length - 1].color;
      } else {
        point.color = Color.fromString(DEFAULT_POINT_COLOR);
      }
    } else {
      const start = orderedPoints.value[higher - 1];
      const end = orderedPoints.value[higher];
      const prec = Math.round(((position - start.position) / (end.position - start.position)) * 100);
      point.color = Color.findColorBetween(
        Color.fromString(start.color.getColor()),
        Color.fromString(end.color.getColor()),
        prec,
      );
    }
    point.color.setAlpha(1);
    addPoint(point);
  }
};

const onRemoveColor = (pointID: string) => {
  removePoint(pointID);
};
const onShowDeletedColor = (value: boolean) => {
  showDeletedColor.value = value;
};

onMounted(() => {
  previewArea.value?.addEventListener('mousedown', (e) => {
    onAddColor(e);
  });
});

onUnmounted(() => {
  previewArea.value?.removeEventListener('mousedown', onAddColor);
});
</script>

<template>
  <div class="transparent-img" />
  <div v-if="points" ref="previewArea" class="preview-area relative h-8 cursor-copy rounded-full" :style="controlStyle">
    <template v-for="[pointKey, gradientPoint] in points.entries()" :key="pointKey">
      <Point
        v-if="gradientPoint.color"
        :key="pointKey"
        :theme="theme"
        :area="previewArea"
        :point-i-d="pointKey"
        :color="gradientPoint.color.getColor()"
        :is-transparent="gradientPoint.color.a === 0"
        :position="gradientPoint.position"
        :is-selected-point="pointKey == selectedPointID"
        :length="points.size || 2"
        :show-deleted-color="showDeletedColor"
        @on-show-deleted-color="onShowDeletedColor"
        @on-remove-color="onRemoveColor" />
    </template>
  </div>
</template>

<style scoped>
.transparent-img {
  position: relative;
  width: 194px;
  height: 8px;
  top: 8px;
  left: 0;
  background-image: url('../../../../assets/transparent.png');
  background-size: 38px;
  border-radius: 999px;
  z-index: 0;
}
.preview-area::after,
.preview-area::before {
  content: ' ';
  position: absolute;
  width: 194px;
  height: 10px;
}
.preview-area::after {
  bottom: -10px;
}
.preview-area::before {
  top: -10px;
}
</style>
