import type { SpacingOption } from './types';
import { type SpacingType } from './types';
export const spacing: SpacingType = {
  auto: 'auto',
  xxs: '--g-s-xxs',
  xs: '--g-s-xs',
  s: '--g-s-s',
  m: '--g-s-m',
  l: '--g-s-l',
  xl: '--g-s-xl',
  '2xl': '--g-s-2xl',
  '3xl': '--g-s-3xl',
  '4xl': '--g-s-4xl',
  '5xl': '--g-s-5xl',
};

function isEmpty<T>(obj: T extends Object ? T : never) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const initialValue = (value?: string) => {
  if (!value || isEmpty(value)) return '0';
  if (value === 'auto') return 'auto';
  if (value === '-px') return '-';
  return Object.keys(spacing).find((key) => spacing[key as keyof SpacingType] === value.slice(4, value.length - 1));
};

export const createInitVal = (value?: string) => {
  if (!value) return '0';
  if (isNaN(parseInt(value))) {
    return initialValue(value);
  } else {
    return `${parseInt(value)}`;
  }
};

export const createNumber = (initValue?: string, globalValue?: SpacingOption) => {
  if (initValue === 'auto') {
    return 'Auto';
  }
  if (globalValue) {
    return `${parseFloat(globalValue?.value as string)}`;
  }
  return initValue ?? '0';
};
