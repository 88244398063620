import type { Emitter } from 'mitt';
import mitt from 'mitt';
type Events = {
  'publish-success': void;
  'publish-success-notify': void;
  'publish-error': {
    errorCode: Number;
    errorMessage: String;
    overrideRetry?: Record<string, any>;
    componentsUid?: Record<'uid' | 'message', string>[];
  };
  'save-success': void;
  'init-template-success': void;
  'save-error': void;
  'toolbar-active-refresh': void;
  'force-parent-active': { direction: any; component: any };
  'component-update-setting': { uid: string; controlId: string };
  'on-change-link-of-gem-ai': string;
  'set-image-of-gem-ai': File | undefined;
  'generate-link-to-layout': 'main' | 'panel' | '' | 'tool-bar' | undefined;
  'set-images-detection-for-select': string[];
  'detect-image-error': string;
  'retry-detect-image': void;
  'set-publish-btn-status': boolean;
  'editor-undo-redo': { newValue: string; componentUid: string };
  'on-change-spacing': {
    spacing: {
      desktop?: {
        margin: {
          bottom: string;
        };
      };
      tablet?: {
        margin: {
          bottom: string;
        };
      };
      mobile?: {
        margin: {
          bottom: string;
        };
      };
    } | null;
  };
  'on-change-preview-version-loading': boolean;
  'build-with-section-loaded-success': string;
  'request-sync-product-source': any;
};

export const event: Emitter<Events> = mitt<Events>();
