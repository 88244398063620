<template>
  <div>
    <UseDraggable
      :initial-value="position"
      :class="{ fixed: firstItemId === idClassDrag }"
      :style="{ width: props.width + 'px' }"
      :data-id="firstItemId"
      class="text-14 rounded-medium bg-light-100 text-dark-400 z-10 mb-8 flex h-[40px] cursor-move select-none items-center justify-between pl-[8px] pr-16 transition-[background] duration-[150ms]"
      @mousedown.stop="onMousedown($event, firstItemId)"
      @mousemove.stop="onMousemove"
      @mouseup.stop="onMouseup(firstItemId)">
      <div class="flex items-center">
        <span
          v-if="data?.[firstItemId]?.childrens?.length"
          class="flex h-[32px] w-[32px] cursor-pointer items-center justify-center py-4 transition-all duration-[200ms]"
          :style="subMenu.indexOf(firstItemId) != -1 ? 'transform: rotate(-90deg);' : ''"
          @mousedown.stop="handleSubMenu(firstItemId)">
          <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L6 5.29289L10.6464 0.646447C10.8417 0.451184 11.1583 0.451184 11.3536 0.646447C11.5488 0.841709 11.5488 1.15829 11.3536 1.35355L6.35355 6.35355C6.15829 6.54882 5.84171 6.54882 5.64645 6.35355L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
              fill="#212121" />
          </svg>
        </span>
        <div class="flex flex-col" :class="data?.[firstItemId]?.childrens?.length ? 'ml-4' : 'ml-8'">
          <span class="text-12 text-dark-400 font-medium leading-[14px]">
            {{ data?.[firstItemId]?.title }}
          </span>
          <span class="text-10 text-text-dark-300">{{ data?.[firstItemId]?.link }}</span>
        </div>
      </div>
      <div class="flex items-center">
        <span
          aria-label="Pick link"
          class="rounded-medium flex h-[32px] w-[32px] cursor-pointer items-center justify-center hover:bg-black/10"
          @mousedown.stop="handleOpenSetting(data?.[firstItemId]?.title, data?.[firstItemId]?.link ?? '', firstItemId)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 1C3.27614 1 3.5 1.22386 3.5 1.5L3.5 6.63663C4.36014 6.86034 4.99512 7.6422 4.99512 8.57236C4.99512 9.50252 4.36013 10.2844 3.5 10.5081L3.5 14.5C3.5 14.7761 3.27614 15 3 15C2.72386 15 2.5 14.7761 2.5 14.5L2.5 10.5106C1.63491 10.2903 0.995117 9.50603 0.995117 8.57236C0.995117 7.63868 1.63491 6.85443 2.5 6.63412L2.5 1.5C2.5 1.22386 2.72386 1 3 1ZM1.99512 8.57236C1.99512 8.02007 2.44283 7.57236 2.99512 7.57236C3.5474 7.57236 3.99512 8.02007 3.99512 8.57236C3.99512 9.12464 3.5474 9.57236 2.99512 9.57236C2.44283 9.57236 1.99512 9.12464 1.99512 8.57236Z"
              fill="#212121" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.29976 1.00009C8.5759 1.00009 8.79976 1.22395 8.79976 1.50009V4.20948C9.66113 4.43235 10.2973 5.2148 10.2973 6.14584C10.2973 7.07688 9.66113 7.85934 8.79976 8.0822L8.79976 14.5001C8.79976 14.7762 8.5759 15.0001 8.29976 15.0001C8.02362 15.0001 7.79976 14.7762 7.79976 14.5001L7.79976 8.08346C6.93591 7.86229 6.29732 7.07864 6.29732 6.14584C6.29732 5.21305 6.93591 4.4294 7.79976 4.20823V1.50009C7.79976 1.22395 8.02362 1.00009 8.29976 1.00009ZM8.29732 5.14584C7.74503 5.14584 7.29732 5.59356 7.29732 6.14584C7.29732 6.69813 7.74503 7.14584 8.29732 7.14584C8.8496 7.14584 9.29732 6.69813 9.29732 6.14584C9.29732 5.59356 8.8496 5.14584 8.29732 5.14584Z"
              fill="#212121" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5 1.50009C13.5 1.22395 13.2761 1.00009 13 1.00009C12.7239 1.00009 12.5 1.22395 12.5 1.50009V8.74685C11.6374 8.96887 11 9.75192 11 10.6838C11 11.6158 11.6374 12.3988 12.5 12.6208V14.5C12.5 14.7761 12.7239 15 13 15C13.2761 15 13.5 14.7761 13.5 14.5V12.6208C14.3626 12.3988 15 11.6158 15 10.6838C15 9.75192 14.3626 8.96887 13.5 8.74685V1.50009ZM12 10.6838C12 10.1316 12.4477 9.68384 13 9.68384C13.5523 9.68384 14 10.1316 14 10.6838C14 11.2361 13.5523 11.6838 13 11.6838C12.4477 11.6838 12 11.2361 12 10.6838Z"
              fill="#212121" />
          </svg>
        </span>
        <span
          aria-label="Duplicate"
          class="rounded-medium ml-4 flex h-[32px] w-[32px] cursor-pointer items-center justify-center hover:bg-black/10"
          @mousedown.stop="handleDuplicate(firstItemId)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.27166 1.24512C5.44323 1.24512 4.77166 1.91669 4.77166 2.74512V3.75439H4.00146C3.17304 3.75439 2.50146 4.42597 2.50146 5.25439V13.2544C2.50146 14.0828 3.17304 14.7544 4.00146 14.7544H10.0015C10.8299 14.7544 11.5015 14.0828 11.5015 13.2544V12.9951H12.4998C13.3282 12.9951 13.9998 12.3235 13.9998 11.4951V2.74512C13.9998 1.91669 13.3282 1.24512 12.4998 1.24512H6.27166ZM10.5015 12.9951H6.27166C5.44323 12.9951 4.77166 12.3235 4.77166 11.4951V4.75439H4.00146C3.72532 4.75439 3.50146 4.97825 3.50146 5.25439V13.2544C3.50146 13.5305 3.72532 13.7544 4.00146 13.7544H10.0015C10.2776 13.7544 10.5015 13.5305 10.5015 13.2544V12.9951ZM5.77166 2.74512C5.77166 2.46897 5.99552 2.24512 6.27166 2.24512H12.4998C12.776 2.24512 12.9998 2.46897 12.9998 2.74512V11.4951C12.9998 11.7713 12.776 11.9951 12.4998 11.9951H6.27166C5.99552 11.9951 5.77166 11.7713 5.77166 11.4951V2.74512Z"
              fill="#212121" />
          </svg>
        </span>
        <span
          aria-label="Delete"
          class="hover:text-light-100 rounded-medium ml-4 flex h-[32px] w-[32px] cursor-pointer items-center justify-center text-red-300 hover:bg-red-300/90"
          @mousedown.stop="handleDelete(firstItemId)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.5 6C6.77614 6 7 6.22386 7 6.5V11.5C7 11.7761 6.77614 12 6.5 12C6.22386 12 6 11.7761 6 11.5V6.5C6 6.22386 6.22386 6 6.5 6Z"
              fill="currentColor" />
            <path
              d="M9.5 6C9.77614 6 10 6.22386 10 6.5V11.5C10 11.7761 9.77614 12 9.5 12C9.22386 12 9 11.7761 9 11.5V6.5C9 6.22386 9.22386 6 9.5 6Z"
              fill="currentColor" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11 2.5V3H14C14.2761 3 14.5 3.22386 14.5 3.5C14.5 3.77614 14.2761 4 14 4H13V12.5C13 13.8807 11.8807 15 10.5 15H5.5C4.11929 15 3 13.8807 3 12.5V4H2C1.72386 4 1.5 3.77614 1.5 3.5C1.5 3.22386 1.72386 3 2 3H5V2.5C5 1.67157 5.67157 1 6.5 1H9.5C10.3284 1 11 1.67157 11 2.5ZM4 4V12.5C4 13.3284 4.67157 14 5.5 14H10.5C11.3284 14 12 13.3284 12 12.5V4H4ZM6 3V2.5C6 2.22386 6.22386 2 6.5 2H9.5C9.77614 2 10 2.22386 10 2.5V3H6Z"
              fill="currentColor" />
          </svg>
        </span>
      </div>
    </UseDraggable>
    <DropZone v-if="idDragEl !== firstItemId" :drop-zone-id="firstItemId" />
    <g-collapse-transition :duration="200">
      <div v-if="subMenu.indexOf(firstItemId) === -1">
        <div v-if="data?.[firstItemId]?.childrens?.length > 0">
          <SubMenuItem
            :data="data"
            :child-id="data?.[firstItemId]?.childrens"
            :width="width - 40"
            @delete-item="handleDeleteItem"
            @duplicate-item="handleDuplicateItem"
            @open-setting="openSetting" />
        </div>
      </div>
    </g-collapse-transition>
  </div>
</template>

<script lang="ts" setup>
import { UseDraggable } from '@vueuse/components';
import { GCollapseTransition } from '@gem/uikit';
import { computed, inject, ref } from 'vue';
import type { Data, Store } from '../../stores/menu';
import DropZone from './DropZone.vue';
import SubMenuItem from './SubMenuItem.vue';

const emits = defineEmits<{
  (e: 'deleteItem', id: string): void;
  (e: 'duplicateItem', id: string): void;
  (e: 'openSetting', value: { title: string; link: string; id: string }): void;
}>();

const store = inject<Store>('store');

const props = defineProps<{
  data: Data;
  firstItemId: string;
  spacing?: number;
  width: number;
}>();

const subMenu = ref<string[]>([]);
const dragEl = ref<string[]>([]);
const isDragEl = ref(false);
const isSubMenu = ref(false);
const idDragEl = ref('');
const position = ref({
  x: 0,
  y: 0,
});
const idClassDrag = computed(() => store?.getters?.getItemDragIdDrag());
const isMouseMove = computed(() => store?.getters?.getItemDragMouseMove());

const handleSubMenu = (id: string) => {
  if (subMenu.value.indexOf(id) === -1) {
    subMenu.value.push(id);
  } else {
    subMenu.value.splice(subMenu.value.indexOf(id), 1);
  }
};

// Mouse Event
const onMousedown = (e: MouseEvent, id: string) => {
  const clientRect = getClientRect(id);
  const height = clientRect?.height ?? 0;
  const width = clientRect?.width ?? 0;
  const itemDragX = clientRect?.x ?? 0;
  const itemDragY = clientRect?.y ?? 0;

  if (subMenu.value.indexOf(id) == -1) {
    isSubMenu.value = true;
    subMenu.value.push(id);
  }

  isDragEl.value = true;
  idDragEl.value = id;
  dragEl.value.push(id);

  store?.actions.setItemDragX(itemDragX);
  store?.actions.setItemDragY(itemDragY);
  store?.actions.setItemDragIsDrag(true);
  store?.actions.setItemDragId(id);
  store?.actions.setItemDragHeight(height);
  store?.actions.setItemDragIdDrag(id);

  position.value.x = e.clientX - width / 2;
  position.value.y = e.clientY - height / 2;
};

const onMousemove = () => {
  if (!isMouseMove.value && isDragEl.value) {
    store?.actions.setItemDragMouseMove(true);
  } else if (isDragEl.value) {
    const clientRect = getClientRect(idDragEl.value);
    const itemDragX = clientRect?.x ?? 0;
    const itemDragY = clientRect?.y ?? 0;
    store?.actions.setItemDragX(itemDragX);
    store?.actions.setItemDragY(itemDragY);
  }
};

const onMouseup = (id: string) => {
  if (isSubMenu.value) {
    isSubMenu.value = false;
    subMenu.value.splice(subMenu.value.indexOf(id), 1);
  }

  idDragEl.value = '';
  isDragEl.value = false;
  dragEl.value.splice(dragEl.value.indexOf(id), 1);
  store?.actions.setItemDragIsDrag(false);
  store?.actions.setItemDragIdDrag('');

  store?.actions.setItemDragMouseMove(false);
};
// End Mouse Event

const getClientRect = (id: string) => {
  const rect = document.querySelector(`[data-id='${id}']`);
  return rect?.getBoundingClientRect();
};

const handleDelete = (id: string) => {
  emits('deleteItem', id);
};

const handleDeleteItem = (id: string) => {
  emits('deleteItem', id);
};

const handleDuplicate = (id: string) => {
  emits('duplicateItem', id);
};

const handleDuplicateItem = (id: string) => {
  emits('duplicateItem', id);
};

const handleOpenSetting = (title: string, link: string, id: string) => {
  emits('openSetting', { title, link, id });
};

const openSetting = (value: { title: string; link: string; id: string }) => {
  emits('openSetting', value);
};
</script>
