<script lang="ts" setup>
import { ref, watch } from 'vue';
import type { ProductOption, SelectedOption } from '../type/common';
import Select from './Select.vue';

type ProductSetting = {
  productId?: string;
  initialVariantId?: string;
  initialVariantBaseId?: string;
  productHandle?: string;
};

type Props = {
  id?: string;
  value?: ProductSetting;
  options?: ProductOption[];
  variants?: SelectedOption[];
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'change-variants', value?: any): void;
}>();

const variantSelected = ref<{ [key: string]: any }>(
  props.variants?.reduce((a, b) => {
    if (b.name) {
      return { ...a, [b.name]: b.value };
    }
    return a;
  }, {}) ?? {},
);

watch(
  () => props.variants,
  (newVariants) => {
    variantSelected.value =
      newVariants?.reduce((a, b) => {
        if (b.name) {
          return { ...a, [b.name]: b.value };
        }
        return a;
      }, {}) ?? {};
  },
);

const changeValueSelect = (controlId?: string | number, value?: any) => {
  if (controlId) {
    variantSelected.value[controlId] = value;
    if (Object.getOwnPropertyNames(variantSelected.value).length === props.options?.length) {
      let variantValue = '';
      props.options?.map(
        (v, i) =>
          (variantValue +=
            i === props.options?.length! - 1 ? variantSelected.value[v.name!] : variantSelected.value[v.name!] + ' / '),
      );
      emit('change-variants', variantValue.trim());
    }
  }
};
</script>
<template>
  <div v-if="options">
    <div v-for="(v, i) in options" :key="i" class="flex items-center justify-between">
      <div class="text-12 font-regular text-text-dark-300 mb-16">
        {{ v.name }}
      </div>
      <Select
        :id="v.name ?? ''"
        class="mb-16 !w-[140px]"
        :value="variantSelected[v.name ?? '']"
        :options="v.values.map((vl) => ({ label: vl.label!, value: vl.label! }))"
        @control-change="changeValueSelect"></Select>
    </div>
  </div>
</template>
