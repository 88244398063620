import type { Ref } from 'vue';
import type { FontName, globalStyleFontProps } from '../types';

export const useResolveFontValue = (themeFont: Ref<globalStyleFontProps | undefined>) => {
  return (fontValue?: string): string | undefined => {
    if (fontValue?.includes('--g-theme-font')) {
      const typeValue = fontValue.replace('--g-theme-font-', '') as FontName;
      return themeFont.value?.[typeValue]?.family || '';
    }
    return fontValue;
  };
};
