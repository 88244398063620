<script lang="ts" setup>
import { computed } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import type { OptionSelect } from '@gem/uikit';
import { getLabel } from '../common';
import Wrapper from '../Wrapper/Index.vue';
import PopoverContent from '../Wrapper/PopoverContent.vue';
import DateTimePickers from '../../date-picker/DateTimePickers.vue';
import type { CreationDateTrigger } from '../type';
import { CompareType, CreationDateType } from '../constant';
import dayjs from '@gem/uikit/src/helpers/dayjs';
import InputBadge from '../common/InputBadge.vue';

const props = defineProps<{
  data: CreationDateTrigger;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onDelete'): void;
  (event: 'onUpdate', data: CreationDateTrigger): void;
}>();

const DATE_OPTIONS: OptionSelect[] = [
  {
    id: '7',
    name: 'A week',
    des: '7 days',
  },
  {
    id: '30',
    name: 'A month',
    des: '30 days',
  },
];

const CREATION_OPTIONS = [
  {
    id: CreationDateType.DURATION,
    name: getLabel(CreationDateType.DURATION),
    value: CreationDateType.DURATION,
  },
  {
    id: CreationDateType.EXACT_DATE,
    name: getLabel(CreationDateType.EXACT_DATE),
    value: CreationDateType.EXACT_DATE,
  },
  {
    id: CreationDateType.BETWEEN_DATES,
    name: getLabel(CreationDateType.BETWEEN_DATES),
    value: CreationDateType.BETWEEN_DATES,
  },
];

const handleSelectDateType = (value: CreationDateType) => {
  emit('onUpdate', {
    ...props.data,
    type: value,
    conditionType: value === CreationDateType.EXACT_DATE ? CompareType.EXACT : CompareType.BETWEEN,
  });
};

const handleChangeDate = useDebounceFn((controlId: string, value: string | Date) => {
  emit('onUpdate', {
    ...props.data,
    [controlId]: value,
  });
}, 300);

const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const subTitle = computed(() => {
  const { days, from, to, type } = props.data;
  if (type === CreationDateType.DURATION) {
    if (Number(days) <= 7) return 'A week ago';
    return `${days} days ago`;
  }
  if (type === CreationDateType.EXACT_DATE && from) {
    return `On ${dayjs(from).format('MMM DD YYYY')}`;
  }
  if (type === CreationDateType.BETWEEN_DATES) {
    if (!from && !to) return 'Select date';
    if (!from || !to) return 'Unknown date';
    return `${from ? dayjs(from).format('MMM DD YYYY') : ''} - ${to ? dayjs(to).format('MMM DD YYYY') : ''}`;
  }
  return 'Select date';
});

const message = computed(() => {
  const { type, from, to, days } = props.data;
  if (type === CreationDateType.DURATION && Number(days) < 1) return 'Amount number must bigger than 1';
  if (type !== CreationDateType.BETWEEN_DATES) return '';
  if (dayjs(from).isAfter(dayjs(to).add(-1, 'day'))) return 'The 1st number must be smaller than 2nd number';
  return '';
});
</script>

<template>
  <Wrapper title="Creation date" :sub-title="subTitle" :is-loading="isLoading" @on-delete="emit('onDelete')">
    <PopoverContent title="Type">
      <div class="w-[140px]">
        <GSelect :active-id="data.type" :options="CREATION_OPTIONS" @select="handleSelectDateType">
          <template #button-content>
            <span>{{ getLabel(data.type) }}</span>
          </template>
        </GSelect>
      </div>
    </PopoverContent>
    <PopoverContent v-if="data.type === CreationDateType.DURATION" title="Days">
      <InputBadge
        id="days"
        :value="data.days"
        :min="1"
        :options="DATE_OPTIONS"
        @on-change="(id: string, value: string) => handleChangeDate(id, value)" />
      <template #message>
        <div v-if="message" class="text-12 flex items-start gap-8 text-red-200">
          <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
          <p>{{ message }}</p>
        </div>
      </template>
    </PopoverContent>
    <PopoverContent v-else-if="data.type === CreationDateType.EXACT_DATE" title="Date">
      <DateTimePickers
        id="from"
        :is-only-accept-integer="true"
        :auto-emit-control-change="false"
        :value="data.from"
        class="w-full max-w-[140px]"
        mode="date"
        placeholder="E.g: Jan 01 2024"
        @control-change="handleChangeDate" />
    </PopoverContent>
    <template v-else>
      <PopoverContent title="From">
        <DateTimePickers
          id="from"
          :max-date="data.to ? addDays(new Date(data.to), -1) : undefined"
          :is-only-accept-integer="true"
          :value="data?.from"
          :auto-emit-control-change="false"
          class="w-full max-w-[140px]"
          mode="date"
          placeholder="E.g: Jan 01 2024"
          @control-change="handleChangeDate" />
      </PopoverContent>
      <PopoverContent title="To">
        <DateTimePickers
          id="to"
          :min-date="data.from ? addDays(new Date(data.from), 1) : undefined"
          :is-only-accept-integer="true"
          :auto-emit-control-change="false"
          :value="data?.to"
          class="w-full max-w-[140px]"
          mode="date"
          placeholder="E.g: Jan 01 2024"
          @control-change="handleChangeDate" />
        <template #message>
          <div v-if="message" class="text-12 flex items-start gap-8 text-red-200">
            <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
            <p>{{ message }}</p>
          </div>
        </template>
      </PopoverContent>
    </template>
  </Wrapper>
</template>
