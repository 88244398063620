<script lang="ts" setup>
import { computed } from 'vue';
type Options = {
  label?: string;
  value?: string;
  icon?: string;
  type?: string;
  isTextIcon?: boolean;
  disabled?: boolean;
};

type PropsType = {
  id: string;
  value?: any;
  options?: Options[];
  disableToggle?: boolean;
  readonly?: boolean;
  iconSmall?: boolean;
  iconViewBox?: number | string;
  enableItemBackground?: boolean;
  itemPerRow?: number;
  itemSpacing?: 'large' | 'small';
  enableTooltip?: boolean;
};

const props = withDefaults(defineProps<PropsType>(), {
  itemSpacing: 'small',
  enableTooltip: true,
});
const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string, value?: string): void;
  (e: 'controlChange', controlId?: string, value?: string): void;
  (e: 'controlFocus', controlId?: string, value?: string): void;
  (e: 'controlBlur', controlId?: string, value?: string): void;
}>();

const change = (value?: string) => {
  emit('controlChange', props.id, value);
};

const iconWidth = computed(() => {
  return props.options?.length == 3 ? 72 : 50;
});

const getIconStyle = (item: Options) => {
  const styles = [];
  if (item.type === 'carousel-dot') {
    styles.push('group');
  }
  if (props.value === item.value) {
    styles.push('active');
  }

  return styles.join(' ');
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div
      class="flex h-48 w-full justify-between rounded-xl"
      :class="{
        'flex-wrap': itemPerRow,
        'gap-8': itemSpacing === 'small',
        'gap-16': itemSpacing === 'large',
      }">
      <div
        v-for="item in options"
        :key="item.value"
        :class="{
          ' !text-primary-300': value === item.value && !enableItemBackground,
          ' !text-dark-50': value === item.value && enableItemBackground,
          'text-dark-200': value !== item.value,
          'bg-dark-400 border-dark-400 hover:text-dark-50 rounded-xl border': enableItemBackground,
          ' !border-primary-300 !bg-dark-400': value == item.value && enableItemBackground,
          ' active': value === item.value,
          'hover:bg-dark-50': item.isTextIcon && enableItemBackground,
          'cursor-not-allowed': item.disabled,
        }"
        :style="
          itemPerRow
            ? `width: calc((100% - ${itemPerRow - 1} * ${itemSpacing === 'large' ? '16px' : '8px'}) / ${itemPerRow})`
            : ''
        "
        class="text-12 gp-layout-segment-item group/icon flex h-full cursor-pointer select-none overflow-hidden text-center font-medium"
        @click.stop="!item.disabled && change(item.value)">
        <g-tooltip placement="top" :disabled="!enableTooltip || !item.label" :class="{ 'opacity-50': item.disabled }">
          <span
            v-if="item.icon"
            :class="{ '[&_>_svg]:h-20': !iconSmall, '[&_>_svg]:h-12': iconSmall }"
            v-html="item.icon"></span>
          <template v-else-if="item.type">
            <g-base-icon
              :name="((`${item.type}-${item.value}`) as any)"
              :width="iconViewBox ? '100%' : `${iconWidth}px`"
              :height="iconViewBox ? '100%' : '48px'"
              :viewBox="iconViewBox || `0 0 ${iconWidth} 48`"
              fill="none"
              :custom-class="getIconStyle(item)">
            </g-base-icon>
          </template>

          <span v-else class="[&_>_svg]:h-20" v-html="item.label"></span>
          <template #content>
            <div class="text-12 text-light-100">{{ item.label }}</div>
          </template>
        </g-tooltip>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<style lang="scss" scoped>
.gp-layout-segment-item::v-deep(svg) {
  .gp-icon-highlight {
    @apply text-dark-50;
  }

  .gp-icon-highlight-sub {
    @apply text-dark-50;
  }

  &:hover {
    .gp-icon-highlight {
      @apply text-text-dark-300;
    }

    .gp-icon-highlight-sub {
      @apply text-text-dark-100;
    }
  }
}

.gp-layout-segment-item.active::v-deep(svg) {
  .gp-icon-highlight {
    @apply text-text-dark-300;
  }

  .gp-icon-highlight-sub {
    @apply text-text-dark-100;
  }
}
</style>
