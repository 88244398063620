<script lang="ts" setup>
import type { InventoryStatusTrigger } from '../type';
import { computed, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { getLabel, getMinValueForInventory, getSubTitle, getNewRangeValue, getMessageForValidate } from '../common';
import { InventoryStatus, CompareType } from '../constant';
import Wrapper from '../Wrapper/Index.vue';
import PopoverContent from '../Wrapper/PopoverContent.vue';
import RangeInput from '../common/RangeInput.vue';
import CompareTypeSelect from '../common/CompareTypeSelect.vue';
import InputBadge from '../common/InputBadge.vue';

const props = defineProps<{
  data: InventoryStatusTrigger;
  moneyFormat?: string;
  active?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (event: 'onUpdate', data: InventoryStatusTrigger): void;
}>();

const triggerButton = ref(null);
const isOpenDropdown = ref(false);
const type = ref<InventoryStatus>(props.data.type);
const quantityType = ref<CompareType>(props.data.quantityType);

const subTitle = computed(() => {
  const { type, quantityType, from, to } = props.data;
  const fromValue = Number(from);
  if (type === InventoryStatus.OUT_OF_STOCK) return getLabel(InventoryStatus.OUT_OF_STOCK);
  if (quantityType === CompareType.EXACT_OR_ABOVE && fromValue >= 1) return 'In stock';
  if (
    (quantityType === CompareType.BELOW && fromValue === 2) ||
    (quantityType === CompareType.EXACT_OR_BELOW && fromValue === 1)
  )
    return 'Exact 1';
  if (quantityType === CompareType.BELOW && fromValue >= 3) return `From 1 to ${fromValue - 1}`;
  if (quantityType === CompareType.EXACT_OR_BELOW) return `From 1 to ${fromValue}`;
  return getSubTitle(quantityType, from, to, '{{amount}}');
});

watch(
  () => props.data,
  (data) => {
    type.value = data.type;
    quantityType.value = data.quantityType;
  },
  { immediate: true },
);

const onChangeType = (_: string, value: InventoryStatus) => {
  emit('onUpdate', {
    ...props.data,
    type: value,
  });
};

const onChangeQuantityType = (value: CompareType) => {
  const minCurr = getMinValueForInventory(value);
  const { from, to } = getNewRangeValue(props.data.from, props.data.to ?? '', minCurr, value);
  emit('onUpdate', {
    ...props.data,
    quantityType: value,
    conditionType: value,
    from,
    to,
  });
};

const onChangeQuantityValue = (name: string, value: string) => {
  emit('onUpdate', {
    ...props.data,
    [name]: value,
  });
};

onClickOutside(triggerButton, () => {
  isOpenDropdown.value = false;
});

const message = computed(() => {
  const { conditionType, from, to } = props.data;
  return getMessageForValidate({
    from,
    to,
    type: conditionType as CompareType,
    trigger: 'inventory_status',
  });
});

const min = computed(() => {
  const { conditionType } = props.data;
  return getMinValueForInventory(conditionType as CompareType);
});
</script>

<template>
  <Wrapper title="Product inventory" :sub-title="subTitle" disable-delete :active="active" :is-loading="isLoading">
    <PopoverContent title="When">
      <div class="w-[140px]">
        <GSelect
          :active-id="type"
          :options="[
            { id: InventoryStatus.IN_STOCK, name: getLabel(InventoryStatus.IN_STOCK) },
            { id: InventoryStatus.OUT_OF_STOCK, name: getLabel(InventoryStatus.OUT_OF_STOCK) },
          ]"
          @select="onChangeType">
          <template #button-content>
            <span>{{ getLabel(type) }}</span>
          </template>
        </GSelect>
      </div>
      <template #tooltip>
        <GTooltip placement="top" content-class="w-[158px] !whitespace-normal break-words">
          <GBaseIcon name="info-solid" width="20" height="20" viewBox="0 0 16 16" class="!text-text-dark-100" />
          <template #content> Only work with product enable “Track inventory” </template>
        </GTooltip>
      </template>
    </PopoverContent>
    <PopoverContent v-if="type === InventoryStatus.IN_STOCK" title="Quantity">
      <CompareTypeSelect :quantity-type="quantityType" @on-change="onChangeQuantityType" />
      <RangeInput
        v-if="quantityType === CompareType.BETWEEN"
        :from="data.from"
        :to="data.to"
        :min="1"
        @on-update-value="onChangeQuantityValue" />
      <InputBadge v-else id="from" :value="data.from" :min="min" @on-change="onChangeQuantityValue" />
      <template #message>
        <div v-if="message" class="text-12 flex items-start gap-8 text-red-200">
          <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
          <p>{{ message }}</p>
        </div>
      </template>
    </PopoverContent>
  </Wrapper>
</template>
