<script lang="ts" setup>
import { ref, watch } from 'vue';

type Props = {
  id?: string;
  value?: any;
  max?: number;
  min?: number;
  layout?: 'vertical' | 'horizontal';
};
const props = withDefaults(defineProps<Props>(), {
  layout: 'horizontal',
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

const deg = ref<number | undefined>(props.value);
const maxV = ref<number>(props.max !== undefined && props.max > 359 ? 359 : props.max ?? 359);
const minV = ref<number>(props.min !== undefined && props.min < 0 ? 0 : props.min ?? 0);
const centerPoint = ref<HTMLElement>();
const defaultX = ref<number>(0);
const defaultY = ref<number>(0);

watch(
  () => props.value,
  (value) => {
    if (value !== undefined) {
      if (value > maxV.value) deg.value = maxV.value;
      if (value < minV.value) deg.value = minV.value;
      deg.value = value;
    }
  },
);

const mousemove = (e: MouseEvent) => {
  const { x: cX, y: cY } = e;
  const pX = cX - defaultX.value;
  const pY = cY - defaultY.value;
  const d = Math.sqrt(pX * pX + pY * pY);
  let currentDeg = Math.round(Math.asin(pY / d) * (180 / Math.PI));
  if (pX < 0) currentDeg = 180 - currentDeg;
  if (currentDeg < 0) {
    currentDeg = maxV.value + currentDeg;
  }
  deg.value = currentDeg;
  onChange();
};

const mouseup = () => {
  window.removeEventListener('mousemove', mousemove);
  window.removeEventListener('mouseup', mouseup);
  change();
};

// deg.value = props.value;
const rotateHandler = () => {
  const rect = centerPoint.value?.getClientRects();
  if (!rect) return;
  const { x, y } = rect[0];
  defaultX.value = x;
  defaultY.value = y;
  window.addEventListener('mousemove', mousemove);
  window.addEventListener('mouseup', mouseup);
};

const onChange = () => {
  emit('controlOnChange', props.id, deg.value);
};
const change = () => {
  if (deg.value) {
    if (deg.value > maxV.value) deg.value = maxV.value;
    if (deg.value < minV.value) deg.value = maxV.value;
    emit('controlOnChange', props.id, deg.value);
  }
  emit('controlChange', props.id, deg.value);
};
</script>
<template>
  <div class="gemx-control w-full">
    <div class="gfSABody relative flex items-start justify-between">
      <slot name="label"></slot>
      <div class="bg-dark-400 relative flex h-[84px] w-[84px] select-none items-center justify-center rounded-full">
        <div
          class="text-light-450 text-12 after:bg-text-dark-100 relative after:absolute after:right-[-4px] after:top-[1px] after:h-[3px] after:w-[3px] after:rounded-full after:content-['']">
          {{ deg }}
        </div>
        <div class="absolute left-0 top-0 h-full w-full">
          <div class="gfSACenter" @mousedown="rotateHandler">
            <div ref="centerPoint" class="gfSACenterCenter" />
            <div ref="clockwise" class="gfSACenterBorder" :style="`transform: rotate(${deg}deg)`">
              <span />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$blue: #839bc0;
$text: #333333;
$textShade2: #7f7f7f;
$textShade3: #dadada;

.gfSABody {
  width: 100%;
  margin-top: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  // height: 100px;
  // clear: both;

  .gfSACenter {
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    float: left;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    .gfSACenterCenter {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 50%;
      margin: auto;
      margin-top: calc(50% - 3px);
    }

    .gfSACenterBorder {
      position: absolute;
      top: calc(50% - 6px);
      width: 50%;
      left: 50%;
      -webkit-transform-origin: left;
      transform-origin: left;
      padding: 6px 0px;
      cursor: pointer;

      span {
        content: '';
        width: 9px;
        height: 9px;
        background-color: #ffffff;
        // top: 20px;
        position: absolute;
        border-radius: 100%;
        top: 2px;
        right: 6px;
      }
    }
  }
}
</style>
