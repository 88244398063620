import type { SizeSettingValue } from '../control/size-setting/type';

export function isPercent(value: string): boolean {
  return value.includes('%');
}

export function isAuto(value: string): boolean {
  return value.toLowerCase() === 'auto' || !value;
}

export function isOriginalRatio(sizeSettingValue: SizeSettingValue): boolean {
  return (
    sizeSettingValue.shape === 'custom' &&
    (isAuto(sizeSettingValue.width || '') || isAuto(sizeSettingValue.height || ''))
  );
}

export function isCustomShape(sizeSettingValue: SizeSettingValue): boolean {
  return sizeSettingValue.shape === 'custom' || sizeSettingValue.shape === 'original';
}

export function getSizeFromRatioValue(
  ratio: string,
  size: {
    from: 'width' | 'height';
    value: string;
  },
): string {
  const ratioObj = convertRatioStringToObject(ratio);
  const sizeNumber = parseInt(size.value);
  return size.from === 'width'
    ? `${((sizeNumber * ratioObj.height) / ratioObj.width).toFixed(0)}px`
    : `${((sizeNumber * ratioObj.width) / ratioObj.height).toFixed(0)}px`;
}

export function convertRatioStringToObject(ratio: string): { width: number; height: number } {
  const ratioArr = ratio.split('/');
  return ratioArr.length == 2
    ? {
        width: parseInt(ratioArr[0]) || 1,
        height: parseInt(ratioArr[1]) || 1,
      }
    : {
        width: 1,
        height: 1,
      };
}

export function reduceRatio(ratio: string) {
  const { width, height } = convertRatioStringToObject(ratio);
  const gcd = getGreatestCommonDivisor(width, height);
  return `${width / gcd}:${height / gcd}`;
}

export function reduce(numerator: number, denominator: number) {
  const gcd = getGreatestCommonDivisor(numerator, denominator);
  return [numerator / gcd, denominator / gcd];
}

function getGreatestCommonDivisor(a: number, b: number): number {
  return b ? getGreatestCommonDivisor(b, a % b) : a;
}
