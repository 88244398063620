<script setup lang="ts">
import { computed, ref } from 'vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import type { SizeProps } from './type';
import type { CustomSizeProps } from './type';
import SizeFormEdit from './SizeFormEdit.vue';

type Props = {
  mobileOnly?: boolean;
  isCustom?: CustomSizeProps;
  value?: Record<string, SizeProps>;
  currentType?: 'small' | 'medium' | 'large';
  currentScreen?: string;
  devices?: string[];
  component?: 'tag' | string;
  componentTag?: string;
};

const props = defineProps<Props>();
const resetKey = ref(0);

const initValue =
  props.componentTag === 'Icon'
    ? {
        small: {
          vertical: '8px',
          horizontal: '8px',
        },
        medium: {
          vertical: '16px',
          horizontal: '16px',
        },
        large: {
          vertical: '24px',
          horizontal: '24px',
        },
      }
    : props.component === 'tag'
    ? {
        small: {
          vertical: '2px',
          horizontal: '8px',
        },
        medium: {
          vertical: '12px',
          horizontal: '4px',
        },
        large: {
          vertical: '16px',
          horizontal: '8px',
        },
      }
    : {
        small: {
          vertical: '4px',
          horizontal: '16px',
        },
        medium: {
          vertical: '8px',
          horizontal: '24px',
        },
        large: {
          vertical: '12px',
          horizontal: '32px',
        },
      };

const defaultValue = ref(initValue);
const emit = defineEmits<{
  (e: 'change', device: string, value?: SizeProps): void;
  (e: 'onChange', device: string, value?: SizeProps): void;
  (e: 'changeScreen', screenId: string): void;
  (e: 'close'): void;
  (e: 'reset'): void;
}>();

const extendValue = computed(() => {
  return Object.fromEntries(
    props.devices?.map((device, index) => {
      const composeDeviceValue = props.devices?.slice(0, index + 1).reduce((curr, deviceId) => {
        const controlDevice = props.value?.[deviceId];
        return {
          ...curr,
          ...controlDevice,
        };
      }, {} as SizeProps);
      return [device, composeDeviceValue];
    }) ?? [],
  );
});

const selectedTab = computed(() => {
  return props.currentScreen
    ? props.devices?.includes(props.currentScreen)
      ? props.devices?.indexOf(props.currentScreen)
      : 0
    : 0;
});

const changeTab = (index: number) => {
  const screenId = props.devices?.[index];
  if (screenId) {
    emit('changeScreen', screenId);
  }
};

const onChange = (device: string, controlId: string, value?: any) => {
  const extendV = extendValue.value[device];
  if (extendV) {
    const newV: SizeProps = {
      ...defaultValue.value[props.currentType ?? 'medium'],
      ...(Object.keys(extendV).length !== 0 && extendValue.value[device]),
      [controlId]: value,
    };

    emit('change', device, newV);
  }
};

const onChangeData = (device: string, controlId: string, value?: any) => {
  const extendV = extendValue.value[device];
  if (extendV) {
    const newV: SizeProps = {
      ...defaultValue.value[props.currentType ?? 'medium'],
      ...(Object.keys(extendV).length !== 0 && extendValue.value[device]),
      [controlId]: value,
    };
    emit('onChange', device, newV);
  }
};

// const close = () => {
//   emit('close');
// };
</script>

<template>
  <g-popover
    :closeable="true"
    class="aspect-square h-full"
    :overlay="false"
    no-shadow-box
    arrow-class="!text-dark-400 translate-x-[-42px]"
    overlay-container="#root-modal"
    placement="bottom-end"
    wrapper-class="translate-x-[42px]"
    cls="!p-0 mt-2">
    <template #default="{ open }">
      <GButtonV2 :active="open" type="ghost" size="medium" only-icon="more-setting-20" class="h-full"></GButtonV2>
    </template>
    <template #content="{ close }">
      <div ref="popup" class="bg-dark-400 rounded-12 w-screen max-w-[264px] transform px-4 sm:px-0">
        <div class="flex items-center justify-between p-16">
          <h3 class="text-14 text-light-450 font-semibold">Custom inside space</h3>
          <div
            class="hover:bg-light-100 flex aspect-square w-24 cursor-pointer items-center justify-center rounded-xl hover:bg-opacity-20"
            @click="close">
            <g-base-icon name="close" width="16" height="16" class="text-light-450"></g-base-icon>
          </div>
        </div>
        <div v-if="mobileOnly" class="px-12 py-8">
          <SizeFormEdit
            :reset-key="resetKey"
            :vertical="
              isCustom !== undefined
                ? extendValue['desktop']?.vertical
                : defaultValue[currentType ?? 'medium']?.vertical
            "
            :horizontal="
              isCustom !== undefined
                ? extendValue['desktop']?.horizontal
                : defaultValue[currentType ?? 'medium']?.horizontal
            "
            @change="(...args) => onChange('desktop', ...args)"
            @on-change="(...args) => onChangeData('desktop', ...args)" />
        </div>
        <TabGroup v-else :selected-index="selectedTab" @change="changeTab">
          <TabList class="rounded-small relative mx-16 flex items-center gap-16">
            <Tab v-for="device in devices" :key="device" v-slot="{ selected }" as="template">
              <button
                :class="{
                  '!border-primary-300 text-light-450': device === currentScreen,
                  'text-text-dark-300': !selected,
                  'text-text-dark-100': device !== currentScreen,
                }"
                class="text-12 z-5 border-b-2 border-transparent py-8 text-center capitalize focus-visible:outline-none">
                {{ device }}
              </button>
            </Tab>
            <div class="border-light-500 absolute bottom-0 left-0 z-0 w-full border-b border-opacity-20"></div>
          </TabList>
          <TabPanels class="p-16">
            <TabPanel v-for="d in devices" :key="d">
              <SizeFormEdit
                :reset-key="resetKey"
                :vertical="
                  isCustom !== undefined
                    ? extendValue[d]?.vertical
                      ? extendValue[d]?.vertical
                      : defaultValue[currentType ?? 'medium']?.vertical
                    : defaultValue[currentType ?? 'medium']?.vertical
                "
                :horizontal="
                  isCustom !== undefined
                    ? extendValue[d]?.horizontal
                      ? extendValue[d]?.horizontal
                      : defaultValue[currentType ?? 'medium']?.horizontal
                    : defaultValue[currentType ?? 'medium']?.horizontal
                "
                @change="(...args) => onChange(d, ...args)"
                @on-change="(...args) => onChangeData(d, ...args)" />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </template>
  </g-popover>
</template>
