import type { Ref } from 'vue';
import type { TypographyFamily, TypographyV2Props } from '../../../types';
import type { globalStyleFontProps } from '../types';
import { useResolveFontValue } from './useResolveFontValue';
import { useIsCheckedFontItem } from './useIsCheckedFontItem';

export function useRemoveDuplicateFonts(
  themeFonts: Ref<globalStyleFontProps | undefined>,
  currentFont: Ref<string | TypographyV2Props['fontFamily']>,
) {
  const resolveFontValue = useResolveFontValue(themeFonts);
  const isCheckedFontItem = useIsCheckedFontItem(themeFonts, currentFont);

  return (recentFonts: (TypographyFamily & any[]) | never[]): TypographyFamily[] => {
    const uniqueFonts = new Map<string, TypographyFamily>();

    recentFonts.forEach((font) => {
      const { value, type } = font.value;

      const label = type === 'theme' ? resolveFontValue(value) || value : value;

      if (!uniqueFonts.has(label) || isCheckedFontItem({ value, type })) {
        uniqueFonts.set(label, font);
      }
    });

    return Array.from(uniqueFonts.values());
  };
}
