<template>
  <svg width="59" height="33" viewBox="0 0 59 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9363_27008)">
      <rect
        width="58"
        height="32"
        rx="1.8125"
        transform="matrix(-1 0 0 1 58.5 0.5)"
        fill="#424242"
        class="background" />
      <circle cx="8" cy="8" r="8" transform="matrix(-1 0 0 1 24.5 -7.5)" fill="#5E5E5E" class="sub" />
      <path
        d="M61.5289 47.7263L50.6122 34.7973C50.2426 34.3588 49.7859 34.004 49.2717 33.7562C48.7576 33.5084 48.1976 33.3731 47.6284 33.3591C47.0591 33.3451 46.4933 33.4527 45.9679 33.675C45.4425 33.8973 44.9692 34.2292 44.5791 34.649L39.4745 40.1357L19.5206 15.9732C19.1299 15.4997 18.6389 15.1215 18.084 14.8667C17.529 14.6118 16.9245 14.4868 16.3153 14.5011C15.7061 14.5153 15.1079 14.6684 14.5652 14.949C14.0225 15.2295 13.5493 15.6303 13.1806 16.1215L-10.6757 47.8858C-11.1318 48.4947 -11.411 49.2204 -11.482 49.9812C-11.553 50.7421 -11.4129 51.5079 -11.0775 52.1925C-10.7421 52.8771 -10.2247 53.4534 -9.58344 53.8566C-8.94218 54.2598 -8.20251 54.4739 -7.44764 54.4748L58.4446 54.5C59.2196 54.4997 59.9783 54.2746 60.631 53.8514C61.2837 53.4283 61.8029 52.8247 62.1274 52.1121C62.4518 51.3996 62.5679 50.6078 62.4618 49.8306C62.3558 49.0534 62.032 48.3231 61.5289 47.7263Z"
        fill="#5E5E5E"
        class="sub" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M52.6875 11.5C53.6885 11.5 54.5 12.3115 54.5 13.3125V13.6875C54.5 14.6885 53.6885 15.5 52.6875 15.5H36.3125C35.3115 15.5 34.5 14.6885 34.5 13.6875V13.3125C34.5 12.3115 35.3115 11.5 36.3125 11.5H52.6875ZM44.6875 17.5C45.6885 17.5 46.5 18.3115 46.5 19.3125V19.6875C46.5 20.6885 45.6885 21.5 44.6875 21.5H36.3125C35.3115 21.5 34.5 20.6885 34.5 19.6875V19.3125C34.5 18.3115 35.3115 17.5 36.3125 17.5H44.6875Z"
        fill="#AAAAAA"
        class="trunk" />
    </g>
    <defs>
      <clipPath id="clip0_9363_27008">
        <rect width="58" height="32" rx="1.8125" transform="matrix(-1 0 0 1 58.5 0.5)" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
