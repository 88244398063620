<script lang="ts" setup>
import { ref } from 'vue';

import DeleteSection from './DeleteSection.vue';
import PopoverSection from './PopoverSection.vue';

defineProps<{
  title: string;
  popupTitle?: string;
  subTitle: string;
  disableDelete?: boolean;
  active?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (event: 'onDelete'): void;
  (event: 'onOpen'): void;
}>();

const showDeleteConfirm = ref(false);
</script>

<template>
  <div
    :class="{
      'animate-pulse': isLoading,
      'hover:bg-dark-250': !isLoading,
    }"
    class="bg-dark-400 shadow-4dp trigger-badge group group relative z-50 flex h-[72px] w-full flex-col rounded-xl p-12 text-white">
    <div class="flex h-[28px] items-center gap-4">
      <p class="text-text-dark-500 text-12 flex-1 leading-5">{{ title }}</p>
      <div v-if="!isLoading" class="invisible flex items-center gap-4 group-hover:visible">
        <DeleteSection
          :show-delete-confirm="showDeleteConfirm"
          :disable-delete="disableDelete"
          @show="showDeleteConfirm = true"
          @cancel="showDeleteConfirm = false"
          @delete="emit('onDelete')" />
        <PopoverSection
          :title="popupTitle ?? title"
          :show-delete-confirm="showDeleteConfirm"
          :active="active"
          @on-open="emit('onOpen')">
          <template #default="{ open }">
            <slot v-bind="{ open }" />
          </template>
        </PopoverSection>
      </div>
    </div>
    <p class="text-text-dark-300 text-12 line-clamp-1 leading-5">{{ subTitle }}</p>
  </div>
</template>
