import type { TypographyV2Props } from '../../../types';

type FontWeightOption = {
  label: string;
  value: string;
};

export const useGetFontWeightFromOptions = (
  baseTypo?: TypographyV2Props,
  fontWeightOptions?: FontWeightOption[],
): string | number | undefined => {
  if (!baseTypo || !Array.isArray(fontWeightOptions) || fontWeightOptions.length === 0) {
    return baseTypo?.fontWeight;
  }

  const matchingFontWeight = fontWeightOptions.find((option) => option.value === baseTypo.fontWeight);

  return matchingFontWeight ? baseTypo.fontWeight : fontWeightOptions[0].value;
};
