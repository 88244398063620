<template>
  <svg width="59" height="33" viewBox="0 0 59 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9363_26565)">
      <rect x="0.5" y="0.5" width="58" height="32" rx="1.8125" fill="#424242" class="background" />
      <circle cx="8" cy="8" r="8" transform="matrix(-1 0 0 1 48.5 -7.5)" fill="#5E5E5E" class="sub" />
      <path
        d="M85.5289 47.7263L74.6122 34.7973C74.2426 34.3588 73.7859 34.004 73.2717 33.7562C72.7576 33.5084 72.1976 33.3731 71.6284 33.3591C71.0591 33.3451 70.4933 33.4527 69.9679 33.675C69.4425 33.8973 68.9692 34.2292 68.5791 34.649L63.4745 40.1357L43.5206 15.9732C43.1299 15.4997 42.6389 15.1215 42.084 14.8667C41.529 14.6118 40.9245 14.4868 40.3153 14.5011C39.7061 14.5153 39.1079 14.6684 38.5652 14.949C38.0225 15.2295 37.5493 15.6303 37.1806 16.1215L13.3243 47.8858C12.8682 48.4947 12.589 49.2204 12.518 49.9812C12.447 50.7421 12.5871 51.5079 12.9225 52.1925C13.2579 52.8771 13.7753 53.4534 14.4166 53.8566C15.0578 54.2598 15.7975 54.4739 16.5524 54.4748L82.4446 54.5C83.2196 54.4997 83.9783 54.2746 84.631 53.8514C85.2837 53.4283 85.8029 52.8247 86.1274 52.1121C86.4518 51.3996 86.5679 50.6078 86.4618 49.8306C86.3558 49.0534 86.032 48.3231 85.5289 47.7263Z"
        fill="#5E5E5E"
        class="sub" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.3125 11.5C7.31148 11.5 6.5 12.3115 6.5 13.3125V13.6875C6.5 14.6885 7.31148 15.5 8.3125 15.5H24.6875C25.6885 15.5 26.5 14.6885 26.5 13.6875V13.3125C26.5 12.3115 25.6885 11.5 24.6875 11.5H8.3125ZM8.3125 17.5C7.31148 17.5 6.5 18.3115 6.5 19.3125V19.6875C6.5 20.6885 7.31148 21.5 8.3125 21.5H16.6875C17.6885 21.5 18.5 20.6885 18.5 19.6875V19.3125C18.5 18.3115 17.6885 17.5 16.6875 17.5H8.3125Z"
        fill="#AAAAAA"
        class="trunk" />
    </g>
    <defs>
      <clipPath id="clip0_9363_26565">
        <rect x="0.5" y="0.5" width="58" height="32" rx="1.8125" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
