<script lang="ts" setup>
import { computed } from 'vue';
import { CompareType } from '../constant';
import Wrapper from '../Wrapper/Index.vue';
import PopoverContent from '../Wrapper/PopoverContent.vue';
import RangeInput from '../common/RangeInput.vue';
import type { PriceRangeTrigger } from '../type';
import CompareTypeSelect from '../common/CompareTypeSelect.vue';
import {
  formatTitleCurrency,
  getMessageForValidate,
  getMinValueForPrice,
  getNewRangeValue,
  getSubTitle,
} from '../common';
import InputBadge from '../common/InputBadge.vue';

const props = defineProps<{
  data: PriceRangeTrigger;
  moneyFormat?: string;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onDelete'): void;
  (event: 'onUpdate', data: PriceRangeTrigger): void;
}>();

const min = computed(() => getMinValueForPrice(props.data.conditionType as CompareType));

const subTitle = computed(() => {
  const { conditionType, from, to } = props.data;
  if (conditionType === CompareType.EXACT_OR_BELOW && Number(from) === 0) {
    const value = formatTitleCurrency(props.moneyFormat, from);
    return `Exact ${value}`;
  }
  return getSubTitle(conditionType, from, to, props.moneyFormat);
});

const onSelect = (value: CompareType) => {
  const minCurr = getMinValueForPrice(value);
  const { from, to } = getNewRangeValue(props.data.from, props.data.to ?? '', minCurr, value);
  emit('onUpdate', {
    ...props.data,
    conditionType: value,
    from,
    to,
  });
};

const onChangeQuantityValue = (name: string, value: string) => {
  emit('onUpdate', {
    ...props.data,
    [name]: value,
  });
};

const message = computed(() => {
  const { conditionType, from, to } = props.data;
  return getMessageForValidate({
    from,
    to,
    type: conditionType as CompareType,
    trigger: 'price_range',
  });
});
</script>

<template>
  <Wrapper title="Price range" :sub-title="subTitle" :is-loading="isLoading" @on-delete="emit('onDelete')">
    <PopoverContent title="Amount">
      <CompareTypeSelect :quantity-type="data.conditionType" @on-change="onSelect" />
      <RangeInput
        v-if="data.conditionType === CompareType.BETWEEN"
        :quantity-type="data.conditionType"
        :from="data.from"
        :to="data.to"
        :is-integer="false"
        @on-update-value="onChangeQuantityValue" />
      <InputBadge
        v-else
        id="from"
        :value="data.from"
        :min="min"
        :is-integer="false"
        @on-change="onChangeQuantityValue" />
      <template #message>
        <div v-if="message" class="text-12 flex items-center gap-8 text-red-200">
          <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
          <p>{{ message }}</p>
        </div>
      </template>
    </PopoverContent>
  </Wrapper>
</template>
