export function lightenDarkenColor(color: string, amount: number = 30) {
  if (lightOrDark(color) === 'dark') {
    return mix(color, '#ffffff', amount);
  }
  return mix(color, '#000000', amount);
}

const mix = (color1: string, color2: string, amount: number) => {
  const rgb1 = inputToRgb(color1);
  const rgb2 = inputToRgb(color2);
  const p = amount / 100;
  const rgba = {
    r: Math.round((rgb2.r - rgb1.r) * p + rgb1.r),
    g: Math.round((rgb2.g - rgb1.g) * p + rgb1.g),
    b: Math.round((rgb2.b - rgb1.b) * p + rgb1.b),
    a: (rgb2.a - rgb1.a) * p + rgb1.a,
  };
  return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
};

export const inputToRgb = (color: string) => {
  const colorHex = formatColorToHex(color);
  const num = parseInt(colorHex, 16);
  return {
    r: getRed(num),
    g: getGreen(num),
    b: getBlue(num),
    a: getAlphaOfColor(color),
  };
};

const formatColorToHex = (color: string) => {
  if (color[0] == '#') {
    return color.slice(1);
  }
  return colorRgbToHex(color);
};

const colorRgbToHex = (color: string) => {
  const colorRgb = getColorHexFromRgb(color);
  if (colorRgb) {
    return colorRgb;
  }
  const colorRgba = getColorHexFromRgba(color);
  if (colorRgba) {
    return colorRgba;
  }
  return '000000';
};

const getColorHexFromRgb = (color: string) => {
  const digits = /(.*?)rgb\((\d+),\s?(\d+),\s?(\d+)\)/.exec(color);
  if (digits?.length) {
    const r = parseInt(digits[2]);
    const g = parseInt(digits[3]);
    const b = parseInt(digits[4]);
    return getActualColor(r) + getActualColor(g) + getActualColor(b);
  }
};

const getColorHexFromRgba = (color: string) => {
  const digits = /(.*?)rgba?\((\d+),\s?(\d+),\s?(\d+),\s?(\d+(\.\d)?)\)/.exec(color);
  if (digits?.length) {
    const r = parseInt(digits[2]);
    const g = parseInt(digits[3]);
    const b = parseInt(digits[4]);
    return getActualColor(r) + getActualColor(g) + getActualColor(b);
  }
};

const getAlphaOfColor = (color: string) => {
  const digits = /(.*?)rgba?\((\d+),\s?(\d+),\s?(\d+),\s?(\d+(\.\d)?)\)/.exec(color);
  if (digits?.length) {
    const a = parseInt(digits[5]);
    return a;
  }
  return 1;
};

export const lightOrDark = (color: string) => {
  const colorHex = formatColorToHex(color);
  const num = parseInt(colorHex, 16);
  const r = getRed(num);
  const g = getGreen(num);
  const b = getBlue(num);
  const hsp: any = (r * 299 + g * 587 + b * 114) / 1000;
  if (hsp >= 128) {
    return 'light';
  }
  return 'dark';
};

const getRed = (num: number) => {
  return num >> 16;
};

const getBlue = (num: number) => {
  return num & 0x0000ff;
};

const getGreen = (num: number) => {
  return (num >> 8) & 0x00ff;
};

const getActualColor = (codeNumber: number) => {
  return codeNumber.toString(16).length == 1 ? '0' + codeNumber.toString(16) : codeNumber.toString(16);
};
