<script lang="ts" setup>
import { computed, ref } from 'vue';
import { GSyncProgress } from '@gem/uikit';
import { useInfiniteScroll } from '@vueuse/core';
import { replaceImageToSize } from '../../hook/useResizeImage';

export type BlogList = {
  id: string;
  title?: string;
  image?: string;
};

type Props = {
  open?: boolean;
  value?: string;
  list?: BlogList[];
  isLoading?: boolean;
  syncPercent?: number;
  pageType?: string;
  inputSearchComponent?: Object;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'showMore'): void;
  (e: 'refresh'): void;
  (e: 'setValueSelected', id?: string | string[]): void;
  (e: 'onSearch', value?: string): void;
}>();

const blogsInfinityScroll = ref<HTMLElement | null>(null);
const searchKeyword = ref('');
const open = computed(() => props.open);
const blogListNotFound = computed(() => props.list?.length == 0);
const isInstant = computed(() => props.pageType === 'STATIC');

useInfiniteScroll(
  blogsInfinityScroll,
  () => {
    emit('showMore');
  },
  { distance: 100 },
);
const onSearch = () => {
  setTimeout(() => {
    emit('onSearch', searchKeyword.value);
  }, 200);
};
const setValueSelected = (id?: string) => {
  if (id) {
    emit('setValueSelected', id);
    close();
  }
};

const close = () => {
  clear();
  emit('close');
};
const updateSearchInput = (value: string) => {
  searchKeyword.value = value;
  setTimeout(() => {
    emit('onSearch', searchKeyword.value);
  }, 200);
};

const clear = () => {
  searchKeyword.value = '';
  onSearch();
};
</script>
<template>
  <div>
    <Teleport to="body">
      <g-modal
        :hide-actions="true"
        :is-open="open"
        :show-btn-close="true"
        :is-draggable="true"
        :show-line-top="false"
        body-class="bg-light-400 pb-16"
        modal-class="!w-[580px]"
        header-class="cursor-default"
        container-class="absolute !left-[337px] [@media(max-height:660px)]:max-h-full [@media(max-height:660px)]:bottom-16"
        @close="close">
        <template #title>
          <div class="flex h-full w-full items-center">
            <div>
              <p>Select a blog</p>
            </div>
            <div class="flex h-full flex-1 items-center justify-end">
              <div
                class="group/button flex h-[52px] w-[52px] flex-shrink-0 cursor-pointer items-center justify-center"
                @click.stop="emit('refresh')">
                <g-button
                  type="ghost"
                  :only-icon="true"
                  :disable="isLoading"
                  :light-mode="true"
                  size="semi-medium"
                  data-test="editor-control-modal-button-refresh">
                  <GBaseIcon
                    v-if="isLoading"
                    :class="{ 'animate-spin': isLoading }"
                    name="small-loading"
                    color="#676767"
                    width="16"
                    height="16"></GBaseIcon>
                  <GBaseIcon
                    v-else
                    name="refresh-select"
                    color="#676767"
                    width="16"
                    height="16"
                    view-box="0 0 16 16"></GBaseIcon>
                </g-button>
              </div>
              <div class="bg-light-400 absolute h-24 w-[1px]"></div>
            </div>
          </div>
        </template>
        <div>
          <div class="flex items-center">
            <div class="flex w-full items-center p-16">
              <component
                :is="inputSearchComponent"
                :query-value="searchKeyword"
                placeholder="Search blog..."
                data-test="setting-modal-blog-input-search"
                class="w-full"
                input-style="normalLight"
                @search="updateSearchInput"
                @clear="clear" />
            </div>
          </div>
          <div>
            <div class="flex flex-col">
              <g-sync-progress :percent-sync-done="syncPercent" />
              <div
                v-if="!blogListNotFound && isInstant"
                class="text-14 text-light-low sticky top-0 flex pb-12 font-medium">
                <p>Instant Page only show “Available” blogs.</p>
              </div>
              <perfect-scrollbar ref="blogsInfinityScroll" class="!h-[53vh]">
                <div v-if="!blogListNotFound" class="bg-light-400 relative flex flex-1 flex-col gap-16 px-16 pt-0">
                  <div class="flex flex-1 flex-col gap-16" data-test="setting-modal-blog-listing">
                    <div
                      v-for="(item, index) in list"
                      :key="index"
                      data-test="setting-modal-blog-item"
                      class="active:border-primary-300 hover:bg-light-250 flex cursor-pointer items-center rounded-[12px] bg-white active:border"
                      :class="{
                        'border-primary-300 !cursor-default border': item.id === value,
                      }"
                      @click="setValueSelected(item.id)"
                      @keypress="setValueSelected(item.id)">
                      <div
                        class="h-[72px] w-[72px] flex-shrink-0 rounded-l-[12px] bg-white"
                        :class="{
                          'flex items-center justify-center': !item.image,
                        }">
                        <img
                          v-if="item.image"
                          class="h-full w-full rounded-l-[12px]"
                          :src="replaceImageToSize(item.image, '72x72') ?? item.image"
                          alt="blogs" />
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.0958 3.72426C20.3301 3.48994 20.3301 3.11004 20.0958 2.87573L18.823 1.60294C18.5886 1.36862 18.2087 1.36862 17.9744 1.60294L16.8259 2.75147L18.9472 4.8728L20.0958 3.72426Z"
                            fill="#676767" />
                          <path
                            d="M18.0987 5.72132L15.9774 3.6L11.5386 8.03877C11.0906 8.48682 10.8379 9.09387 10.8357 9.7275L10.8328 10.5649C10.8322 10.7314 10.9673 10.8665 11.1338 10.8659L11.9712 10.863C12.6048 10.8608 13.2119 10.6081 13.6599 10.1601L18.0987 5.72132Z"
                            fill="#676767" />
                          <path
                            d="M6.60005 5.69999C6.60005 4.87157 7.27162 4.19999 8.10005 4.19999H12C12.4971 4.19999 12.9 3.79705 12.9 3.29999C12.9 2.80294 12.4971 2.39999 12 2.39999H8.10005C6.27751 2.39999 4.80005 3.87745 4.80005 5.69999V18.3C4.80005 20.1225 6.27751 21.6 8.10005 21.6H15.9C17.7226 21.6 19.2 20.1225 19.2 18.3V9.59999C19.2 9.10294 18.7971 8.69999 18.3 8.69999C17.803 8.69999 17.4 9.10294 17.4 9.59999V18.3C17.4 19.1284 16.7285 19.8 15.9 19.8H8.10005C7.27162 19.8 6.60005 19.1284 6.60005 18.3V5.69999Z"
                            fill="#676767" />
                          <path
                            d="M9.30005 12.6C8.80299 12.6 8.40005 13.0029 8.40005 13.5C8.40005 13.997 8.80299 14.4 9.30005 14.4H14.7C15.1971 14.4 15.6 13.997 15.6 13.5C15.6 13.0029 15.1971 12.6 14.7 12.6H9.30005Z"
                            fill="#676767" />
                          <path
                            d="M8.40005 17.1C8.40005 16.6029 8.80299 16.2 9.30005 16.2H12.6002C13.0972 16.2 13.5002 16.6029 13.5002 17.1C13.5002 17.597 13.0972 18 12.6002 18H9.30005C8.80299 18 8.40005 17.597 8.40005 17.1Z"
                            fill="#676767" />
                        </svg>
                      </div>
                      <div class="text-14 text-text-light-500 w-full truncate p-16 font-medium leading-6">
                        {{ item?.title }}
                      </div>
                    </div>
                    <div
                      v-if="isLoading"
                      class="border-light-400 flex items-center border-x border-b"
                      :class="{ 'border-y': list?.length !== 0, 'border-b': list?.length === 0 }">
                      <GLoadingPlaceholder width="100%" height="58px"></GLoadingPlaceholder>
                    </div>
                    <div class="text-12 text-text-light-300 flex select-none items-center justify-center">
                      <div>Blog data is auto-synced every 10 minutes.</div>
                      <span class="text-primary-300 ml-4 cursor-pointer font-medium" @click="emit('refresh')"
                        >Sync again</span
                      >
                    </div>
                  </div>
                </div>
                <div v-else class="flex h-[calc(100%_-_10px)] flex-col items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.4826 61.894C29.0334 61.894 35.9652 54.9622 35.9652 46.4113C35.9652 37.8605 29.0334 30.9287 20.4826 30.9287C11.9318 30.9287 5 37.8605 5 46.4113C5 54.9622 11.9318 61.894 20.4826 61.894ZM20.4826 64.894C30.6903 64.894 38.9652 56.619 38.9652 46.4113C38.9652 36.2037 30.6903 27.9287 20.4826 27.9287C10.275 27.9287 2 36.2037 2 46.4113C2 56.619 10.275 64.894 20.4826 64.894Z"
                      fill="#9E9E9E" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M43.5869 71.6359L30.8672 58.9162L32.9885 56.7949L45.7082 69.5146L43.5869 71.6359Z"
                      fill="#9E9E9E" />
                    <path
                      d="M37.8904 20C37.91 23.3133 35.2402 26.0159 31.9258 26.0355C35.2402 26.0159 37.942 28.6865 37.9615 32C37.942 28.6865 40.6126 25.9841 43.9258 25.9644C40.6126 25.9841 37.91 23.3133 37.8904 20Z"
                      fill="#9E9E9E" />
                    <path
                      d="M10.4794 66C10.4908 67.9328 8.9334 69.5093 7 69.5207C8.9334 69.5093 10.5095 71.0671 10.5208 73C10.5095 71.0671 12.0673 69.4907 14 69.4792C12.0673 69.4907 10.4908 67.9328 10.4794 66Z"
                      fill="#9E9E9E" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18 53H75V11L18 11L18 53ZM18 8C16.3431 8 15 9.34315 15 11V53C15 54.6569 16.3431 56 18 56H75C76.6569 56 78 54.6569 78 53V11C78 9.34315 76.6569 8 75 8H18Z"
                      fill="#9E9E9E" />
                    <path
                      d="M60.2374 17C62.4286 17 64.1992 17.6197 65.5493 18.8592C66.8994 20.0986 67.5745 21.7586 67.5745 23.8391C67.5745 26.163 66.8552 27.8894 65.4165 29.0181C63.9779 30.1248 62.0302 30.6781 59.5735 30.6781L59.4739 33.832H56.8511L56.7183 28.5866H57.6811C59.8723 28.5866 61.5876 28.2546 62.827 27.5906C64.0664 26.9266 64.6862 25.6761 64.6862 23.8391C64.6862 22.5111 64.2878 21.4598 63.491 20.6851C62.6942 19.9105 61.6208 19.5231 60.2706 19.5231C58.8984 19.5231 57.8139 19.8994 57.0171 20.6519C56.2425 21.3823 55.8551 22.3894 55.8551 23.6731H53C53 22.3451 53.2988 21.1831 53.8964 20.1871C54.494 19.169 55.335 18.3833 56.4195 17.83C57.5262 17.2767 58.7988 17 60.2374 17ZM58.1459 40.6379C57.5704 40.6379 57.0835 40.4387 56.6851 40.0403C56.2867 39.6419 56.0875 39.155 56.0875 38.5795C56.0875 38.0041 56.2867 37.5172 56.6851 37.1188C57.0835 36.7204 57.5704 36.5212 58.1459 36.5212C58.6992 36.5212 59.164 36.7204 59.5403 37.1188C59.9387 37.5172 60.1378 38.0041 60.1378 38.5795C60.1378 39.155 59.9387 39.6419 59.5403 40.0403C59.164 40.4387 58.6992 40.6379 58.1459 40.6379Z"
                      fill="#9E9E9E" />
                  </svg>
                  <p class="text-14 font-regular text-text-light-300">No blogs match your search.</p>
                  <p class="text-14 font-regular text-text-light-300">Please try another keyword</p>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </g-modal>
    </Teleport>
  </div>
</template>
