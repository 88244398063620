import { CompareType } from './constant';
import type { TriggerEvent } from './type';

export const getAmount = (amount: string = '') => {
  if (amount.includes('$')) {
    return `$${amount.replace('$', '')}`;
  } else return amount;
};

export function isStringNumber(value: string) {
  // Check if the value is not a string or is an empty string
  if (typeof value !== 'string' || value.trim() === '') {
    return false;
  }

  // Use Number to attempt conversion
  const number = Number(value);

  // Check if the result is a valid finite number
  return !isNaN(number) && isFinite(number);
}

export const getLabel = (triggerType: string) => {
  if (triggerType.length === 0) return '';
  return triggerType.charAt(0).toUpperCase() + triggerType.slice(1).replaceAll('_', ' ');
};

export const extractContent = (template: string) => {
  const match = template.match(/{{(.*?)}}/);
  return match ? match[1] : null;
};

export function isFloat(n: number) {
  return Math.floor(n) !== n;
}

export const formatTitleCurrency = (format?: string, value?: string) => {
  const currency = extractContent(format as string);
  return format?.replace(`{{${currency}}}`, value ?? '');
};

export const getSubTitle = (type?: CompareType, from?: string, to?: string, format?: string) => {
  const fromValue = Number(from);
  const fromValueFormat = formatTitleCurrency(format, from);
  const toValueFormat = formatTitleCurrency(format, to);

  switch (type) {
    case CompareType.BELOW:
      return `Below ${fromValueFormat}`;
    case CompareType.EXACT_OR_BELOW:
      return `From 0 to ${fromValueFormat}`;
    case CompareType.EXACT:
      return `Exact ${fromValueFormat}`;
    case CompareType.ABOVE:
      return `Above ${fromValueFormat}`;
    case CompareType.EXACT_OR_ABOVE:
      return `From ${fromValueFormat} & above`;
    case CompareType.BETWEEN:
      return `From ${fromValue} to ${toValueFormat}`;
    default:
      return '';
  }
};

export const getMinValueForDiscountPrice = (type: CompareType) => {
  if (type === CompareType.BELOW) return 1;
  return 0;
};

export const getMinValueForPrice = (type: CompareType) => {
  if (type === CompareType.BELOW) return 1;
  return 0;
};

export const getMinValueForInventory = (type: CompareType) => {
  if (type === CompareType.ABOVE) return 0;
  if (type === CompareType.BELOW) return 2;
  return 1;
};

export const getMessageForDiscountPrice = (from: number, to: number, type: CompareType) => {
  const min = getMinValueForDiscountPrice(type);
  if (type === CompareType.BETWEEN && from >= to) return 'The 1st number must be smaller than 2nd number';
  if (from < min) return `Amount number must bigger than ${min > 0 ? min - 1 : min}`;
  return '';
};

export const getMessageForInventory = (from: number, to: number, type: CompareType) => {
  const min = getMinValueForInventory(type);
  if (type === CompareType.BETWEEN && from >= to) return 'The 1st number must be smaller than 2nd number';
  if (from < min) return `Quantity number must bigger than ${min > 0 ? min - 1 : min}`;
  return '';
};

export const getMessageForPrice = (from: number, to: number, type: CompareType) => {
  const min = getMinValueForPrice(type);
  if (type === CompareType.BETWEEN && from >= to) return 'The 1st number must be smaller than 2nd number';
  if (from < min) return `Amount number must bigger than ${min > 0 ? min - 1 : min}`;
  return '';
};

export const getMessageForValidate = (params: {
  from: string;
  to?: string;
  trigger: TriggerEvent;
  type: CompareType;
}) => {
  const { from, to, trigger, type } = params;
  if (!from.trim() || !to?.trim()) return '';
  let min = 0;
  const fromVal = Number(from);
  const toVal = Number(to);
  if (trigger === 'inventory_status') min = getMinValueForInventory(type);
  if (trigger === 'discount_price') min = getMinValueForDiscountPrice(type);
  if (trigger === 'price_range') min = getMinValueForPrice(type);
  if (type === CompareType.BETWEEN && fromVal >= toVal) return 'The 1st number must be smaller than 2nd number';
  if (fromVal < min)
    return `${trigger === 'inventory_status' ? 'Quantity' : 'Amount'} number must bigger than ${
      min > 0 ? min - 1 : min
    }`;
  return '';
};

export const handleSortable = (tags: string[], tagsCurrent: string[]) => {
  return tags.sort((a) => (tagsCurrent.includes(a) ? -1 : 1));
};

export const handleUnique = (tags: string[]) => {
  const result: string[] = tags.reduce((acc: string[], curr: string) => {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);
  return result;
};

export const handleSearch = (tags: string[], key: string) => {
  return tags.filter((tag) => tag.toLowerCase().includes(key.toLowerCase()));
};

export const getNewRangeValue = (from: string, to: string, min: number, type: CompareType) => {
  let fromNew = Number(from);
  let toNew = Number(to);
  if (fromNew < min) fromNew = min;
  if (type === CompareType.BETWEEN && fromNew >= toNew) toNew = fromNew + 1;
  return {
    from: fromNew.toString(),
    to: toNew.toString(),
  };
};
