<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import type { THEME_DARK } from '../../const';

import { GRADIENT_PROVIDE_KEY } from '../../composables/useGradientColor';
import { strictInject } from '../../helpers';
import { THEME_LIGHT } from '../../const';

const props = defineProps<{
  theme: typeof THEME_DARK | typeof THEME_LIGHT;
  area?: HTMLElement;
  pointID: string;
  isSelectedPoint: boolean;
  color: string;
  position: number;
  length: number;
  isTransparent: boolean;
  showDeletedColor: boolean;
}>();

const emits = defineEmits<{
  (e: 'onRemoveColor', pointID: string): void;
  (e: 'onShowDeletedColor', showDeletedColor: boolean): void;
}>();

const { updatePosition, selectPoint } = strictInject(GRADIENT_PROVIDE_KEY);

const item = ref<HTMLElement>();
const cursorStyle = ref('cursor-grab');

const itemStyle = computed(() => {
  const rect = props.area?.getBoundingClientRect();
  const width = (props.position / 100) * (rect?.width || 194);
  return {
    transform: `translateX(${width}px)`,
  };
});

const onMouseMove = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  selectPoint(props.pointID);
  if (props.showDeletedColor) emits('onShowDeletedColor', false);

  if (props.area) {
    const rect = props.area.getBoundingClientRect();
    let x = e.clientX - rect.left;
    if (x > rect.width) {
      x = rect.width;
    }
    if (x < 0) {
      x = 0;
    }
    const position = Math.floor((x / rect.width) * 100);
    updatePosition(position);
  }
};

const removeColorPoint = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
  emits('onRemoveColor', props.pointID);
};

onMounted(() => {
  item.value?.addEventListener('mousedown', (e) => {
    if (e.button === 2) return;
    cursorStyle.value = 'cursor-grabbing';
    onMouseMove(e);
    document.addEventListener('mousemove', onMouseMove);
  });
  document.addEventListener('mouseup', () => {
    cursorStyle.value = 'cursor-grab';
    document.removeEventListener('mousemove', onMouseMove);
    emits('onShowDeletedColor', true);
  });
});

const onOpenContextMenu = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  return false;
};

onUnmounted(() => {
  cursorStyle.value = 'cursor-grab';
  document.removeEventListener('mousemove', onMouseMove);
});
</script>

<template>
  <div class="group relative">
    <div
      class="pointer-events-none absolute top-[-42px] left-[-13px] z-50 items-center justify-center bg-transparent pb-4 opacity-0"
      :style="{
        ...itemStyle,
        transition: 'opacity 300ms ease',
      }"
      :class="{
        'group-hover:!pointer-events-auto group-hover:!opacity-100': length > 2 && showDeletedColor,
      }"
      @click="onOpenContextMenu">
      <GButtonV2 type="tertiary" only-icon="delete-version" size="normal" @click="removeColorPoint"></GButtonV2>
    </div>
    <div
      ref="item"
      data-test="editor-control-gradientPicker-point"
      :class="`absolute -top-4 !z-20 -ml-8 inline-block h-16 w-16  rounded-full hover:border-white ${cursorStyle}`"
      :style="{
        ...itemStyle,
        boxShadow: theme === THEME_LIGHT ? '0px 4px 16px 0px #0000001F' : '',
        zIndex: isSelectedPoint ? '99' : '5',
      }"
      oncontextmenu="return false"
      @mousedown.right="onOpenContextMenu">
      <div
        class="absolute -top-10 -left-10 !z-20 flex h-36 w-36 items-center justify-center rounded-full !bg-transparent">
        <div
          :style="{
            boxShadow: theme === THEME_LIGHT ? '0px 4px 16px 0px #0000001F' : '',
          }"
          class="bg-dark-400 z-5 border-text-dark-300 h-28 w-28 rounded-full border group-hover:border-white"
          :class="{
            '!bg-white': theme === THEME_LIGHT,
            '!border-primary-300 group-hover:!border-primary-300': isSelectedPoint,
          }" />
      </div>
      <div
        class="relative !z-50 h-16 w-16 rounded-full"
        :style="{ background: isTransparent ? '#ffffff' : color }"></div>
    </div>
  </div>
</template>
