<script lang="ts" setup>
import { ref, watch } from 'vue';
import PickLinkModal from './modal/PickLinkModal.vue';
import Segment from './Segment.vue';
import type { TabType } from '../type/pickLink';
import { debounce } from '../helpers/debounce';

type List = { title?: string; handle?: string; id?: string };

type Props = {
  id?: string;
  value?: { link?: string; target?: string };
  list?: List[];
  loading?: boolean;
  instant?: boolean;
  pickLinkLabel?: string;
  shopDomain?: string;
  inputSearchComponent?: Object;
  isShowBanner?: boolean;
  getCurrentTab: TabType;
};

const props = defineProps<Props>();

const pickLinkVisibility = ref<boolean>(false);
const enteredInput = ref(!!props.value?.link?.length);
const refInput = ref<HTMLInputElement>();

const emit = defineEmits<{
  (e: 'control-search', value?: string): void;
  (e: 'control-on-change', controlId?: string | number, value?: any): void;
  (e: 'control-change', controlId?: string | number, value?: any): void;
  (e: 'control-focus', controlId?: string | number, value?: any): void;
  (e: 'control-blur', controlId?: string | number, value?: any): void;
  (e: 'set-tab-selected', tab?: TabType): void;
  (e: 'showMore'): void;
  (e: 'create-popup'): void;
  (e: 'close-banner'): void;
  (e: 'save-current-tab', value: string | undefined): void;
}>();

const val = ref(props.value ?? { link: '/', target: '_self' });
const targets = ref<{ label: string; value: string }[]>([
  { label: 'Current', value: '_self' },
  { label: 'New tab', value: '_blank' },
]);

const setTabSelected = (tab?: TabType) => {
  emit('set-tab-selected', tab);
};

const close = () => {
  pickLinkVisibility.value = false;
};

const handleClearUrl = () => {
  val.value.link = '';
  changeLink('');
  enteredInput.value = false;

  refInput.value?.focus();
};

const save = (value?: { link: string; target: string; title: string; selectedTab?: string }) => {
  emit('save-current-tab', value?.selectedTab || 'custom');

  if (value) {
    val.value = value;

    if (value.link.length) {
      enteredInput.value = true;
    }

    emit('control-change', props.id, val.value);
  }
  pickLinkVisibility.value = false;
};

const changeTarget = (_id: any, value: any) => {
  val.value.target = value;
  emit('control-change', props.id, val.value);
};

const changeLink = (value: any) => {
  enteredInput.value = true;
  val.value.link = value;
  enteredInput.value = true;
  debounce(() => {
    emit('control-change', props.id, val.value);
  }, 600);
};
const onSearch = (value?: string) => {
  emit('control-search', value);
};

const handleOnBlurInput = (value?: string) => {
  if (value === '') enteredInput.value = false;
};

watch(pickLinkVisibility, (v) => {
  if (v) {
    emit('set-tab-selected', props.getCurrentTab);
    if (props.getCurrentTab) {
      setTabSelected(props.getCurrentTab);
    } else {
      setTabSelected('products');
    }
  }
});

watch(
  () => props.value,
  (value) => {
    if (value !== undefined) val.value = value;
  },
);
</script>
<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div>
      <div class="flex flex-col">
        <div class="flex items-start justify-between" :class="{ 'mb-16': enteredInput }">
          <div
            class="gemx-control-label text-12 font-regular text-text-dark-300 flex min-h-[36px] items-center pb-8 pt-10">
            {{ pickLinkLabel || 'Source URL' }}
          </div>
          <div class="w-[140px]">
            <div class="relative mb-8 w-full">
              <g-input
                ref="refInput"
                type="text"
                input-style="secondary"
                placeholder="E.g. https://gempages.net"
                :classes="val.link ? '!pr-32' : ''"
                :value="val.link"
                icon-position="last"
                @on-change="changeLink"
                @handle-blur="handleOnBlurInput">
                <template #icon>
                  <button
                    v-if="val.link"
                    class="hover:bg-dark-250 relative right-4 rounded-xl p-6"
                    @click="handleClearUrl">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L7.29289 8L5.14645 10.1464C4.95118 10.3417 4.95118 10.6583 5.14645 10.8536C5.34171 11.0488 5.65829 11.0488 5.85355 10.8536L8 8.70711L10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464L8.70711 8L10.8536 5.85355C11.0488 5.65829 11.0488 5.34171 10.8536 5.14645C10.6583 4.95118 10.3417 4.95118 10.1464 5.14645L8 7.29289L5.85355 5.14645Z"
                        fill="#E2E2E2" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
                        fill="#E2E2E2" />
                    </svg></button
                ></template>
              </g-input>
            </div>
            <GButtonV2
              type="secondary"
              button-width="full"
              size="medium"
              data-test="editor-control-button-pick-link"
              icon-before="pick-link"
              icon-size="16"
              @click="pickLinkVisibility = true">
              Insert link
            </GButtonV2>
          </div>
        </div>
        <div v-if="enteredInput && props.getCurrentTab !== 'popup'" class="flex items-start justify-between">
          <div class="gemx-control-label text-12 font-regular text-text-dark-300 flex items-center py-8">
            Open link in
          </div>
          <Segment id="target" class="!w-[140px]" :value="val.target" :options="targets" @control-change="changeTarget">
          </Segment>
        </div>
      </div>

      <PickLinkModal
        v-if="pickLinkVisibility"
        :list="list"
        :value="val"
        :loading="loading"
        :targets="targets"
        :instant="instant"
        :shop-domain="shopDomain"
        :input-search-component="inputSearchComponent"
        :is-show-banner="isShowBanner"
        :get-current-tab="getCurrentTab"
        @close-banner="emit('close-banner')"
        @close="close"
        @save="save"
        @show-more="emit('showMore')"
        @create-popup="emit('create-popup')"
        @on-search="onSearch"
        @on-set-tab-selected="setTabSelected" />
    </div>
    <slot name="info"></slot>
  </div>
</template>
