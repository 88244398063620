<template>
  <div class="gemx-control gemx-control-input w-full text-white">
    <slot name="label"></slot>
    <div class="gemx-text-editor-wrap">
      <div v-if="optionsTypes && optionsTypes.length" ref="editorTool" class="gemx-list-item-format gap-8">
        <component
          :is="renderControl(option.type)"
          v-for="option in optionsTypes"
          :key="option.type"
          v-bind="option"
          @status-format-text="setFormatText"
          @clear-format-text="clearFormatText" />
      </div>
      <perfect-scrollbar
        class="gemx-scroll-body rounded-medium text-12 text-light-450 placeholder:text-text-dark-100 hover:bg-dark-200 focus:border-primary-300 disabled:border-dark-200 disabled:text-text-dark-100 bg-dark-400 mt-8 h-32 w-full border border-transparent p-8 outline-none transition duration-200 hover:border-transparent disabled:cursor-not-allowed">
        <div
          ref="gtTextEditor"
          class="gemx-text-editor"
          contenteditable="true"
          @blur="savePosition"
          @keyup="onKeyup"
          @mouseup="mouseup"
          @paste="textPlainData"
          v-html="val" />
      </perfect-scrollbar>
    </div>
    <div v-if="desc" class="gt_sub-desc" v-html="desc" />
    <slot name="info"></slot>
  </div>
</template>
<script>
import FormatBold from './item-editor/FormatBold.vue';
import FormatItalic from './item-editor/FormatItalic.vue';
import FormatUnderline from './item-editor/FormatUnderline.vue';
import FormatAlignLeft from './item-editor/FormatAlignLeft.vue';
import FormatAlignRight from './item-editor/FormatAlignRight.vue';
import FormatAlignCenter from './item-editor/FormatAlignCenter.vue';
import FormatColorText from './item-editor/FormatColorText.vue';
import InsertLink from './item-editor/InsertLink.vue';
import FormatOrderedList from './item-editor/FormatOrderedList.vue';
import FormatUnorderedList from './item-editor/FormatUnorderedList.vue';
export default {
  components: {
    FormatItalic,
    FormatUnderline,
    FormatAlignLeft,
    FormatAlignRight,
    FormatAlignCenter,
    FormatColorText,
    InsertLink,
    FormatOrderedList,
  },
  props: {
    id: [String, Number],
    type: String,
    title: String,
    desc: String,
    defaultParagraphSeparator: {
      type: String,
      required: false,
      default: () => 'p',
    },
    options: [Array, Object],
    value: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    readonly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    screens: {
      type: [Array, Object],
      default: () => [],
    },
  },
  emits: ['controlOnChange', 'controlChange'],
  data() {
    return {
      val: '', // value gốc
      cacheVal: '', // cache value khi đang edit content
      optionsTypes: [],
      sel: '',
      currentFormat: [],
      editorProps: {},
      ranges: [],
      savedSel: '',
    };
  },
  watch: {
    value(newV, oldV) {
      if (newV === oldV) return;
      if (newV === this.cacheVal) return;
      const $editorContent = this.$refs.gtTextEditor;
      this.$nextTick(() => {
        if (!$editorContent || !$editorContent?.className.includes('focus-visible')) {
          this.val = newV;
          this.cacheVal = newV;
        }
      });
      return;
    },
    options(newV, oldV) {
      if (newV === oldV) return;
      this.setOptions(newV);
    },
  },
  created() {
    this.val = this.value;
    this.cacheVal = this.value;
    this.setOptions(this.options);
    document.execCommand('defaultParagraphSeparator', false, this.defaultParagraphSeparator);
  },

  mounted() {
    document.addEventListener('selectionchange', this.setActiveTool);
  },
  unmounted() {
    document.removeEventListener('selectionchange', this.setActiveTool);
  },
  methods: {
    setOptions(options) {
      const optionsValue = [];
      for (let index = 0; index < options.length; index++) {
        const item = options[index];
        optionsValue[index] = {
          type: item,
        };
        if (item == 'foreColor') {
          optionsValue[index]['color'] = '#333333';
        }
      }
      this.optionsTypes = optionsValue;
    },
    setActiveTool() {
      const _this = this;
      if (_this.$refs.editorTool) {
        const tools = _this.$refs.editorTool.getElementsByTagName('button')
          ? _this.$refs.editorTool.getElementsByTagName('button')
          : _this.$refs.editorTool.getElementsByTagName('div');
        for (let i = 0; i < tools.length; i++) {
          tools[i].classList.remove('gt_active');
        }
        _this.optionsTypes.forEach((option) => {
          const type = option.type;
          switch (type) {
            case 'bold': {
              if (document.queryCommandValue(type) == 'true') _this.addActiveTool('gt_text_format_bold');
              break;
            }
            case 'italic': {
              if (document.queryCommandValue(type) == 'true') _this.addActiveTool('gt_text_format_italic');
              break;
            }
            case 'underline': {
              if (document.queryCommandValue(type) == 'true') _this.addActiveTool('gt_text_format_underline');
              break;
            }
            case 'justifyLeft':
              if (document.queryCommandValue(type) == 'true') _this.addActiveTool('gt_text_format_left');
              break;
            case 'justifyRight':
              if (document.queryCommandValue(type) == 'true') _this.addActiveTool('gt_text_format_right');
              break;
            case 'justifyCenter':
              if (document.queryCommandValue(type) == 'true') _this.addActiveTool('gt_text_format_center');
              break;
            case 'createlink': {
              const parentSelected = document.getSelection()?.focusNode?.parentNode;
              if (parentSelected && parentSelected.closest('.gemx-text-editor') && parentSelected.nodeName === 'A') {
                _this.addActiveTool('gt_text_insert_link_scope');
              }
              break;
            }
          }
        });
      }
    },
    addActiveTool(className) {
      if (window.editorTool) {
        const tool = window.editorTool.getElementsByClassName(className)[0];
        if (tool && tool.classList && !tool.classList.contains('gt_active')) {
          tool.classList.add('gt_active');
        }
      }
    },
    textPlainData(e) {
      e.preventDefault();
      // Append plain text
      const text = e.clipboardData.getData('text/plain');
      // if (!text) {
      //   text = e.clipboardData.getData('text/plain');
      // }
      document.execCommand('insertHTML', false, text);
    },
    // General Methods
    setValue(value) {
      if (value != this.val && value != this.cacheVal) {
        this.val = value;
        this.cacheVal = value;
      }
    },
    onChange() {
      const $editorContent = this.$refs.gtTextEditor;
      const value = $editorContent?.innerHTML;
      this.cacheVal = value;
      this.$emit('controlChange', this.id, value);
    },
    change() {
      const $editorContent = this.$refs.gtTextEditor;
      const value = $editorContent?.innerHTML;
      this.cacheVal = value;
      this.$emit('controlChange', this.id, value);
    },

    savePosition() {
      this.change();
      this.savedSel = '';
      this.savedSel = this.saveSelection();
    },
    saveSelection() {
      if (window.getSelection) {
        const sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          this.ranges = [];
          for (let i = 0, len = sel.rangeCount; i < len; ++i) {
            this.ranges.push(sel.getRangeAt(i));
          }
          return this.ranges;
        }
      } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange();
      }
      return null;
    },
    restoreSelection(savedSel) {
      if (savedSel) {
        if (window.getSelection) {
          const sel = window.getSelection();
          sel.removeAllRanges();
          for (let i = 0, len = savedSel.length; i < len; ++i) {
            sel.addRange(savedSel[i]);
          }
        } else if (document.selection && savedSel.select) {
          savedSel.select();
        }
      }
    },
    renderControl(option) {
      switch (option) {
        case 'bold':
          return FormatBold;
        case 'italic':
          return FormatItalic;
        case 'underline':
          return FormatUnderline;
        case 'justifyLeft':
          return FormatAlignLeft;
        case 'justifyRight':
          return FormatAlignRight;
        case 'justifyCenter':
          return FormatAlignCenter;
        case 'foreColor':
          return FormatColorText;
        case 'createlink':
          return InsertLink;
        case 'insertOrderedList':
          return FormatOrderedList;
        case 'insertUnorderedList':
          return FormatUnorderedList;
        default:
          return false;
      }
    },
    mouseup() {
      window.editorTool = this.$refs.editorTool;
      const tools = document.getElementsByTagName('BUTTON');
      for (let i = 0; i < tools.length; i++) {
        tools[i].classList.remove('gt_active');
      }
      this.onChangeFormat();
    },
    onKeyup() {
      this.onChange();
      const keyCode = event.keyCode;
      if (event.target.textContent.length <= 0) {
        for (let i = 0; i < this.currentFormat.length; i++) {
          const itemButtonFormat = this.currentFormat[i];
          itemButtonFormat.classButton.classList.remove('gt_active');
        }
      }

      if ((keyCode == 37) | (keyCode == 38) | (keyCode == 39) | (keyCode == 40)) {
        this.onChangeFormat();
      }
    },
    onChangeFormat() {
      if (this.currentFormat && this.currentFormat.length > 0) {
        for (let index = 0; index < this.currentFormat.length; index++) {
          const item = this.currentFormat[index];
          if (item.type == 'foreColor') {
            const selected = window.getSelection().focusNode.parentNode;
            const color = selected.getAttribute('color');

            if (color) {
              this.optionsTypes = this.optionsTypes.map(function (option) {
                if (option.type == 'foreColor') {
                  if (option.color) {
                    option.color = color;
                  }
                }
                return option;
              });
            }
          }

          if (document.queryCommandValue(item.type) == 'true') {
            if (!item.classButton.classList.contains('gt_active')) {
              item.classButton.classList.add('gt_active');
            }
          } else {
            item.classButton.classList.remove('gt_active');
          }
        }
      }
    },
    getSelectionHtml() {
      let html = '';
      if (typeof window.getSelection != 'undefined') {
        const sel = window.getSelection();
        if (sel.rangeCount) {
          const container = document.createElement('div');
          for (let i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
        }
      } else if (typeof document.selection != 'undefined') {
        if (document.selection.type == 'Text') {
          html = document.selection.createRange().htmlText;
        }
      }
      return html;
    },
    insertLink(data) {
      this.restoreSelection(this.savedSel);

      if (data.url !== '') {
        let urlInsert = '';
        if (data.openNewTab) {
          urlInsert = '<a href="' + data.url + '" target="_blank">' + data.text + '</a>';
        } else {
          urlInsert = '<a href="' + data.url + '">' + data.text + '</a>';
        }
        document.execCommand('insertHTML', false, urlInsert);
      } else {
        document.execCommand('unlink', false, false);
      }
    },
    clearFormatText() {
      const $content = this.$refs.gtTextEditor;
      $content.focus();
      document.execCommand('forecolor', false, '#ffffff');
      this.change();
    },
    setFormatText(obj) {
      const $content = this.$refs.gtTextEditor;
      $content.focus();
      if (obj.type == 'CreateLink') {
        this.insertLink(obj);
        return;
      }
      if (this.currentFormat.length > 0) {
        const filterFormat = this.currentFormat.filter(function (item) {
          if (item.type == obj.type) {
            return item;
          }
        });

        if (filterFormat.length <= 0) {
          this.currentFormat.push({
            type: obj.type,
            classButton: obj.classButton,
          });
        }
      } else {
        this.currentFormat.push({
          type: obj.type,
          classButton: obj.classButton,
        });
      }

      this.setExecCommand(obj);
    },
    setExecCommand(obj) {
      if (document.getSelection().type !== 'Caret') {
        if (document.queryCommandState(obj.type) == false) {
          if (!obj.classButton.classList.contains('gt_active')) {
            obj.classButton.classList.add('gt_active');
          }
          document.execCommand(obj.type, false, obj.value);
        } else {
          obj.classButton.classList.remove('gt_active');
          document.execCommand(obj.type, true, obj.value);
        }
        this.change();
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.gemx-text-editor-wrap {
  width: 100%;
  .gemx-list-item-format {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    position: relative;

    button[role='button'] {
      border: 1px solid #494949;
      border-radius: 3px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #323232;
      fill: #e2e2e2;
      transition: all 0.3s;
      svg {
        width: 22px;
        height: 22px;
        margin: 0;
      }
      &:hover {
        background: #515151;
      }
    }
  }

  .gemx-text-editor {
    font-size: theme('fontSize.12');
    color: theme('colors.light.450');
    outline: none;
  }

  .gemx-scroll-body {
    min-height: 200px;
    max-height: 420px;
    outline: none;
  }
}
</style>
