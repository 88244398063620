/* eslint-disable */
export type ColorInput = {
  r?: number;
  g?: number;
  b?: number;
  a?: number;
  hue?: number;
  saturation?: number;
  value?: number;
  lightness?: number;
  format?: 'HSV' | 'HSL';
};
export class Color {
  private static instance: Color;

  r = 0;
  g = 0;
  b = 0;
  a = 1;
  hue = 0;
  saturation = 0;
  value = 0;
  lightness = 0;
  format: NonNullable<ColorInput['format']> = 'HSV';
  constructor(input?: ColorInput) {
    this.r = input?.r ?? 0;
    this.g = input?.g ?? 0;
    this.b = input?.b ?? 0;
    this.a = input?.a ?? 1;
    this.hue = input?.hue ?? 0;
    this.saturation = input?.saturation ?? 0;
    this.value = input?.value ?? 0;
    this.lightness = input?.lightness ?? 0;
    this.format = input?.format ?? 'HSV';
    this.updateHSX();
  }

  public static getInstance(): Color {
    if (!Color.instance) {
      Color.instance = new Color();
    }

    return Color.instance;
  }
  public static fromString(str?: string): Color {
    if (str) {
      if (str.startsWith('#')) {
        return this.fromHex(str);
      } else if (str.startsWith('rgb')) {
        return this.fromRGBAString(str);
      } else {
        return this.fromHex(`#${str}`);
      }
    } else {
      return this.getInstance();
    }
  }

  private static fromHex(hex: string): Color {
    const input = Color.hexToRGB(hex);

    return new Color(input);
  }

  private static fromRGBAString(str: string): Color {
    const input = this.RGBAtoObject(str);
    return new Color(input);
  }
  static hexToRGB(hex: string) {
    let alpha = false,
      hVal = hex.slice(hex.startsWith('#') ? 1 : 0);
    if (hVal.length === 3) hVal = [...hVal].map((x) => x + x).join('');
    else if (hVal.length === 8) alpha = true;
    const h = parseInt(hVal, 16);
    const r = h >>> (alpha ? 24 : 16);
    const g = (h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8);
    const b = (h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0);
    const a = alpha ? h & 0x000000ff : undefined;
    return { r, g, b, a };
  }

  static findColorBetween(left: Color, right: Color, percentage: number) {
    const components = ['r', 'g', 'b', 'a'] as const;
    const color: ColorInput = components.reduce((acc, c) => {
      const value = left[c] + ((right[c] - left[c]) * percentage) / 100;
      if (c === 'a') {
        const val: string = Math.abs((left[c] ?? 1) + (((right[c] ?? 1) - (left[c] ?? 1)) * percentage) / 100).toFixed(
          2,
        );
        return {
          ...acc,
          [c]: Number(val),
        };
      }
      return {
        ...acc,
        [c]: Math.round(value),
      };
    }, {});
    return new Color(color);
  }
  static RGBAtoObject = (rgbStr: string) => {
    const [r = 0, g = 0, b = 0, a = 1] = rgbStr
      .replace(/\s/g, '')
      .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
      ?.map(Number)
      .filter((x) => !isNaN(x)) ?? [0, 0, 0, 1];
    return { r, g, b, a };
  };

  clone() {
    return new Color(this);
  }
  setFormat(format: NonNullable<ColorInput['format']>) {
    if (format === 'HSV') this.format = 'HSV';
    if (format === 'HSL') this.format = 'HSL';
  }
  isValidRGBValue(value?: number) {
    return typeof value === 'number' && isNaN(value) === false && value >= 0 && value <= 255;
  }
  setRGBA(red: number, green: number, blue: number, alpha?: number) {
    if (
      this.isValidRGBValue(red) === false ||
      this.isValidRGBValue(green) === false ||
      this.isValidRGBValue(blue) === false
    )
      return;

    this.r = red | 0;
    this.g = green | 0;
    this.b = blue | 0;

    if (this.isValidRGBValue(alpha) === true) this.a = alpha ?? 1;
  }
  setAlpha(value: number) {
    this.a = value;
    this.updateHSX();
  }
  setByName(name: 'r' | 'g' | 'b', value: number) {
    if (name === 'r' || name === 'g' || name === 'b') {
      if (this.isValidRGBValue(value) === false) return;

      this[name] = value;
      this.updateHSX();
    }
  }
  setHSV(hue: number, saturation: number, value: number) {
    this.hue = hue;
    this.saturation = saturation;
    this.value = value;
    this.HSVtoRGB();
  }

  setHSL(hue: number, saturation: number, lightness: number) {
    this.hue = hue;
    this.saturation = saturation;
    this.lightness = lightness;
    this.HSLtoRGB();
  }

  setHue(value?: number) {
    if (typeof value !== 'number' || isNaN(value) === true || value < 0 || value > 359) return;
    this.hue = value;
    this.updateRGB();
  }
  setSaturation(value?: number) {
    if (typeof value !== 'number' || isNaN(value) === true || value < 0 || value > 100) return;
    this.saturation = value;
    this.updateRGB();
  }
  setValue(value?: number) {
    if (typeof value !== 'number' || isNaN(value) === true || value < 0 || value > 100) return;
    this.value = value;
    this.HSVtoRGB();
  }
  setLightness(value?: number) {
    if (typeof value !== 'number' || isNaN(value) === true || value < 0 || value > 100) return;
    this.lightness = value;
    this.HSLtoRGB();
  }
  setHexa(value: string) {
    const valid = /(^#{0,1}[0-9A-F]{6}$)|(^#{0,1}[0-9A-F]{3}$)/i.test(value);

    if (valid !== true) return;

    if (value[0] === '#') value = value.slice(1, value.length);

    if (value.length === 3) value = value.replace(/([0-9A-F])([0-9A-F])([0-9A-F])/i, '$1$1$2$2$3$3');

    this.r = parseInt(value.substring(0, 2), 16);
    this.g = parseInt(value.substring(2, 2), 16);
    this.b = parseInt(value.substring(4, 2), 16);

    this.a = 1;
    this.RGBtoHSV();
  }
  /*========== Conversion Methods ==========*/
  convertToHSL() {
    if (this.format === 'HSL') return;

    this.setFormat('HSL');
    this.RGBtoHSL();
  }
  convertToHSV() {
    if (this.format === 'HSV') return;

    this.setFormat('HSV');
    this.RGBtoHSV();
  }

  /*========== Update Methods ==========*/
  updateRGB() {
    if (this.format === 'HSV') {
      this.HSVtoRGB();
      return;
    }

    if (this.format === 'HSL') {
      this.HSLtoRGB();
      return;
    }
  }
  updateHSX() {
    if (this.format === 'HSV') {
      this.RGBtoHSV();
      return;
    }

    if (this.format === 'HSL') {
      this.RGBtoHSL();
      return;
    }
  }

  rgbToHex = (r: number, g: number, b: number) => {
    return ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0');
  };
  private HSLtoRGB() {
    const h = this.hue;
    let s = this.saturation;
    let l = this.lightness;

    s /= 100;
    l /= 100;
    const k = (n: number) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    const r = 255 * f(0);
    const g = 255 * f(8);
    const b = 255 * f(4);
    this.setRGBA(r, g, b);
    return;
  }

  private HSVtoRGB() {
    const H = this.hue / 360;
    const S = this.saturation / 100;
    const V = this.value / 100;

    let i = Math.floor(H * 6);
    let f = H * 6 - i;
    let p = V * (1 - S);
    let q = V * (1 - f * S);
    let t = V * (1 - (1 - f) * S);

    switch (i % 6) {
      case 0:
        this.setRGBA(V * 255, t * 255, p * 255);
        break;
      case 1:
        this.setRGBA(q * 255, V * 255, p * 255);
        break;
      case 2:
        this.setRGBA(p * 255, V * 255, t * 255);
        break;
      case 3:
        this.setRGBA(p * 255, q * 255, V * 255);
        break;
      case 4:
        this.setRGBA(t * 255, p * 255, V * 255);
        break;
      case 5:
        this.setRGBA(V * 255, p * 255, q * 255);
        break;
    }
  }

  private RGBtoHSV() {
    const red = this.r / 255;
    const green = this.g / 255;
    const blue = this.b / 255;

    const cmax = Math.max(red, green, blue);
    const cmin = Math.min(red, green, blue);
    const delta = cmax - cmin;
    let hue = 0;
    let saturation = 0;

    if (delta) {
      if (cmax === red) {
        hue = ((green - blue) / delta) % 6;
      } else if (cmax === green) {
        hue = (blue - red) / delta + 2;
      } else {
        hue = (red - green) / delta + 4;
      }
      hue = Math.round(hue * 60);
      if (hue < 0) hue += 360;
    }

    if (cmax !== 0) {
      saturation = delta / cmax;
    }

    this.hue = hue;
    this.saturation = +(saturation * 100).toFixed(1);
    this.value = +(cmax * 100).toFixed(1);
  }

  private RGBtoHSL() {
    let r = this.r;
    let g = this.g;
    let b = this.b;
    r /= 255;
    g /= 255;
    b /= 255;
    const l = Math.max(r, g, b);
    const s = l - Math.min(r, g, b);
    const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;
    this.hue = 60 * h < 0 ? 60 * h + 360 : 60 * h;
    this.saturation = 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0);
    this.lightness = (100 * (2 * l - s)) / 2;
  }

  /*========== Get Methods ==========*/
  getHexColor() {
    let r = this.r.toString(16);
    let g = this.g.toString(16);
    let b = this.b.toString(16);
    if (this.r < 16) r = '0' + r;
    if (this.g < 16) g = '0' + g;
    if (this.b < 16) b = '0' + b;
    const value = '#' + r + g + b;
    return value.toUpperCase();
  }
  getRGBA() {
    const rgb = '(' + this.r + ', ' + this.g + ', ' + this.b;
    let a = '';
    let v = '';
    const x = this.a;
    if (x !== 1) {
      a = 'a';
      v = ', ' + x;
    }

    const value = 'rgb' + a + rgb + v + ')';
    return value;
  }

  formatRGBA(a: number = 1) {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${a})`;
  }
  getHSLA(): string {
    if (this.format === 'HSV') {
      const color = new Color(this);
      color.setFormat('HSL');
      color.updateHSX();
      return color.getHSLA();
    }

    let a = '';
    let v = '';
    const hsl = '(' + this.hue + ', ' + this.saturation + '%, ' + this.lightness + '%';
    const x = this.a;
    if (x !== 1) {
      a = 'a';
      v = ', ' + x;
    }

    const value = 'hsl' + a + hsl + v + ')';
    return value;
  }
  getColor() {
    if (this.a === 1) return this.getHexColor();
    return this.getRGBA();
  }
}
