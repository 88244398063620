<script lang="ts" setup>
import { GButtonV2, GTooltip } from '@gem/uikit';

const props = defineProps<{
  showDeleteConfirm: boolean;
  disableDelete?: boolean;
}>();

const emit = defineEmits<{
  (event: 'show'): void;
  (event: 'cancel'): void;
  (event: 'delete'): void;
}>();

const onShowDelete = () => {
  if (props.disableDelete) return;
  emit('show');
};
</script>

<template>
  <div class="flex items-center gap-4">
    <GTooltip
      :disabled="disableDelete !== true"
      content-class="w-[200px] !whitespace-normal break-words"
      placement="top-end">
      <GButtonV2
        v-if="!showDeleteConfirm"
        icon-size="16"
        size="normal"
        type="ghost"
        only-icon="trash-20"
        :disable="disableDelete"
        :class="`${disableDelete ? '' : '!text-red-200'}`"
        @click="onShowDelete" />
      <template #content> This is required condition for the Product Badge to work properly. </template>
    </GTooltip>
    <template v-if="showDeleteConfirm">
      <GButtonV2 size="normal" type="tertiary" @click="emit('cancel')">Cancel</GButtonV2>
      <GButtonV2 size="normal" type="danger" @click="emit('delete')">Delete</GButtonV2>
    </template>
  </div>
</template>
