<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

const emit = defineEmits<{
  (e: 'edit', index: string): void;
  (e: 'changeTitle', value: string, index: string): void;
  (e: 'change', value: Event | KeyboardEvent, index: string): void;
  (e: 'duplicate', index: string): void;
  (e: 'delete', index: string): void;
  (e: 'moveToIndex', index: number): void;
  (e: 'changeStatusEditTitleItem', id: string, value?: boolean): void;
  (e: 'changeStatusBlurringTitleItem', id: string, value?: boolean): void;
  (e: 'click', index: string): void;
  (e: 'hover', index?: string): void;
}>();

export type ChildItemTag =
  | 'AccordionItem'
  | 'Accordion'
  | 'TabItem'
  | 'CarouselItem'
  | 'IconListItem'
  | 'IconListItemHoz';

const props = defineProps<{
  index?: string;
  title?: string;
  icon?: string;
  tag?: ChildItemTag;
  isActive?: boolean;
  isExpand?: boolean;
  isEditingTitle?: boolean | null;
}>();

const showAction = ref(false);
const hasHover = ref(false);
const title = ref(props.title);

const inputTitleRef = ref<HTMLInputElement | null>(null);

const triggerDestroyTextInlineForIcon = () => {
  const customEvent = new CustomEvent('destroyTextInlineForIcon', { detail: null });
  dispatchEvent(customEvent);
};

const handleDeleteItem = () => {
  triggerDestroyTextInlineForIcon();
  showAction.value = true;
};

const cancelDeleteItem = () => {
  showAction.value = false;
};

const deleteItem = () => {
  showAction.value = false;
  triggerDestroyTextInlineForIcon();
  if (props.index) emit('delete', props.index);
};

const showEditTitle = () => {
  if (props.index && props.isExpand) emit('edit', props.index);
};

const handleDuplicateItem = () => {
  if (props.index) emit('duplicate', props.index);
};
const handleEditTitleItem = () => {
  if (props.index) {
    emit('changeStatusEditTitleItem', props.index);
    setTimeout(() => {
      if (inputTitleRef.value) inputTitleRef.value.focus();
    }, 0);
  }
};

const onChange = (value: string) => {
  title.value = value;
  if (props.index) emit('changeTitle', value, props.index);
};
const onBlur = (value: Event | KeyboardEvent) => {
  if (props.index) {
    emit('changeStatusBlurringTitleItem', props.index);
    emit('change', value, props.index);
  }
};

const preventDrag = (event: DragEvent) => {
  event.preventDefault();
  event.stopPropagation();
};
const handleMoveTotIndex = () => {
  if (props.tag == 'CarouselItem') emit('moveToIndex', Number(props.index));
};

const handleClick = () => {
  if (props.index) emit('click', props.index);
};

watch(
  () => props.title,
  (value) => {
    title.value = value;
  },
);

onMounted(() => {
  if (props.isEditingTitle) inputTitleRef.value?.focus();
});

watch(
  () => props.isEditingTitle,
  (value) => {
    if (value) {
      if (inputTitleRef.value) inputTitleRef.value.focus();
    }
  },
);
const handleHoverItem = () => {
  if (hasHover.value) {
    return;
  }
  hasHover.value = true;
  if (props.index !== undefined) emit('hover', props.index);
};

const handleLeaveItem = () => {
  if (!hasHover.value) {
    return;
  }
  hasHover.value = false;
};
</script>

<template>
  <div :class="{ 'border-dark-300 mb-8 overflow-hidden rounded-xl border': isActive }" @click="handleMoveTotIndex">
    <div
      :class="{
        'mb-8 rounded-xl': !isActive,
        'rounded-t-xl border-b-0': isActive,
      }"
      class="bg-dark-400 group-[.sortable-fallback]/sortable-item:bg-dark-400 group-[.sortable-ghost]/sortable-item:bg-dark-250 group-[&:not(.dragging)]/sortable:hover:bg-dark-250 relative flex h-36 cursor-pointer select-none items-center justify-between overflow-hidden pl-8"
      @mouseover="handleHoverItem"
      @click="handleClick"
      @mouseleave="handleLeaveItem">
      <div class="relative z-10 flex w-full flex-1 items-center overflow-hidden">
        <span
          class="text-light-450 hover:text-light-450 hover:bg-dark-150 mr-4 flex h-[28px] w-[28px] cursor-ns-resize items-center justify-center rounded-xl transition-all duration-200"
          :class="{ 'rotate-180': isActive }"
          @click="showEditTitle()">
          <template v-if="tag && ['IconListItemHoz', 'TabItem', 'AccordionItem'].includes(tag)">
            <div v-html="icon"></div>
          </template>
          <template v-else>
            <g-base-icon v-if="!isExpand" name="item-drag" width="16" height="16" viewBox="0 0 16 16"></g-base-icon>
            <g-base-icon v-else name="instant-arrow-down" width="16" height="16" viewBox="0 0 16 16"></g-base-icon>
          </template>
        </span>
        <span
          v-show="!isEditingTitle"
          class="text-12 text-light-100 line-clamp-1 w-full overflow-hidden text-ellipsis"
          :class="{ 'max-w-[114px]': showAction, 'max-w-[160px]': !showAction }"
          v-html="title">
        </span>
        <g-input
          v-show="isEditingTitle"
          ref="inputTitleRef"
          class="!bg-dark-400 text-light-450 h-32 w-full rounded-xl leading-[18px]"
          input-style="primary"
          size="small"
          :value="title"
          @blur="onBlur"
          @on-change="onChange">
        </g-input>
      </div>
      <template v-if="!isEditingTitle">
        <div
          v-if="!showAction"
          :class="{
            'px-0': !hasHover,
            'w-[80px]': hasHover,
          }"
          class="non-draggable relative z-10 flex h-full flex-shrink-0 items-center justify-center gap-4 hover:cursor-pointer">
          <template v-if="hasHover">
            <GButtonV2
              v-if="props.tag === 'CarouselItem'"
              :type="'ghost'"
              :only-icon="'edit'"
              :icon-view-box="'0 0 20 20'"
              size="normal"
              @click.stop="handleEditTitleItem"></GButtonV2>
            <GButtonV2
              :type="'ghost'"
              :only-icon="'duplicate-page'"
              :icon-view-box="'0 0 16 16'"
              size="normal"
              @click.stop="handleDuplicateItem"></GButtonV2>
            <GButtonV2
              :type="'ghost'"
              :only-icon="'trash'"
              :icon-view-box="'0 0 16 16'"
              size="normal"
              @click.stop="handleDeleteItem"></GButtonV2>
          </template>
        </div>
        <div v-else class="non-draggable flex h-full items-center gap-4 hover:cursor-pointer">
          <GButtonV2 type="tertiary" size="normal" @click.stop="cancelDeleteItem">Cancel </GButtonV2>
          <GButtonV2 type="danger" size="normal" @click.stop="deleteItem"> Delete </GButtonV2>
        </div>
      </template>
    </div>

    <div
      v-if="isActive"
      class="bg-dark-500 flex items-center justify-between p-16"
      draggable="true"
      @dragstart="preventDrag">
      <div class="text-12 text-text-dark-300 mb-4">Title</div>
      <g-input
        class="!bg-dark-400 text-light-450 max-w-input-horizontal h-36 w-full rounded-xl border-none leading-[18px]"
        size="small"
        :value="title"
        @blur="onBlur"
        @on-change="onChange">
      </g-input>
    </div>
  </div>
</template>
