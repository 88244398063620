<script lang="ts" setup>
import { ref, watch } from 'vue';
import ID from '../utils/id';

type Props = {
  id: string;
  children: [];
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();
const val = ref<any>(props.children);
// val.value = props.children;
const setValue = (value: any) => {
  if (value != val.value) {
    val.value = value;
  }
};
watch(props.children, (value) => {
  setValue(value);
});
const duplicateComponent = (child: any) => {
  const newChild = recursiveComponent(JSON.parse(JSON.stringify(child)));
  val.value.push(newChild);
  change();
};
const recursiveComponent = (component: any) => {
  component.uid = ID();
  if (component.children && component.children.length) {
    component.children = component.children.map((item: any) => recursiveComponent(item));
  }
  const newComponent = { ...component };
  return newComponent;
};

const deleteComponent = (uid: string) => {
  if (val.value.length == 1) {
    return;
  }
  const indexDelete = val.value.findIndex((item: any) => item.uid === uid);
  val.value.splice(indexDelete, 1);
  change();
};
const onChange = () => {
  emit('controlOnChange', props.id, val.value);
};

const change = () => {
  emit('controlChange', props.id, val.value);
};
// const focus = () => {
//   emit('controlFocus', props.id, val.value);
// };
// const blur = () => {
//   emit('controlBlur', props.id, val.value);
// };
</script>

<template>
  <div class="gemx-control gt_control-children">
    <slot name="label"></slot>
    <div class="gt_control-children--list">
      <draggable :list="val" @change="onChange()">
        <div v-for="(child, index) of val" :key="index" class="gt_control-children--item">
          <div class="gt_control-children--item__wrap">
            <div class="gt_control-children--item__arrow icon">
              <svg class="need_a_name" height="16px" viewBox="0 0 16 16">
                <path
                  d="M7,11.5c-0.13,0-0.26-0.05-0.35-0.15c-0.2-0.2-0.2-0.51,0-0.71L9.29,8L6.65,5.35c-0.2-0.2-0.2-0.51,0-0.71 s0.51-0.2,0.71,0l3,3c0.2,0.2,0.2,0.51,0,0.71l-3,3C7.26,11.45,7.13,11.5,7,11.5z"></path>
              </svg>
            </div>
            <div class="gt_control-children--item__text">
              {{ child.label }}
            </div>
          </div>
          <div class="gt_control-children--item__wrap ml-auto">
            <div class="gt_control-children--item__copy icon" @click="duplicateComponent(child)">
              <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.67969 12.7422H2.78906V12.7422C2.36622 12.7422 2.02344 12.3994 2.02344 11.9766V3.55469"
                  stroke-linecap="round"
                  stroke-linejoin="round"></path>
                <path
                  d="M4.0651 11.2109C3.78321 11.2109 3.55469 10.9824 3.55469 10.7005V1.76823C3.55469 1.48634 3.78321 1.25781 4.0651 1.25781H11.4661C11.748 1.25781 11.9766 1.48634 11.9766 1.76823V10.7005C11.9766 10.9824 11.748 11.2109 11.4661 11.2109H4.0651Z"
                  stroke-linecap="round"
                  stroke-linejoin="round"></path>
              </svg>
            </div>
            <div class="gt_control-children--item__delete icon" @click="deleteComponent(child.uid)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.0625 12.25H3.9375V12.25C3.45425 12.25 3.0625 11.8582 3.0625 11.375V3.5H10.9375V11.375C10.9375 11.8582 10.5457 12.25 10.0625 12.25V12.25Z"
                  stroke-linecap="round"
                  stroke-linejoin="round"></path>
                <path d="M5.6875 9.625V6.125" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M8.3125 9.625V6.125" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M1.3125 3.5H12.6875" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  d="M8.3125 1.75H5.6875V1.75C5.20425 1.75 4.8125 2.14175 4.8125 2.625V2.625V3.5H9.1875V2.625V2.625C9.1875 2.14175 8.79575 1.75 8.3125 1.75V1.75V1.75Z"
                  stroke-linecap="round"
                  stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<style lang="scss" scoped>
.gt_control-children {
  .gt_control-children--list {
    .gt_control-children--item {
      display: flex;
      align-items: center;
      background-color: #242424;
      margin-bottom: 1px;
      width: 250px;
      height: 38px;

      .gt_control-children--item__wrap {
        color: #fff;
        width: fit-content;
        display: flex;
        align-items: center;
      }
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    stroke: #fff;
  }
}
</style>
