<script lang="ts" setup>
import type { FontFamilyType, TypographyFamily, TypographyFontOption, TypographyV2Props } from '../../types';
import type { FontItem, FontName } from './types';
import { useToggle, useVirtualList } from '@vueuse/core';
import { useFloating, useOutsideClick } from '@gem/uikit';
import { useFontFamilySelect } from './composables/useFontFamilySelect';
import { flip, offset, shift } from '@floating-ui/core';
import type { Ref } from 'vue';
import { computed, onMounted, ref, watch, nextTick, inject } from 'vue';
import FontFamilyItem from './FontFamily/FontFamilyItem.vue';
import { cloneDeepObject } from '@gem/uikit/src/types/common';

type PropsType = {
  id: string;
  value?: string | TypographyV2Props['fontFamily'];
  options?: TypographyFontOption[];
  fontUploads?: TypographyFontOption[];
  wrapperClass?: string;
  middleware?: any[];
  currentComponentSetting?: any;
  globalStyleFont?: Record<string, FontItem>;
  shopStore?: any;
  editorStore?: any;
  isFreePlan?: boolean;
  themeFonts?: Partial<Record<FontName, FontItem>>;
  fontType?: 'google' | 'bunny';
};

const props = withDefaults(defineProps<PropsType>(), {
  middleware: () => [flip(), shift(), offset(4)],
});

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any): void;
  (e: 'handleChangeFile', event: Event): void;
  (e: 'handleDeleteFont', closeModal: () => void, dataFontCurrent: any): void;
  (e: 'handleSaveFontMeta', value: any, type: string): void;
  (e: 'goToPricing'): void;
  (e: 'handleSwitchFont'): void;
}>();

const pageMetaRecentlyValueParse = inject<Ref<TypographyFamily>>('pageMetaRecentlyValueParse');
const keyword = ref('');
const inputSearchFont = ref<HTMLInputElement | null>();
const isShowModal = ref(false);
const dataFontCurrent = ref({});
const [isShow, toggle] = useToggle(false);
const mapFontOptions = computed(() => {
  const options = props.options ?? [];
  return [...options];
});

const items = computed(() => {
  if (!mapFontOptions.value) return [];

  const trimmedKeyword = keyword.value.trim().toLowerCase();

  return mapFontOptions.value.filter((font) => {
    const fontValue = font.type === 'theme' ? resolveFontValue(font.value) ?? font.value : font.value;

    if (trimmedKeyword.length === 0) {
      return !isRecentFont({ value: fontValue, type: font.type });
    }

    return font.label?.toLowerCase().includes(trimmedKeyword) || fontValue?.toLowerCase().includes(trimmedKeyword);
  });
});

const mixItems = computed(() => {
  const createFontList = (fonts: any, label: string, isRecently?: boolean) => {
    if (!fonts) return [];
    const dataFonts = cloneDeepObject(fonts);

    const fontList = dataFonts.map((item: any) => {
      if (isRecently) {
        return {
          value: typeof item.value === 'string' ? item.value : item.value.value,
          type: typeof item.value === 'string' ? 'google' : item.value.type,
          isRecently: true,
        };
      }

      return {
        ...item,
        type: item?.type || 'google',
      };
    });

    if (fontList.length > 0) {
      fontList.unshift({
        label,
        isLabel: true,
      });
    }

    return fontList;
  };
  const recentlyFonts = createFontList(listFontRecently.value, 'Recently', true);
  const customFonts = createFontList(listFontCustom.value, 'Custom font');
  const themeFonts = createFontList(listFontTheme.value, 'Theme font');
  const optionsFonts =
    props.fontType === 'bunny'
      ? createFontList(listFontBunny.value, 'Bunny font')
      : createFontList(listFontGoogle.value, 'Google font');

  return [...(!keyword.value ? recentlyFonts : []), ...customFonts, ...themeFonts, ...optionsFonts];
});
const listFontRecently = computed(() => {
  const value = pageMetaRecentlyValueParse?.value;

  if (Array.isArray(value)) {
    const uniqueRecentFonts = removeDuplicateFonts(value);
    return uniqueRecentFonts;
  }

  return [];
});
const listFontCustom = computed(() => {
  const trimmedKeyword = keyword.value.trim().toLowerCase();
  const fontUploads = props.fontUploads ?? [];

  const filterFont = (font: TypographyFontOption) => {
    if (trimmedKeyword.length === 0) {
      return !isRecentFont(font);
    }
    return font.label?.toLowerCase().includes(trimmedKeyword) || font.value?.toLowerCase().includes(trimmedKeyword);
  };

  const filteredFontUploads = fontUploads.filter(filterFont);
  const currentCustomFonts = items.value.filter((item) => item.type === 'custom');

  return mergeFontCustomArrays(filteredFontUploads, currentCustomFonts);
});
const listFontTheme = computed(() => {
  return items.value.filter((item) => item.type === 'theme');
});
const listFontGoogle = computed(() => {
  return items.value.filter((item) => item.type === 'google' || !item.type);
});
const listFontBunny = computed(() => {
  return items.value.filter((item) => item.type === 'bunny' || !item.type);
});
const currentOption = computed(() => {
  if (!props.value) return;

  return getCurrentOption(props.value);
});
const currentIndex = computed(() => {
  return items.value.findIndex((item) => item.value === props.value);
});

// ======== HOOK ========
const { isRecentFont, resolveFontValue, isCheckedFontItem, removeDuplicateFonts } = useFontFamilySelect(props);
const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(mixItems, {
  itemHeight: 36,
  overscan: 10,
});

const {
  x,
  y,
  strategy,
  reference: button,
  floating: select,
} = useFloating({
  placement: 'bottom',
  strategy: 'absolute',
  middleware: props.middleware,
});

// ======== FUNCTION ========
const getCurrentOption = (fontFamily: TypographyV2Props['fontFamily']): TypographyFontOption | undefined => {
  if (typeof fontFamily === 'string') {
    const font = mapFontOptions.value?.find((item) => item.value === fontFamily);

    return (
      font ?? {
        label: fontFamily,
        value: fontFamily,
        type: 'google',
      }
    );
  }

  if (!fontFamily?.value) return;

  const font = mapFontOptions.value?.find((item) => item.value === fontFamily.value);
  return (
    font ?? {
      label: fontFamily.value,
      value: fontFamily.value,
      type: fontFamily.type,
    }
  );
};
const getStyleFontFamily = (font: string) => {
  if (['sans-serif'].includes(font)) {
    return font;
  }
  if (font.includes('--g-theme-font')) {
    return `var(${font})`;
  }
  return `'${font}'`;
};
const getVariantStyleFontFamily = (value?: string, type?: string): string | undefined => {
  if (type === 'theme') {
    if (value === props.themeFonts?.heading?.family) {
      return `--g-theme-font-heading`;
    }
    if (value === props.themeFonts?.body?.family) {
      return `--g-theme-font-body`;
    }
  }

  return;
};
const loadGoogleFontByName = (fontFamily: string, typeFont: FontFamilyType) => {
  const fontElementSettingClass = 'google-fonts-element';
  const fontUrl =
    typeFont === 'bunny'
      ? `https://fonts.bunny.net/css?family=${fontFamily}&display=fallback`
      : `https://fonts.googleapis.com/css?family=${fontFamily}`;
  const fontLinkElement = document.querySelector(`.${fontElementSettingClass}[data-font="${encodeURI(fontFamily)}"]`);
  if (fontLinkElement) {
    if (fontLinkElement.getAttribute('href') !== fontUrl) {
      fontLinkElement.setAttribute('href', fontUrl);
    }
  } else {
    const link = document.createElement('link');
    link.className = fontElementSettingClass;
    link.dataset.font = encodeURI(fontFamily);
    link.href = fontUrl;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }
};
const loadGoogleFonts = () => {
  const fonts = list.value;
  if (fonts?.length) {
    for (const font of fonts) {
      if (font?.data?.type !== 'google' && font?.data?.type !== 'bunny') continue;
      const fontFamily = (font?.data?.type === 'bunny' ? font.data.familyBackupValue : font.data.value) ?? '';
      loadGoogleFontByName(fontFamily, font?.data?.type);
    }
  }
};
function mergeFontCustomArrays(
  fontUploads: TypographyFontOption[],
  customFonts: TypographyFontOption[],
): TypographyFontOption[] {
  const combinedArray = [...fontUploads, ...customFonts];
  const mergedMap = combinedArray.reduce<Map<string, TypographyFontOption>>((acc, obj) => {
    if (acc.has(obj?.value)) {
      acc.set(obj?.value, { ...acc.get(obj?.value), ...obj });
    } else {
      acc.set(obj?.value, { ...obj });
    }
    return acc;
  }, new Map());

  return Array.from(mergedMap.values());
}

// ======== ON HANDLER ========
const onOpen = () => {
  toggle(!isShow.value);

  setTimeout(() => {
    // setTimeout: delay load 0s when open dropdown
    loadGoogleFonts();
  }, 0);
};
const onClose = () => {
  toggle(false);
};
const onOpenModal = (e: Event) => {
  e.stopPropagation();
  isShowModal.value = true;
};
const onCloseModal = () => {
  isShowModal.value = false;
};
const onChange = (value: any, type: FontFamilyType) => {
  onHandleSaveFontMeta({
    value,
    type,
  });
  onClose();
  emit('controlChange', props.id, {
    value,
    type,
  });
};
const onUploadFont = (event: Event) => {
  keyword.value = '';
  emit('handleChangeFile', event);
};
const onHandleSaveFontMeta = (data: TypographyFamily) => {
  const payload = cloneDeepObject(data);
  const variantStyle = getVariantStyleFontFamily(data.value, data.type);
  payload.value = variantStyle ?? data.value;

  emit('handleSaveFontMeta', payload, 'recently');
};
// ======== WATCH ========
watch(isShow, () => {
  if (isShow) {
    nextTick(() => {
      inputSearchFont?.value?.focus();
    });
  }
});
watch(list, (newVal) => {
  if (newVal) {
    loadGoogleFonts();
  }
});
watch(
  () => keyword.value.trim().toLowerCase(),
  (newVal) => {
    if (newVal) {
      scrollTo(0);
    } else {
      scrollTo(currentIndex.value);
    }
  },
);
onMounted(() => {
  if (
    currentOption.value?.value &&
    (currentOption.value?.type === 'google' || currentOption.value?.type === 'bunny' || !currentOption.value?.type)
  ) {
    loadGoogleFontByName(currentOption.value.value, currentOption.value?.type);
  }
});
useOutsideClick(select, onClose, { detectIframe: true, containSelectors: ['.btn-select-font'] });
</script>

<template>
  <div class="relative">
    <button
      ref="button"
      class="btn-select-font text-12 text-light-450 bg-dark-400 placeholder:text-text-dark-100 disabled:border-dark-200 disabled:text-text-dark-100 relative flex h-36 w-full items-center rounded-xl border px-8 outline-none transition duration-200 disabled:cursor-not-allowed"
      :class="[
        {
          'hover:!bg-dark-200 !border-primary-300': isShow,
          'hover:!bg-dark-200 border-transparent': !isShow,
        },
        wrapperClass,
      ]"
      data-test="editor-control-font-select"
      @click="() => onOpen()">
      <div class="flex w-full items-center justify-between">
        <span
          class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left"
          :style="{ 'font-family': getStyleFontFamily(currentOption?.value || ''), 'font-weight': '400' }">
          {{ resolveFontValue(currentOption?.value) }}
        </span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.13313 7.62204C5.31064 7.45932 5.59845 7.45932 5.77596 7.62204L10 11.4941L14.224 7.62204C14.4016 7.45932 14.6894 7.45932 14.8669 7.62204C15.0444 7.78476 15.0444 8.04858 14.8669 8.21129L10.3214 12.378C10.1439 12.5407 9.8561 12.5407 9.67859 12.378L5.13313 8.21129C4.95562 8.04858 4.95562 7.78476 5.13313 7.62204Z"
            fill="#E2E2E2"></path>
        </svg>
      </div>
    </button>
  </div>
  <Teleport to="#root-modal">
    <div
      ref="select"
      v-click-away="!isShowModal && isShow"
      class="font-family-option-select text-16 shadow-2dp sm:text-14 bg-dark-400 text-light-450 rounded-12 absolute z-[999] w-[330px] focus:outline-none"
      :class="{
        'invisible opacity-0': !isShow,
      }"
      :style="{
        position: strategy,
        top: y ? `${y}px` : '',
        left: x ? `${x - 16}px` : '0px',
        width: '330px',
      }">
      <div class="px-16 pt-16">
        <div class="mb-16 flex items-center justify-between">
          <p class="text-light-200 font-semibold leading-6">Font picker</p>
          <g-button
            type="ghost"
            class="text-light-450 ml-auto h-32 w-32 flex-none items-center justify-center !p-0"
            @click="onClose">
            <g-base-icon name="close" width="16" height="16" viewBox="0 0 20 20"></g-base-icon>
          </g-button>
        </div>
        <div class="bg-dark-300 mb-[12px] flex h-36 rounded-xl">
          <div class="flex aspect-square h-full items-center justify-center">
            <GBaseIcon name="search" width="16" class="shrink-0" />
          </div>
          <input
            ref="inputSearchFont"
            v-model="keyword"
            data-test="editor-control-font-input"
            placeholder="Search fonts"
            class="text-12 h-full flex-1 bg-transparent pr-8 outline-none" />
        </div>
        <div
          v-bind="containerProps"
          class="scrollbar:!w-[8px] scrollbar:bg-transparent scrollbar-track:!rounded-full scrollbar-track:!bg-dark-300 scrollbar-thumb:!rounded-full scrollbar-track:!cursor-grabbing scrollbar-thumb:!bg-light-450 h-[295px] overflow-x-hidden">
          <div v-bind="wrapperProps">
            <div class="bg-primary-300/10 mb-[12px] flex h-[44px] items-center gap-4 rounded-xl p-8">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 10.5C15 11.0523 14.5523 11.5 14 11.5C13.4477 11.5 13 11.0523 13 10.5C13 9.94772 13.4477 9.5 14 9.5C14.5523 9.5 15 9.94772 15 10.5Z"
                  fill="#7190FF" />
                <path
                  d="M14.75 13.25C14.75 12.8358 14.4142 12.5 14 12.5C13.5858 12.5 13.25 12.8358 13.25 13.25V17.75C13.25 18.1642 13.5858 18.5 14 18.5C14.4142 18.5 14.75 18.1642 14.75 17.75V13.25Z"
                  fill="#7190FF" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21ZM14 19.5C17.0376 19.5 19.5 17.0376 19.5 14C19.5 10.9624 17.0376 8.5 14 8.5C10.9624 8.5 8.5 10.9624 8.5 14C8.5 17.0376 10.9624 19.5 14 19.5Z"
                  fill="#7190FF" />
              </svg>

              <p class="text-12 text-text-dark-500 font-normal">You should use a maximum of 3 fonts.</p>
            </div>
            <template v-for="item in list" :key="item.index">
              <div v-if="item.data?.isLabel" class="flex justify-between">
                <p class="text-12 text-text-dark-300 mt-16 font-normal leading-5">
                  {{ item.data?.label }}
                </p>
                <div
                  v-if="['Google font', 'Bunny font'].includes(item.data?.label)"
                  class="flex cursor-pointer items-end"
                  @click="$emit('handleSwitchFont')">
                  <g-base-icon
                    color="#8AA4FF"
                    name="external-minor-2"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"></g-base-icon>
                  <p class="text-12 text-primary-200 mt-16 font-normal leading-5">Switch font source</p>
                </div>
              </div>
              <template v-else>
                <div
                  v-if="item.data.isRecently"
                  data-test="editor-control-font-option"
                  class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent transition-all before:absolute"
                  @click="onChange(item.data.value, item.data.type)">
                  <FontFamilyItem
                    :is-checked="isCheckedFontItem({ value: item.data?.value, type: item.data.type })"
                    :font-family="item.data.value"
                    :var-font-family="getVariantStyleFontFamily(item.data?.value, item.data.type)"
                    :label="resolveFontValue(item.data?.value)" />
                </div>
                <div
                  v-else-if="item.data.type === 'custom'"
                  data-test="editor-control-font-option"
                  class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent transition-all before:absolute"
                  @click="onChange(item.data.value, 'custom')">
                  <FontFamilyItem
                    :is-checked="isCheckedFontItem({ value: item.data.value, type: 'custom' })"
                    :font-family="item.data.value"
                    :label="item.data.value"
                    :has-delete="!item.data?.isLoading"
                    :has-disabled-delete="
                      item.data.value === globalStyleFont?.heading.family ||
                      item.data.value === globalStyleFont?.body.family ||
                      item.data.value === value
                    "
                    :is-loading="item.data?.isLoading"
                    @open-modal-delete="
                      (e) => {
                        onOpenModal(e);
                        dataFontCurrent = item.data;
                      }
                    " />
                </div>
                <div
                  v-else-if="item.data.type === 'theme'"
                  data-test="editor-control-font-option"
                  class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent transition-all before:absolute"
                  @click="onChange(item.data.value, 'theme')">
                  <FontFamilyItem
                    :is-checked="isCheckedFontItem({ value: item.data.value, type: 'theme' })"
                    :font-family="item.data.value"
                    :var-font-family="getVariantStyleFontFamily(item.data?.value, item.data.type)"
                    :label="resolveFontValue(item.data?.value)" />
                </div>
                <div
                  v-else-if="item.data.type === 'google' || item.data.type === 'bunny'"
                  data-test="editor-control-font-option"
                  class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent transition-all before:absolute"
                  @click="onChange(item.data.value, item.data.type ?? 'google')">
                  <FontFamilyItem
                    :is-checked="isCheckedFontItem({ value: item.data.value, type: 'google' })"
                    :font-family="item.data.value"
                    :label="item.data.label" />
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div class="border-dark-300 border-t py-12 px-16">
        <GButtonV2
          type="tertiary"
          size="medium"
          class="text-12 relative w-full cursor-pointer items-center justify-center">
          <input
            id="fontFileInput"
            class="z-5 absolute left-[-84px] m-0 h-full w-[500px] cursor-pointer opacity-0 outline-none"
            type="file"
            multiple="true"
            accept=".ttf, .otf, .woff, .woff2"
            @change="onUploadFont" />
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.5 0.5C5.5 0.223858 5.27614 0 5 0C4.72386 0 4.5 0.223858 4.5 0.5V4.5H0.5C0.223858 4.5 0 4.72386 0 5C0 5.27614 0.223858 5.5 0.5 5.5H4.5V9.5C4.5 9.77614 4.72386 10 5 10C5.27614 10 5.5 9.77614 5.5 9.5V5.5H9.5C9.77614 5.5 10 5.27614 10 5C10 4.72386 9.77614 4.5 9.5 4.5H5.5V0.5Z"
              fill="#F9F9F9" />
          </svg>
          <div class="ml-8">
            <p class="text-12 text-text-dark-500 font-medium">Upload font</p>
          </div>
        </GButtonV2>
      </div>
    </div>
  </Teleport>
  <Teleport to="body">
    <g-modal
      :show-btn-close="true"
      :is-open="isShowModal"
      btn-ok-type="danger"
      label-ok="Delete"
      @close="onCloseModal"
      @cancel="onCloseModal"
      @ok="$emit('handleDeleteFont', onCloseModal, dataFontCurrent)">
      <template #title>Delete font</template>
      <template #default>
        <div class="p-16">
          <p class="text-14 text-text-light-500 w-[568px] font-normal">
            Are you sure you want to delete this uploaded font?
          </p>
          <ul class="list-disc pl-[20px]">
            <li class="text-14 text-text-light-500 w-[568px] font-normal">
              All heading using this font will be replaced by
              <p class="inline font-semibold">{{ globalStyleFont?.heading.family }}</p>
              - global heading font;
            </li>
            <li class="text-14 text-text-light-500 w-[568px] font-normal">
              All paragraph using this font will be replaced by
              <p class="inline font-semibold">{{ globalStyleFont?.body.family }}</p>
              - global paragraph font.
            </li>
          </ul>
        </div>
      </template>
    </g-modal>
  </Teleport>
</template>
