import type { Ref } from 'vue';
import { useResolveFontValue } from './useResolveFontValue';
import type { globalStyleFontProps } from '../types';
import type { TypographyFamily, TypographyV2Props } from '../../../types';

export function useIsCheckedFontItem(
  themeFonts: Ref<globalStyleFontProps | undefined>,
  currentFont: Ref<string | TypographyV2Props['fontFamily']>,
) {
  const resolveFontValue = useResolveFontValue(themeFonts);

  return ({ value, type }: TypographyFamily): boolean => {
    const dataFont = currentFont.value;

    if (typeof dataFont === 'string') {
      return resolveFontValue(value) === resolveFontValue(dataFont);
    }

    const resolvedValueA = resolveFontValue(value);
    const resolvedValueB = resolveFontValue(dataFont?.value || '');

    return resolvedValueA === resolvedValueB && type === dataFont?.type;
  };
}
