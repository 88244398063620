import type { ImageShapeOption, ImageShapeValue } from './type';
import type { ComputedRef, Ref } from 'vue';
const useUpdateDataImageShape = (propValue: ComputedRef<ImageShapeValue | undefined>) => {
  const getValueWhenUpdateSetting = (
    settingID: string,
    value?: string,
    previousShapeValue?: Ref<ImageShapeOption | undefined>,
  ) => {
    switch (settingID) {
      case 'height':
        return getValueWhenUpdateHeight(value, previousShapeValue);
      case 'shape':
        return getValueWhenUpdateShape(value as ImageShapeOption);
      default:
        return getValueWhenUpdateWidth(value);
    }
  };

  const getValueWhenUpdateWidth = (value?: string) => {
    return {
      ...propValue.value,
      width: value,
    };
  };

  const getValueWhenUpdateHeight = (value?: string, previousShapeValue?: Ref<ImageShapeOption | undefined>) => {
    const newData = {
      ...propValue.value,
      height: value,
    };
    const isCustom = value && value.toLowerCase() !== 'auto';
    if (isCustom) {
      newData.shape = 'custom';
    }
    if (!isCustom && newData.shape === 'custom') {
      newData.shape = previousShapeValue?.value || 'square';
    }
    return newData;
  };

  const getValueWhenUpdateShape = (value?: ImageShapeOption) => {
    return value === 'custom'
      ? { ...propValue.value, shape: value }
      : {
          ...propValue.value,
          shape: value,
          height: undefined,
        };
  };

  return {
    getValueWhenUpdateSetting,
  };
};

export default useUpdateDataImageShape;
