<template>
  <svg width="59" height="33" viewBox="0 0 59 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9363_22898)">
      <rect x="0.5" y="0.5" width="58" height="32" rx="1.8125" fill="#424242" class="background" />
      <circle cx="4.5" cy="4.5" r="8" fill="#5E5E5E" class="sub" />
      <path
        d="M10.4711 47.7263L21.3878 34.7973C21.7574 34.3588 22.2141 34.004 22.7283 33.7562C23.2424 33.5084 23.8024 33.3731 24.3716 33.3591C24.9409 33.3451 25.5067 33.4527 26.0321 33.675C26.5575 33.8973 27.0308 34.2292 27.4209 34.649L32.5255 40.1357L52.4794 15.9732C52.8701 15.4997 53.3611 15.1215 53.916 14.8667C54.471 14.6118 55.0755 14.4868 55.6847 14.5011C56.2939 14.5153 56.8921 14.6684 57.4348 14.949C57.9775 15.2295 58.4507 15.6303 58.8194 16.1215L82.6757 47.8858C83.1318 48.4947 83.411 49.2204 83.482 49.9812C83.553 50.7421 83.4129 51.5079 83.0775 52.1925C82.7421 52.8771 82.2247 53.4534 81.5834 53.8566C80.9422 54.2598 80.2025 54.4739 79.4476 54.4748L13.5554 54.5C12.7804 54.4997 12.0217 54.2746 11.369 53.8514C10.7163 53.4283 10.1971 52.8247 9.87262 52.1121C9.54817 51.3996 9.43211 50.6078 9.53818 49.8306C9.64424 49.0534 9.968 48.3231 10.4711 47.7263Z"
        fill="#5E5E5E"
        class="sub" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.3125 11.5C22.3115 11.5 21.5 12.3115 21.5 13.3125V13.6875C21.5 14.6885 22.3115 15.5 23.3125 15.5H35.6875C36.6885 15.5 37.5 14.6885 37.5 13.6875V13.3125C37.5 12.3115 36.6885 11.5 35.6875 11.5H23.3125ZM19.3125 17.5C18.3115 17.5 17.5 18.3115 17.5 19.3125V19.6875C17.5 20.6885 18.3115 21.5 19.3125 21.5H39.6875C40.6885 21.5 41.5 20.6885 41.5 19.6875V19.3125C41.5 18.3115 40.6885 17.5 39.6875 17.5H19.3125Z"
        fill="#AAAAAA"
        class="trunk" />
    </g>
    <defs>
      <clipPath id="clip0_9363_22898">
        <rect x="0.5" y="0.5" width="58" height="32" rx="1.8125" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
