<script lang="ts" setup>
import { reactive, ref } from 'vue';
import Modal from './modal/Modal.vue';
type Props = {
  id: string;
  value?: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

type Transition = {
  property: string;
  duration: string;
  easing: string;
  delay: string;
};

const [property, duration, easing, delay] = props.value?.split(' ') ?? ['all', '0s', 'ease-in-out', '0s'];

const transition = reactive<Transition>({
  property,
  duration,
  easing,
  delay,
});

const transitionModal = ref(false);

const change = () => {
  emit(
    'controlChange',
    props.id,
    `${transition.property} ${transition.duration} ${transition.easing} ${transition.delay}`,
  );
};
const changeInput = (value: string, type: 'duration' | 'delay') => {
  const number = parseInt(value.match(/\d+/g)?.join('') ?? '0');
  if (type === 'duration') {
    transition.duration = `${number}s`;
  } else {
    transition.delay = `${number}s`;
  }
  emit(
    'controlChange',
    props.id,
    `${transition.property} ${transition.duration} ${transition.easing} ${transition.delay}`,
  );
};
</script>

<template>
  <div class="sf--control">
    <div class="sf--control--transition text-left" @click="transitionModal = true">
      <p class="mx-2 px-1">Property : {{ transition.property }}</p>
    </div>
    <Modal v-if="transitionModal" :hide-actions="true" @close="transitionModal = false" @cancel.stop="">
      <div class="flex flex-col p-2 text-black">
        <div class="my-1 flex">
          <p class="mr-1 text-sm">What to animate</p>
          <select v-model="transition.property" class="ml-auto outline-none" @change="change">
            <option value="all">all</option>
            <option value="transform">transform</option>
            <option value="opacity">opacity</option>
            <option value="color">color</option>
            <option value="background">background-color</option>
            <option value="filter">background-image</option>
            <option value="box-shadow">background-position</option>
            <option value="border-radius">background-size</option>
          </select>
        </div>
        <div class="my-1 flex">
          <p class="mr-1 text-sm">Duration</p>
          <input
            v-model="transition.duration"
            class="ml-auto outline-none"
            type="text"
            @change="changeInput(transition.duration, 'duration')" />
        </div>
        <div class="my-1 flex">
          <p class="mr-1 text-sm">Delay</p>
          <input
            v-model="transition.delay"
            class="ml-auto outline-none"
            type="text"
            @change="changeInput(transition.delay, 'delay')" />
        </div>
        <div class="my-1 flex">
          <p class="mr-1 text-sm">Curve</p>
          <select v-model="transition.easing" class="ml-auto outline-none" @change="change">
            <option value="linear">Linear</option>
            <option value="ease">Ease</option>
            <option value="ease-in">Ease In</option>
            <option value="ease-in-out">Ease In & Out</option>
            <option value="ease-out">Ease Out</option>
          </select>
        </div>
      </div>
    </Modal>
  </div>
</template>
<style lang="scss" setup scoped>
@import './../utils/common.scss';
</style>
