<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import VariantsItem from './VariantsItem.vue';
import type { GlobalSwatchesData, ProductOption, SelectedOption, SwatchesOptionType } from '@gem/common';

type ProductSetting = {
  productId?: string;
  initialVariantId?: string;
  initialVariantBaseId?: string;
  productHandle?: string;
};

type Props = {
  id?: string;
  value?: ProductSetting;
  options?: ProductOption[];
  variants?: SelectedOption[];
  dataSwatches?: GlobalSwatchesData[];
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'clickEditStyle', optionType: SwatchesOptionType): void;
  (e: 'changeOptionType', dataSwatches: GlobalSwatchesData[]): void;
}>();

const swatches = computed(() => props.dataSwatches);

const variantSelected = ref<{ [key: string]: any }>(modifyVariantSelected(props.variants));

const handleOpenModalEditStyle = (optionType: SwatchesOptionType) => {
  emit('clickEditStyle', optionType);
};

const updateSwatchesConfig = (dataSwatches: GlobalSwatchesData[]) => {
  emit('changeOptionType', dataSwatches);
};

watch(
  () => props.variants,
  (newVariants) => {
    variantSelected.value = modifyVariantSelected(newVariants);
  },
);

function modifyVariantSelected(variants?: SelectedOption[]) {
  return (
    variants?.reduce((a, b) => {
      if (b.name) {
        return { ...a, [b.name]: b.value };
      }
      return a;
    }, {}) ?? {}
  );
}
</script>
<template>
  <VariantsItem
    v-for="(item, index) in options"
    :key="index"
    :data-swatches="swatches"
    :option="item"
    @open-modal-edit-style="handleOpenModalEditStyle"
    @change-option-type="updateSwatchesConfig" />
</template>
