const FONT_SIZE_MAPPINGS = [
  { maxFontValue: 14, renderValue: 12 },
  { maxFontValue: 16, renderValue: 13 },
  { maxFontValue: 20, renderValue: 15 },
  { maxFontValue: 24, renderValue: 16 },
  { maxFontValue: 35, renderValue: 17 },
  { maxFontValue: 50, renderValue: 18 },
  { maxFontValue: 55, renderValue: 20 },
  { maxFontValue: 70, renderValue: 22 },
];

const DEFAULT_RENDER_VALUE = 25;

export const usePreviewTypoStyleConverter = () => {
  const parseFont = (fontValue: string | number) => {
    if (typeof fontValue === 'string') {
      return parseFloat(fontValue);
    }
    return fontValue;
  };

  const getRenderValue = (actualFontValue: string | number) => {
    const numericFontValue = parseFont(actualFontValue);

    for (const mapping of FONT_SIZE_MAPPINGS) {
      if (numericFontValue <= mapping.maxFontValue) {
        return `${mapping.renderValue}px`;
      }
    }
    return `${DEFAULT_RENDER_VALUE}px`; // for values above 70
  };

  return { getRenderValue };
};
