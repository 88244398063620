<script lang="ts" setup>
const emit = defineEmits<{
  (e: 'showEdit', index: string): void;
  (e: 'change', value: string, index: string): void;
}>();

const props = defineProps<{
  id?: string;
  index?: string;
  icon?: string;
  sectionId: string;
  componentUid: string;
  wrapIconComponent: Object;
}>();

const handleChange = (_?: number | string, value?: string) => {
  if (props.index) emit('change', value ?? '', props.index);
};
</script>

<template>
  <component
    :is="wrapIconComponent"
    :id="props.id"
    :value="icon"
    :hide-input="true"
    :custom-label="`Icon ${index ? parseInt(index) + 1 : 1}`"
    :section-id="sectionId"
    :component-uid="componentUid"
    control-label="ChildIcon"
    @control-change="handleChange" />
</template>
