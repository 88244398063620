<script lang="ts" setup>
// import BorderColorSetting from './BorderColorSetting.vue';
import { ref, computed, watch } from 'vue';
import type { ColorProps } from '../global-color/types';
import type { BorderType } from './types';

const emit = defineEmits<{
  (e: 'controlChange', value: BorderType): void;
  (e: 'controlOnChange', value: BorderType): void;
}>();

type Props = {
  id: string;
  value?: BorderType;
  globalColors?: ColorProps[];
  colorMapping?: Record<string, string>;
};

const props = defineProps<Props>();
const keyActive = ref<string>('');
const val = ref<BorderType>(props.value ?? {});
const positionWidths = computed(() => {
  return val.value.width?.split(' ');
});
const positionOptions = {
  top: {
    key: 'top',
    icon: 'border-position-top',
    iconPosition: 'first',
  },
  left: {
    key: 'left',
    icon: 'border-position-left',
    iconPosition: 'first',
  },
  right: {
    key: 'right',
    icon: 'border-position-right',
    iconPosition: 'last',
  },
  bottom: {
    key: 'bottom',
    icon: 'border-position-bottom',
    iconPosition: 'first',
  },
};

const change = (data: BorderType, type: 'controlOnChange' | 'controlChange') => {
  if (type === 'controlChange') emit('controlChange', data);
  else emit('controlOnChange', data);
};

const mapKeyToPosition = (position: string) => {
  switch (position) {
    case 'top':
      return 0;
    case 'right':
      return 1;
    case 'bottom':
      return 2;
    case 'left':
      return 3;
    default:
      return -1;
  }
};

const convertValueToNumber = (value: string | undefined) => {
  if (!value) return 0;
  const num = parseInt(value);
  return isNaN(num) || num < 0 ? 0 : num;
};

const onChangeInput = (value?: string) => {
  if (!keyActive.value) return;
  const currentWidths = positionWidths.value;
  currentWidths?.splice(mapKeyToPosition(keyActive.value), 1, value + 'px');
  val.value = {
    ...props.value,
    width: currentWidths?.join(' '),
  };
  change(val.value, 'controlOnChange');
};

const onBlur = (value?: string) => {
  if (!keyActive.value) return;
  const currentWidths = positionWidths.value;
  currentWidths?.splice(mapKeyToPosition(keyActive.value), 1, convertValueToNumber(value) + 'px');
  val.value = {
    ...props.value,
    width: currentWidths?.join(' '),
  };
  keyActive.value = '';
  change(val.value, 'controlChange');
};
const getPositionWidth = (position: string) => {
  return positionWidths?.value?.[mapKeyToPosition(position)]?.replace('px', '');
};

const onFocus = (key: string) => {
  keyActive.value = key;
  return;
};
watch(
  () => props.value,
  (newVal) => {
    if (newVal) {
      val.value = newVal;
    }
  },
);
</script>

<template>
  <g-popover
    :closeable="true"
    class="aspect-square h-full"
    :overlay="false"
    no-shadow-box
    arrow-class="!text-dark-400 translate-x-[-42px]"
    overlay-container="#root-modal"
    placement="bottom-end"
    wrapper-class="translate-x-[42px] "
    cls="!p-0 ">
    <template #default="{ open }">
      <GButtonV2
        :active="open"
        type="ghost"
        size="medium"
        only-icon="more-setting-20"
        icon-view-box="0 0 20 20"
        class="h-full"></GButtonV2>
    </template>
    <template #content="{ close }">
      <div class="bg-dark-400 rounded-12 border-more-setting">
        <div class="text-light-450 text-14 flex h-[52px] items-center justify-between gap-8 pl-16 font-semibold">
          <span class="">Advanced border</span>
          <div class="relative flex h-full w-[52px] items-center justify-center" @click="close">
            <GButtonV2
              type="ghost"
              size="medium"
              only-icon="close-more-setting"
              icon-view-box="0 0 16 16"
              @click="close">
            </GButtonV2>
          </div>
        </div>
        <div class="rounded-12 w-screen max-w-[264px] p-16 pt-0">
          <!-- Border Position -->
          <div class="flex flex-col items-start">
            <div class="flex h-36 items-center">
              <span class="text-12 text-text-dark-300 block">Position</span>
            </div>
            <div class="flex w-full flex-col pt-8">
              <div class="flex w-full select-none justify-center">
                <g-input
                  :key="positionOptions.top.key"
                  :value="getPositionWidth(positionOptions.top.key)"
                  type="number"
                  input-style="secondary"
                  :icon-position="positionOptions.top.iconPosition"
                  classes="w-[80px] text-12 h-36 gemx-hidden-arrow text-center bg-dark-300 border-dark-300"
                  container-classes="w-fit"
                  size="large"
                  @keydown.enter="(e: Event) => (e.target as HTMLElement).blur()"
                  @on-change="onChangeInput"
                  @focus="() => onFocus(positionOptions.top.key)"
                  @handle-blur="onBlur">
                  <template #icon>
                    <g-base-icon :name="(positionOptions.top.icon as any)" width="16" height="16" viewBox="0 0 16 16" />
                  </template>
                </g-input>
              </div>
              <div class="flex w-full select-none justify-between py-4">
                <g-input
                  :key="positionOptions.left.key"
                  :value="getPositionWidth(positionOptions.left.key)"
                  type="number"
                  input-style="secondary"
                  :icon-position="positionOptions.left.iconPosition"
                  classes="w-[80px] text-12 h-36 gemx-hidden-arrow text-center bg-dark-300 border-dark-300"
                  container-classes="w-fit"
                  size="large"
                  @keydown.enter="(e: Event) => (e.target as HTMLElement).blur()"
                  @on-change="onChangeInput"
                  @focus="() => onFocus(positionOptions.left.key)"
                  @handle-blur="onBlur">
                  <template #icon>
                    <g-base-icon
                      :name="(positionOptions.left.icon as any)"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16" />
                  </template>
                </g-input>
                <g-input
                  :key="positionOptions.right.key"
                  :value="getPositionWidth(positionOptions.right.key)"
                  type="number"
                  input-style="secondary"
                  :icon-position="positionOptions.right.iconPosition"
                  classes="w-[80px] text-12 h-36 gemx-hidden-arrow text-center bg-dark-300 border-dark-300"
                  container-classes="w-fit"
                  size="large"
                  @keydown.enter="(e: Event) => (e.target as HTMLElement).blur()"
                  @on-change="onChangeInput"
                  @focus="() => onFocus(positionOptions.right.key)"
                  @handle-blur="onBlur">
                  <template #icon>
                    <g-base-icon
                      :name="(positionOptions.right.icon as any)"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16" />
                  </template>
                </g-input>
              </div>
              <div class="flex w-full select-none justify-center">
                <g-input
                  :key="positionOptions.bottom.key"
                  :value="getPositionWidth(positionOptions.bottom.key)"
                  type="number"
                  input-style="secondary"
                  :icon-position="positionOptions.bottom.iconPosition"
                  classes="w-[80px] text-12 h-36 gemx-hidden-arrow text-center bg-dark-300 border-dark-300"
                  container-classes="w-fit"
                  size="large"
                  @keydown.enter="(e: Event) => (e.target as HTMLElement).blur()"
                  @on-change="onChangeInput"
                  @focus="() => onFocus(positionOptions.bottom.key)"
                  @handle-blur="onBlur">
                  <template #icon>
                    <g-base-icon
                      :name="(positionOptions.bottom.icon as any)"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16" />
                  </template>
                </g-input>
              </div>
            </div>
          </div>
          <!-- Border Color -->
          <!-- <BorderColorSetting
            :value="value"
            :global-colors="globalColors"
            :color-mapping="colorMapping"
            @control-change="change" /> -->
        </div>
      </div>
    </template>
  </g-popover>
</template>
