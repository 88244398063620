<template>
  <div>
    <slot name="label"></slot>
    <div class="w-full">
      <InputColor
        theme="dark"
        :value="value"
        :show-transparent-color="false"
        :open-color-picker="true"
        @change-color="onChangeColor" />
      <ColorPicker :value="value" :is-auto-update-value="isAutoUpdateValue" @change-color="onChangeColor" />
    </div>

    <slot name="info"></slot>
  </div>
</template>

<script lang="ts" setup>
import ColorPicker from './color-picker/components/ColorPicker.vue';
import InputColor from './color-picker/components/InputColor.vue';
import type { WhereInput } from './color-picker/types';

type Props = {
  id: string;
  value?: string;
  popup?: boolean;
  isAutoUpdateValue?: boolean;
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any, whereInput?: WhereInput): void;
}>();

const onChangeColor = (color: string, whereInput?: WhereInput) => {
  emit('controlChange', props.id, color, whereInput);
};
</script>
