<script lang="ts" setup>
import { ref } from 'vue';
import InputBadge from './InputBadge.vue';

type InputRangeKey = 'from' | 'to';
const inputClasses =
  '!text-center !text-text-dark-500 group-hover:bg-dark-200 group-hover:border-dark-200 h-36 !transition-none';

const props = withDefaults(
  defineProps<{
    from: string;
    to?: string;
    min?: number;
    isInteger?: boolean;
  }>(),
  {
    min: 0,
    isInteger: true,
  },
);

const emit = defineEmits<{
  (event: 'onUpdateValue', key: InputRangeKey, value: string): void;
}>();

const focusBetweenInput = ref(false);
</script>

<template>
  <div class="bg-dark-400 group flex h-36 flex-1 items-center justify-between rounded-xl transition-none">
    <InputBadge
      id="from"
      :value="from"
      :min="props.min"
      :max="Number(to)"
      :is-integer="isInteger"
      :class="`${inputClasses} rounded-r-none focus:!border-r-transparent ${
        focusBetweenInput ? '!border-primary-300 !border-r-0' : ''
      }`"
      @on-focus="focusBetweenInput = true"
      @on-blur="focusBetweenInput = false"
      @on-change="(id: string, value: string) => 
        emit('onUpdateValue', 'from', value)
      " />
    <p
      class="text-12 text-text-dark-100 group-hover:bg-dark-200 group-hover:border-dark-200 border-dark-400 h-36 border-x-0 border-y p-[2px] text-center leading-9 transition-none"
      :class="{
        '!border-primary-300 !border-x-transparent': focusBetweenInput,
      }">
      to
    </p>
    <InputBadge
      id="to"
      :value="to"
      :is-integer="isInteger"
      :min="Number(from) + 1"
      :class="`${inputClasses} rounded-l-none focus:!border-l-transparent ${
        focusBetweenInput ? '!border-primary-300 !border-l-0' : ''
      }`"
      @on-blur="focusBetweenInput = false"
      @on-focus="focusBetweenInput = true"
      @on-change="(id: string, value: string) => emit('onUpdateValue', 'to', value)" />
  </div>
</template>
