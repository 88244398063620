<script lang="ts" setup>
import { ref, computed, watch } from 'vue';

const props = defineProps<{
  id: string;
  value?: number | string;
  placeholder?: string;
  readonly?: boolean;
  componentUid?: string;
  suffix?: string;
  datalist?: Array<{ value: string }>;
  controlChange?: (id: string, value?: any) => void;
  emptyOnClear?: boolean;
  action?: {
    clear?: boolean;
  };
  actionChangeControls?: {
    controlID: string;
    condition?: string;
    newValue: any;
  }[];
}>();

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string, value?: any): void;
  (e: 'controlChange', controlId?: string, value?: any): void;
}>();

const realValue = ref(props.value);
const refInput = ref<HTMLInputElement>();

const latestValue = computed(() => props.value);
const onChange = (value: string) => {
  realValue.value = value;
  emit('controlOnChange', props.id, value);
};

const onBlur = (value: string) => {
  if (realValue.value === props.value) {
    return;
  }
  if (!value && props.emptyOnClear) {
    realValue.value = latestValue.value;
  }
  emit('controlChange', props.id, realValue.value);
  if (props.actionChangeControls && props.actionChangeControls.length > 0) {
    props.actionChangeControls.forEach((element) => {
      const conditionChange = element.condition?.replace(props.id, `'${realValue.value}'`);
      if (conditionChange && eval(conditionChange)) {
        emit('controlChange', element.controlID, element.newValue);
      }
    });
  }
};

watch(
  computed(() => props.value),
  () => {
    realValue.value = props.value;
  },
);

const onClear = () => {
  realValue.value = '';
  refInput.value?.focus();
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div>
      <g-input
        ref="refInput"
        :value="realValue"
        :class="$attrs.class"
        type="text"
        input-style="secondary"
        :list="id"
        :placeholder="placeholder"
        :disabled="readonly"
        :suffix="suffix"
        :action="action"
        icon-position="last"
        @handle-blur="onBlur"
        @on-change="onChange"
        @change="onChange">
        <template v-if="action?.clear" #icon>
          <button v-if="realValue" class="hover:bg-dark-250 relative right-4 rounded-xl p-6" @click="onClear">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L7.29289 8L5.14645 10.1464C4.95118 10.3417 4.95118 10.6583 5.14645 10.8536C5.34171 11.0488 5.65829 11.0488 5.85355 10.8536L8 8.70711L10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464L8.70711 8L10.8536 5.85355C11.0488 5.65829 11.0488 5.34171 10.8536 5.14645C10.6583 4.95118 10.3417 4.95118 10.1464 5.14645L8 7.29289L5.85355 5.14645Z"
                fill="#E2E2E2" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
                fill="#E2E2E2" />
            </svg>
          </button>
        </template>
      </g-input>
      <datalist :id="id">
        <option v-for="data in datalist" :key="data.value" :value="data.value" />
      </datalist>
    </div>
    <slot name="info"></slot>
  </div>
</template>
