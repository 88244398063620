<template>
  <label class="container-checkbox">
    <div
      class="group-hover:bg-overlay-grey group-hover/white:bg-overlay-grey group-hover/dark:bg-overlay-white pointer-events-none absolute left-[-4px] top-[-4px] z-0 h-[28px] w-[28px] rounded-xl"></div>
    <input v-model="val" :class="inputClasses" :value="value" type="checkbox" />
    <span class="checkmark"></span>
  </label>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

const props = defineProps<{
  value?: boolean;
  text?: string;
  desc?: string;
  classContainer?: string;
  inputClasses?: string;
}>();

const val = ref(props.value || false);

watch(
  () => props.value,
  (value) => {
    val.value = value;
  },
);
</script>

<style lang="css">
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #676767;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #3c67ff;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 6.4px;
  top: 1.6px;
  width: 7.2px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(40deg);
}
</style>
