<script lang="ts" setup>
import { computed } from 'vue';
import type { SpacingOption } from './types';
import Value from './Value.vue';

type Prop = {
  id?: string;
  value?: string;
  diffValue?: string | number;
  item: {
    class: string;
    pos: string;
    property: string;
  };
  isFocus?: boolean;
  isOpenSelectSpacing?: boolean;
  options: SpacingOption[];
  onSelectSpacing?: () => void;
  onControlChange?: () => void;
  onClickSubAction?: () => void;
  controlChangeProp?: () => void;
  type?: 'normal' | 'small';
};

const props = defineProps<Prop>();
const emits = defineEmits(['inside-mouseleave', 'inside-mouseover']);

const gridArea = computed(() => {
  const posArr = props.item.pos.split(' ');

  if (posArr[0] === 'top') {
    return '1 / 2 / 2 / 3';
  } else if (posArr[0] === 'right') {
    return '2 / 3 / 3 / 4';
  } else if (posArr[0] === 'bottom') {
    return '3 / 2 / 4 / 3';
  } else {
    return '2 / 1 / 3 / 2';
  }
});

function handleMouseOver() {
  emits('inside-mouseover', props.item?.property);
}

function handleMouseLeave() {
  emits('inside-mouseleave', props.item?.property);
}
</script>

<template>
  <div
    class="flex items-center justify-center place-self-center"
    :style="{ 'grid-area': gridArea }"
    @mouseleave.stop="handleMouseLeave"
    @mouseover.stop="handleMouseOver">
    <Value
      label="padding"
      :property="item.property"
      :options="options"
      :value="value"
      :is-focus="isFocus"
      :min="0"
      :type="type"
      :diff-value="diffValue"
      :control-change="onControlChange"
      @select-spacing="onSelectSpacing"
      @control-on-change="controlChangeProp"
      @on-click-sub-action="onClickSubAction" />
  </div>
</template>
