<script setup lang="ts">
import { computed } from 'vue';
import { getBackgroundStyle, getUniqueColors } from '../helpers';

const props = defineProps<{ color?: string; colors: string[]; title: string; systemColor?: string[] }>();
const emits = defineEmits(['onPickColor']);
const onSelected = (color: string) => {
  emits('onPickColor', color);
};
const isActive = (myColor: string) => {
  return myColor === props.color?.toLowerCase();
};

const allColors = computed(() => {
  const colorList = [...props.colors, ...(props.systemColor ?? [])];
  return getUniqueColors(colorList);
});
</script>

<template>
  <div>
    <div class="text-light-450 text-12 mb-4 leading-5">{{ title }}</div>
    <div data-test="editor-control-suggested-colors" class="grid grid-cols-8">
      <div v-for="myColor in allColors" :key="myColor">
        <div
          data-test="editor-control-suggested-color-option"
          class="group flex h-36 w-36 cursor-pointer items-center justify-center"
          @click="onSelected(myColor)">
          <div
            class="group-hover:border-light-450 flex h-32 w-32 items-center justify-center rounded-full border border-transparent"
            :class="[
              { '!border-primary-300 ': isActive(myColor) },
              { 'group-hover:border-light-450': !isActive(myColor) },
            ]">
            <div class="relative h-24 w-24 rounded-full">
              <div
                class="gemx-transparent-bg absolute top-1/2 left-1/2 h-[20px] w-[20px] -translate-x-1/2 -translate-y-1/2 transform rounded-full border border-transparent" />
              <div
                :style="{
                  ...getBackgroundStyle(myColor),
                }"
                class="absolute top-1/2 left-1/2 h-[20px] w-[20px] -translate-x-1/2 -translate-y-1/2 transform rounded-full border" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.gemx-transparent-bg {
  background-image: url('/images/color-transparent-preview.png');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
