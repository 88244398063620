<script lang="ts" setup>
const emits = defineEmits<{ (event: 'openModalDelete', e: Event): void }>();
const props = withDefaults(
  defineProps<{
    isChecked?: boolean;
    isLoading?: boolean;
    hasDelete?: boolean;
    fontFamily: string;
    varFontFamily?: string;
    label: string;
    hasDisabledDelete?: boolean;
  }>(),
  {
    isChecked: false,
    isLoading: false,
    hasDelete: false,
    fontFamily: '',
    varFontFamily: '',
    label: '',
    hasDisabledDelete: false,
  },
);
const getStyleFontFamily = (font: string) => {
  if (props.varFontFamily) {
    return `var(${props.varFontFamily})`;
  }

  if (['sans-serif'].includes(font)) {
    return font;
  }
  if (font.includes('--g-theme-font')) {
    return `var(${font})`;
  }

  return `'${font}'`;
};
</script>

<template>
  <div class="flex aspect-square h-full items-center justify-center">
    <svg
      width="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="{
        hidden: !isChecked,
      }">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7471 5.30122C16.9912 5.5453 16.9912 5.94103 16.7471 6.18511L7.63139 15.3008C7.51418 15.418 7.35521 15.4839 7.18945 15.4839C7.02368 15.4839 6.86471 15.418 6.7475 15.3008L3.25141 11.8046C3.00734 11.5605 3.00734 11.1648 3.25143 10.9207C3.49551 10.6766 3.89124 10.6766 4.13531 10.9207L7.18946 13.975L15.8632 5.30122C16.1073 5.05714 16.503 5.05714 16.7471 5.30122Z"
        fill="#00C853" />
    </svg>
    <GButtonV2 v-if="isLoading" type="ghost" size="medium" loading />
  </div>
  <div class="flex w-full items-center justify-between pr-[2px]">
    <span
      class="max-w-[216px] overflow-hidden text-ellipsis whitespace-nowrap"
      :style="{ 'font-family': `${getStyleFontFamily(fontFamily)}`, 'font-weight': '400' }">
      {{ label }}
    </span>
    <GButtonV2
      v-if="hasDelete"
      type="dangerGhost"
      size="medium"
      :disable="hasDisabledDelete"
      class="hidden h-32 w-32 p-8 group-hover:flex"
      :class="{
        'bg-dark-300': isChecked,
      }"
      @click="(e: Event) => emits('openModalDelete', e)">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.5 6C6.77614 6 7 6.22386 7 6.5V11.5C7 11.7761 6.77614 12 6.5 12C6.22386 12 6 11.7761 6 11.5V6.5C6 6.22386 6.22386 6 6.5 6Z"
          :fill="hasDisabledDelete ? '#757575' : '#F88E86'" />
        <path
          d="M9.5 6C9.77614 6 10 6.22386 10 6.5V11.5C10 11.7761 9.77614 12 9.5 12C9.22386  12 9 11.7761 9 11.5V6.5C9 6.22386 9.22386 6 9.5 6Z"
          :fill="hasDisabledDelete ? '#757575' : '#F88E86'" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 2.5V3H14C14.2761 3 14.5 3.22386 14.5 3.5C14.5 3.77614 14.2761 4 14 4H13V12.5C13 13.8807 11.8807 15 10.5 15H5.5C4.11929 15 3 13.8807 3 12.5V4H2C1.72386 4 1.5 3.77614 1.5 3.5C1.5 3.22386 1.72386 3 2 3H5V2.5C5 1.67157 5.67157 1 6.5 1H9.5C10.3284 1 11 1.67157 11 2.5ZM4 4V12.5C4 13.3284 4.67157 14 5.5 14H10.5C11.3284 14 12 13.3284 12 12.5V4H4ZM6 3V2.5C6 2.22386 6.22386 2 6.5 2H9.5C9.77614 2 10 2.22386 10 2.5V3H6Z"
          :fill="hasDisabledDelete ? '#757575' : '#F88E86'" />
      </svg>
    </GButtonV2>
  </div>
</template>
