<template>
  <div class="gemx-control flex w-full flex-col gap-16">
    <div class="flex w-full items-center justify-between">
      <p class="!text-14 whitespace-nowrap font-medium leading-[36px] text-white">{{ label }}</p>
      <GToggler id="enableParallax" :value="isParallax" @change="handleControlChange"></GToggler>
    </div>
    <div v-if="isParallax" class="flex justify-between text-white">
      <RangeSlide
        id="speed"
        class="flex items-center justify-between"
        :max="1"
        :step="0.1"
        ignore-unit
        :hide-unit="true"
        :value="speed"
        use-only-unit-init
        @controlOnChange="handleControlChange"
        @controlChange="handleControlChange">
        <template #label> <p class="text-12 text-text-dark-300">Speed</p> </template>
      </RangeSlide>
    </div>
  </div>
</template>
<script setup lang="ts">
import RangeSlide from '../RangeSlide.vue';
import { computed } from 'vue';

type ParallaxType = { enableParallax: boolean; speed?: number };
type Props = {
  id: string;
  value: ParallaxType;
  componentUid: string;
  sectionId: string;
  controlType: string;
  hasDevices?: boolean;
  label?: string;
};
const emit = defineEmits<{
  (event: 'control-change', value: ParallaxType): void;
}>();

const props = defineProps<Props>();
const handleControlChange = (key: keyof ParallaxType, value: boolean | number) => {
  if (key === 'speed' && typeof value === 'number') {
    if (value > 1) value = 1;
    if (value < 0) value = 0;
  }
  emit('control-change', { ...props.value, [key]: value });
  return true;
};

const isParallax = computed(() => props.value.enableParallax);
const speed = computed(() => props.value.speed);
</script>
