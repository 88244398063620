<script setup lang="ts">
import type { Product } from '@gem/common';
import { computed } from 'vue';
import type { PreVariantSelected } from '../modal/MultiProductModal.vue';

const props = defineProps<{
  item: Product;
  variantSelected?: PreVariantSelected;
  list?: any;
  hovered?: boolean;
}>();

const emits = defineEmits<{
  (e: 'openModal'): void;
}>();

const totalVariant = computed(() => {
  const item = props.list.find((el) => el.id === props.item.id);
  return item?.variants?.totalCount;
});

const label = computed(() => {
  const variant = props.variantSelected?.[props.item.id];
  const itemDetail = props.list?.find((el) => el.id === props.item.id);
  if (itemDetail?.variants?.totalCount <= 1) return '';
  if (!variant) return 'All variants';
  const variantCurr = itemDetail?.variants?.edges?.find((el) => el?.node?.id === variant?.variantId);
  if (variantCurr) return variantCurr?.node?.title;
  return Object.values(variant?.optionSelected).join(' - ');
});
</script>

<template>
  <div class="text-12 flex flex-col">
    <p
      class="text-light-100 font-regular"
      :class="{
        'line-clamp-2': !label && !hovered,
        'line-clamp-1': label || hovered,
      }">
      {{ (item as any)?.title }}
    </p>
    <p v-if="hovered && totalVariant > 1" class="text-12 text-primary-200 cursor-pointer" @click="emits('openModal')">
      Select variant
    </p>
    <p v-else class="text-text-dark-300 line-clamp-1">{{ label }}</p>
  </div>
</template>
