<script lang="ts" setup>
import { computed } from 'vue';
import type { OptionSelect } from '@gem/uikit';
import { usePreviewTypoStyleConverter } from '../composables/usePreviewTypoStyleConverter';

interface Props {
  activeId?: string | number;
  isHoverSelect: boolean;
  filteredOptions: OptionSelect[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'select', option: OptionSelect): void;
}>();

const { getRenderValue } = usePreviewTypoStyleConverter();

const isActiveOption = (item: OptionSelect) => {
  return String(props.activeId) === String(item.id);
};

const optionClasses = computed(() => (item: OptionSelect) => [
  'hover:bg-light-100/20 text-light-450 text-12 relative cursor-pointer select-none rounded-xl p-[8px] leading-[20px] pl-40 pr-8',
  { 'cursor-default': isActiveOption(item) },
]);

const onSelect = (item: OptionSelect) => {
  emit('select', item);
};
</script>

<template>
  <GLazyList :data="filteredOptions" :items-per-render="15" :is-hover-select="isHoverSelect" :on-select="onSelect">
    <template #default="{ item }">
      <div :class="optionClasses(item)" :data-value="item.id" data-test="select-option" @click="onSelect(item)">
        <span v-if="isActiveOption(item)" class="absolute inset-y-0 left-0 flex items-center pl-8 pr-12">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.7471 5.30122C16.9912 5.5453 16.9912 5.94103 16.7471 6.18511L7.63139 15.3008C7.51418 15.418 7.35521 15.4839 7.18945 15.4839C7.02368 15.4839 6.86471 15.418 6.7475 15.3008L3.25141 11.8046C3.00734 11.5605 3.00734 11.1648 3.25143 10.9207C3.49551 10.6766 3.89124 10.6766 4.13531 10.9207L7.18946 13.975L15.8632 5.30122C16.1073 5.05714 16.503 5.05714 16.7471 5.30122Z"
              fill="#00C853" />
          </svg>
        </span>
        <div class="flex flex-col gap-4">
          <div class="font-regular flex w-full justify-between">
            <span :style="{ fontSize: getRenderValue(item.value) }" v-html="item.name"> </span>
            <span v-if="item.value" class="text-text-dark-300">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </template>
  </GLazyList>
</template>
