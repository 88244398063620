<script setup lang="ts">
import { useInfiniteScroll } from '@vueuse/core';
import { ref, inject, reactive } from 'vue';
import { GButtonV2 } from '@gem/uikit';
import ConfirmDeleteIcon from './ConfirmDeleteIcon.vue';
type FileDeleteMutationVariables = {
  force?: boolean;
  id: string;
};
type Props = {
  id?: string | number;
  data: any;
  loading?: boolean;
  selectedItem: any;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'on-scroll'): void;
  (e: 'on-select-icon', value: string, id: string): void;
}>();

const iconBoundary = ref();
const openConfirmModal = ref<boolean>(false);
const deletingId = ref<FileDeleteMutationVariables['id'] | null>(null);
// const isChecked = ref();

const onCloseModal = () => {
  openConfirmModal.value = false;
};

useInfiniteScroll(
  iconBoundary,
  () => {
    emit('on-scroll');
  },
  { distance: 20 },
);
function readSVG(url: string, id?: string | undefined) {
  return fetch(url)
    .then((response) => response.text())
    .then((data) => {
      const serialize = new XMLSerializer();

      const domParserData = new DOMParser().parseFromString(data, 'image/svg+xml');
      const svgDom = domParserData.querySelector('svg');

      // add viewbox
      if (!svgDom?.getAttribute('viewBox') && svgDom?.getAttribute('width') && svgDom?.getAttribute('height')) {
        svgDom.setAttribute('viewBox', `0 0 ${svgDom.getAttribute('width')}  ${svgDom.getAttribute('height')}`);
      }
      let stringData = serialize.serializeToString(domParserData);

      if (stringData.includes('<style') && id) {
        const styleString = stringData.match(/<style(\n|.)*?<\/style>/g);
        const regex = /(\.[^}]+\{)/g;

        // add class in style tag
        if (styleString?.length) {
          const newStyle = styleString[0].replace(regex, (val) => {
            return `.id${id} ${val}`;
          });
          let newStringData = stringData.replace(styleString[0], newStyle);
          // add class to svg
          newStringData = newStringData.replace('<svg', `<svg data-id="${id}" class="id${id}"`);
          return newStringData;
        }
      }
      stringData = stringData.replace('<svg', `<svg data-id="${id}"`);
      return stringData;
    });
}
const changeUploadIcon = (path: string, id: string) => {
  readSVG(path, id).then((svg) => {
    emit('on-select-icon', svg, id);
  });
};

const deleteIcon: any = inject('iconDeleteFn');

const confirmDeleteIcon = (id: FileDeleteMutationVariables['id']) => {
  deletingId.value = id;
  openConfirmModal.value = true;
};

const handleDeleteIcon = () => {
  openConfirmModal.value = false;
  if (!deletingId.value) return;
  const variables = reactive<FileDeleteMutationVariables>({
    force: false,
    id: deletingId.value,
  });

  deleteIcon.mutate(variables);
};
</script>

<template>
  <perfect-scrollbar ref="iconBoundary" class="scroll-icon-upload -mx-16 px-16">
    <div class="grid grid-cols-5 gap-8 pb-16">
      <div
        v-if="loading"
        class="bg-dark-300 hover:bg-dark-50 relative flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[6px]">
        <div class="border-t-text-light-300 h-32 w-32 animate-spin rounded-full border-2 border-solid"></div>
      </div>
      <div
        v-for="icon in $props.data"
        :key="icon.node.id"
        class="bg-dark-300 hover:bg-bg-dark-50 group relative flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[6px]"
        :class="{
          'border-primary-300 border': props.selectedItem?.id === icon.node.id,
        }"
        @click="() => changeUploadIcon(icon?.node.filePath, icon.node.id)">
        <img class="h-32 w-32" :src="icon?.node?.filePath" alt="" />
        <div class="invisible absolute top-[5px] right-[5px] group-hover:visible">
          <GButtonV2
            type="danger"
            icon-view-box="0 0 18 18"
            size="extra-small"
            only-icon="trash"
            @click.stop="() => confirmDeleteIcon(icon.node.id)" />
        </div>
      </div>
    </div>
  </perfect-scrollbar>
  <ConfirmDeleteIcon v-if="openConfirmModal" @ok="handleDeleteIcon" @close="onCloseModal" />
</template>

<style lang="scss" scoped>
.scroll-icon-upload .ps--scrolling-y {
  margin-bottom: -16px;
}
</style>
