import { type Ref } from 'vue';
import { MAX_COLOR_SIZE } from './useMyColor';
import { GRADIENT_TAB, SOLID_TAB, generateNewGlobalStyleColor } from '../helpers';

export type ChangeInfo = {
  colorKeys: string[];
  changeIndexKey: string;
  initStartIndex: number;
  changeIndex: number;
};

export const START_CHANGE_INDEX_OF_GRADIENT_TAB = 0;
export const START_CHANGE_INDEX_OF_SOLID_TAB = 5; // Always start change from color with index 6 because the first 5 colors are theme colors

const useColorStore = (onSaveColor: (newColor: {}) => void, currentGlobalStyleColor: Ref<any | undefined>) => {
  const saveChangeToStore = (
    activeTab: string,
    newColor: string | undefined,
    recentGradientColors: string[],
    myColors: string[],
  ) => {
    if (!newColor) return;

    let newColorList = {};
    const changeIndex = getChangeIndex(activeTab);
    if (activeTab === GRADIENT_TAB) {
      const changeInfo = {
        colorKeys: ['recent-gradient-colors'],
        changeIndexKey: 'gradient-index-change',
        initStartIndex: START_CHANGE_INDEX_OF_GRADIENT_TAB,
        changeIndex,
      };
      newColorList = generateNewGlobalStyleColor(
        newColor,
        recentGradientColors.map((item) => item.toLowerCase()),
        MAX_COLOR_SIZE,
        changeInfo,
      );
    } else {
      const changeInfo = {
        colorKeys: ['my-colors', 'theme-colors'],
        changeIndexKey: 'color-index-change',
        initStartIndex: START_CHANGE_INDEX_OF_SOLID_TAB,
        changeIndex,
      };
      newColorList = generateNewGlobalStyleColor(newColor, myColors, MAX_COLOR_SIZE, changeInfo);
    }

    onSaveColor(newColorList);
  };

  const getChangeIndex = (activeTab: string) => {
    if (!currentGlobalStyleColor.value) {
      return activeTab === GRADIENT_TAB ? START_CHANGE_INDEX_OF_GRADIENT_TAB : START_CHANGE_INDEX_OF_SOLID_TAB;
    }

    if (activeTab === GRADIENT_TAB) {
      return currentGlobalStyleColor.value['gradient-index-change'] || START_CHANGE_INDEX_OF_GRADIENT_TAB;
    }

    if (activeTab === SOLID_TAB) {
      return currentGlobalStyleColor.value['color-index-change'] || START_CHANGE_INDEX_OF_SOLID_TAB;
    }
  };

  return saveChangeToStore;
};

export default useColorStore;
