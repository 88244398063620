import paddingTop from './PaddingTop.vue';
import paddingLeft from './PaddingLeft.vue';
import paddingBottom from './PaddingBottom.vue';
import paddingRight from './PaddingRight.vue';
import paddingTopSmall from './PaddingTopSmall.vue';
import paddingLeftSmall from './PaddingLeftSmall.vue';
import paddingBottomSmall from './PaddingBottomSmall.vue';
import paddingRightSmall from './PaddingRightSmall.vue';
import marginTop from './MarginTop.vue';
import marginLeft from './MarginLeft.vue';
import marginBottom from './MarginBottom.vue';
import marginRight from './MarginRight.vue';

export const icons = {
  'padding-top': paddingTop,
  'padding-left': paddingLeft,
  'padding-bottom': paddingBottom,
  'padding-right': paddingRight,
  'padding-top-small': paddingTopSmall,
  'padding-left-small': paddingLeftSmall,
  'padding-bottom-small': paddingBottomSmall,
  'padding-right-small': paddingRightSmall,
  'margin-top': marginTop,
  'margin-left': marginLeft,
  'margin-bottom': marginBottom,
  'margin-right': marginRight,
};
