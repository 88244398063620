<script setup lang="ts">
import type { SpacingOption } from '@gem/control/src/control/distance-picker/types';
import type { ScreenType } from '../../types';
import { ref, computed, provide } from 'vue';
import Inside from '../distance-picker/Inside.vue';
import InsideTriangle from '../distance-picker/InsideTriangle.vue';
import Segment from '../Segment.vue';
import type { PaddingConfig, PaddingOptions, PaddingValue } from '@gem/control/src/control/size-setting/type';
import { StateListKey, MouseUpKey } from '../distance-picker/keys';
import { onClickOutside } from '@vueuse/core';

type Props = {
  id: string;
  label?: string;
  value?: PaddingValue;
  paddingConfig?: PaddingConfig;
  sizePaddingInput?: 'normal' | 'small';
  globalSpacingValues: SpacingOption[];
  currentScreen?: ScreenType;
  onClickSubAction?: (type: string, value?: any) => void;
};

type SpacingPosition = 'top' | 'left' | 'right' | 'bottom';

type PaddingPropertyKey = `padding-${SpacingPosition}` | `padding-${SpacingPosition}-small`;

type PaddingProperties = {
  property: PaddingPropertyKey;
  class: string;
  pos: SpacingPosition;
  default?: number;
};

const initialFillInside = {
  'padding-top': '#212121',
  'padding-right': '#212121',
  'padding-left': '#212121',
  'padding-bottom': '#212121',
  'padding-top-small': '#212121',
  'padding-right-small': '#212121',
  'padding-left-small': '#212121',
  'padding-bottom-small': '#212121',
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlChange', id: string, value?: PaddingValue): void;
  (e: 'controlOnChange', id: string, value?: PaddingValue): void;
}>();

const target = ref(null);
const insidePropertyDiffList = ref<Record<string, string>>({});
const isHover = ref(false);
const isOpenSelectSpacing = ref(false);
const stateList = ref<Record<string, boolean>>({});
const mouseUpList = ref<Record<string, boolean>>({});
const fillInside = ref<Record<string, string>>(initialFillInside);

provide(StateListKey, { stateList: stateList, changeStateListOnFocus });
provide(MouseUpKey, { mouseUp: mouseUpList, detectMouseUp });

onClickOutside(target, () => {
  isHover.value = false;
  stateList.value = {};
});

const sizeConfig = computed(() => {
  return props.paddingConfig
    ? props.paddingConfig
    : {
        small: {
          vertical: '4px',
          horizontal: '16px',
        },
        medium: {
          vertical: '8px',
          horizontal: '24px',
        },
        large: {
          vertical: '12px',
          horizontal: '32px',
        },
      };
});

const spacingOptions = [
  {
    label: 'S',
    value: 'small',
  },
  {
    label: 'M',
    value: 'medium',
  },
  {
    label: 'L',
    value: 'large',
  },
  {
    label: 'Custom',
    value: 'custom',
    baseIcon: 'customize-layout',
  },
];

const buildInsideProperty = computed((): PaddingProperties[] => [
  {
    property: props.sizePaddingInput === 'normal' ? 'padding-top' : 'padding-top-small',
    class: 'top-0 ',
    pos: 'top',
  },
  {
    property: props.sizePaddingInput === 'normal' ? 'padding-left' : 'padding-left-small',
    class: 'top-0 left-0',
    pos: 'left',
  },
  {
    property: props.sizePaddingInput === 'normal' ? 'padding-bottom' : 'padding-bottom-small',
    class: 'bottom-0',
    pos: 'bottom',
  },
  {
    property: props.sizePaddingInput === 'normal' ? 'padding-right' : 'padding-right-small',
    class: 'right-0 ',
    pos: 'right',
  },
]);

const padding = computed(() => props.value);
const linked = computed(() => props.value?.linked || false);

function handleChangeInsideDiffValue(property: keyof SpacingOption, diff: string) {
  insidePropertyDiffList.value = {
    ...insidePropertyDiffList.value,
    [property]: diff,
  };
}

function insideMouseleave() {
  const newFillInside = { ...fillInside.value };
  Object.keys(newFillInside).map((v) => {
    newFillInside[v] = stateList.value[v] ? '#333333' : '#212121';
  });
  fillInside.value = newFillInside;
  isHover.value = false;
}

function insideMouseover(property: string) {
  fillInside.value = {
    ...fillInside.value,
    [property]: '#333333',
  };
  isHover.value = true;
}

const handleClickLink = () => {
  const newLinked = !linked.value;
  const currentPadding = {
    ...padding.value,
    linked: newLinked,
  };
  emit('controlChange', props.id, currentPadding);
};

function onSelectSpacing(property: string, isShow: boolean) {
  isOpenSelectSpacing.value = isShow;
  isHover.value = isShow;
}

function handleChange(property: PaddingPropertyKey, value?: string) {
  emit('controlChange', props.id, getDataChange(property, value));
}

function handleOnChange(property: PaddingPropertyKey, value?: string) {
  emit('controlOnChange', props.id, getDataChange(property, value));
}

function getDataChange(property: PaddingPropertyKey, value?: string, type?: PaddingOptions) {
  let currentPadding = padding.value;
  if (linked.value) {
    currentPadding = {
      top: value,
      bottom: value,
      left: value,
      right: value,
      type: type || 'custom',
      linked: linked.value,
    };
  } else {
    const position = property.split('-')[1] as SpacingPosition;
    currentPadding = {
      ...currentPadding,
      [position]: value,
      type: type || 'custom',
    };
  }
  return currentPadding;
}

function handleUpdatePaddingType(type?: string) {
  const newType = type as PaddingOptions;
  if (newType) {
    if (newType !== 'custom') {
      const sizeByOption = sizeConfig.value[newType];
      const newData: PaddingValue = {
        top: sizeByOption.vertical,
        bottom: sizeByOption.vertical,
        left: sizeByOption.horizontal,
        right: sizeByOption.horizontal,
        type: newType,
        linked: sizeByOption.vertical === sizeByOption.horizontal,
      };
      emit('controlChange', props.id, newData);
    } else {
      emit('controlChange', props.id, {
        ...props.value,
        type: 'custom',
      });
    }
  }
}

function detectMouseUp(property: string) {
  mouseUpList.value = {
    ...mouseUpList.value,
    [property]: !mouseUpList.value[property],
  };
}

function changeStateListOnFocus(pos: string) {
  stateList.value = {
    [pos]: true,
  };
  const newFillInside = { ...fillInside.value };
  Object.keys(newFillInside).map((v) => {
    newFillInside[v] = stateList.value[v] ? '#333333' : '#212121';
  });
  fillInside.value = newFillInside;
}
const type = computed(() => props.sizePaddingInput);
</script>

<template>
  <Segment
    id="padding-type"
    :value="props.value?.type || 'custom'"
    class="mb-16"
    :options="spacingOptions"
    @control-change="(id, value) => handleUpdatePaddingType(value)"
    @control-on-change="(id, value) => handleUpdatePaddingType(value)" />
  <div
    ref="target"
    data-test="editor-control-spacing-inside"
    class="inside border-dark-250 relative h-[132px] w-[140px] grid-cols-3 grid-rows-3 overflow-hidden rounded-xl border"
    :class="{
      '!h-[90px]': type === 'small',
    }">
    <div v-if="linked && isHover">
      <div class="inside-top-left" :class="{ '!rotate-[33deg]': type === 'small' }"></div>
      <div class="inside-bottom-left" :class="{ '!rotate-[-33deg]': type === 'small' }"></div>
      <div class="inside-top-right" :class="{ '!rotate-[-33deg]': type === 'small' }"></div>
      <div class="inside-bottom-right" :class="{ '!rotate-[33deg]': type === 'small' }"></div>
    </div>
    <button
      data-test="editor-control-spacing-synchronize"
      class="bg-dark-500 border-dark-250 hover:border-dark-500 transform-center hover:bg-dark-200 flex h-[32px] w-[38px] items-center justify-center rounded-xl border"
      :class="{
        'h-[24px]': type === 'small',
      }"
      @click.stop="handleClickLink"
      @mouseover="
        () => {
          isHover = true;
        }
      "
      @mouseleave="isHover = false">
      <g-base-icon
        v-if="!linked"
        class="text-light-400"
        name="spacing-un-link"
        width="16px"
        height="16px"
        view-box="0 0 16 16">
      </g-base-icon>
      <g-base-icon
        v-else
        class="text-light-400"
        name="spacing-link"
        width="16px"
        height="16px"
        view-box="0 0 16 16"></g-base-icon>
    </button>
    <svg
      class="overflow-hidden"
      style="grid-area: 1 / 1 / -1 / -1"
      width="140"
      :height="type === 'small' ? '90' : '132'"
      :viewBox="type === 'small' ? '0 0 140 90' : '0 0 140 132'"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <InsideTriangle
        v-for="(item, index) in buildInsideProperty"
        :id="id"
        :key="index"
        :is-open-select-spacing="isOpenSelectSpacing"
        :fill-inside="fillInside[item.property]"
        :item="item"
        :options="globalSpacingValues"
        :value="padding?.[item.pos] === null ? item.default : padding?.[item.pos]"
        @click="changeStateListOnFocus"
        @inside-mouseleave="insideMouseleave"
        @inside-mouseover="insideMouseover"
        @change-diff-value="handleChangeInsideDiffValue" />
    </svg>
    <Inside
      v-for="(item, index) in buildInsideProperty"
      :id="id"
      :key="index"
      :options="globalSpacingValues"
      :item="item"
      :type="type"
      :is-focus="stateList[item.property]"
      :is-open-select-spacing="isOpenSelectSpacing"
      :diff-value="insidePropertyDiffList[item.property]"
      :value="value?.[item.pos]"
      :on-control-change="handleChange"
      :on-select-spacing="onSelectSpacing"
      :control-change-prop="handleOnChange"
      :on-click-sub-action="onClickSubAction"
      @inside-mouseleave="insideMouseleave"
      @inside-mouseover="insideMouseover" />
  </div>
</template>

<style scoped lang="scss">
.inside {
  grid-area: 3 / 3 / span 3 / span 3;
  display: grid;
  justify-items: center;
}
.inside-top-left {
  width: 80px;
  z-index: 0;
  transform: rotate(42deg);
  transform-origin: top left;
  border-top: 1px solid #424242;
  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.inside-bottom-left {
  width: 80px;
  z-index: 0;
  transform: rotate(-41.9deg);
  left: 1.5px;
  transform-origin: bottom left;
  border-top: 1px solid #424242;
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
}
.inside-top-right {
  width: 80px;
  z-index: 0;
  transform: rotate(-42deg);
  transform-origin: top right;
  border-top: 1px solid #424242;
  position: absolute;
  top: 0px;
  right: 0px;
  box-sizing: border-box;
}
.inside-bottom-right {
  width: 80px;
  z-index: 0;
  transform: rotate(41.9deg);
  right: 1.5px;
  transform-origin: bottom right;
  border-top: 1px solid #424242;
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
}
.transform-center {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform;
}
</style>
