<script lang="ts" setup>
import { computed } from 'vue';

type Props = {
  id?: string;
  isOpen: boolean;
  disabled: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'toggle'): void;
}>();

const onToggle = () => emit('toggle');

const buttonClasses = computed(() => [
  'text-12 text-light-450 hover:bg-dark-200 placeholder:text-text-dark-100 disabled:border-dark-200 disabled:text-text-dark-100',
  'relative flex h-36 w-full items-center rounded-xl border border-transparent bg-transparent px-8 outline-none transition duration-200 disabled:cursor-not-allowed',
  { '!border-primary-300': props.isOpen },
]);
</script>

<template>
  <button
    :id="id"
    ref="button"
    data-test="editor-control-g-select-button"
    :disabled="disabled"
    :class="buttonClasses"
    @click="onToggle">
    <div class="flex w-full items-center justify-between">
      <div class="flex w-[calc(100%_-_24px)] overflow-hidden">
        <slot name="button-content" :is-open="isOpen" />
      </div>
    </div>
  </button>
</template>
