<script lang="ts" setup>
import { ref, watch } from 'vue';
import { DatePicker } from 'v-calendar';

type Props = {
  id: string;
  value: string | null;
  format?: string | null;
  isDark?: boolean;
  isRequired?: boolean;
  mode?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
};

const props = withDefaults(defineProps<Props>(), {
  isDark: false,
  isRequired: true,
  mode: 'dateTime',
  format: 'MMMM DD YYYY',
  placeholder: 'MMMM DD YYYY',
});

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value: string | Date): void;
}>();
const attributes = ref([{ bar: { style: { backgroundColor: '#0e60e5' } }, dates: new Date() }]);
const dateTime = ref<string | Date>(props.value ?? new Date());
const format = ref(props.format);

watch(dateTime, () => {
  emit('controlChange', props.id, dateTime.value);
});
</script>

<template>
  <div class="date-time-picker relative">
    <slot name="label"></slot>
    <DatePicker
      v-model="dateTime"
      :popover="{ visibility: 'focus' }"
      locale="en"
      :is-required="isRequired"
      :attributes="attributes"
      :masks="{
        input: format,
        L: format,
      }"
      :min-date="minDate"
      :max-date="maxDate"
      :is-dark="isDark"
      color="blue"
      :mode="mode">
      <template #default="{ inputValue, inputEvents }">
        <div class="relative" v-on="inputEvents">
          <textarea
            class="text-12 text-light-450 placeholder:text-text-dark-100 hover:bg-dark-200 focus:border-primary-300 disabled:border-dark-200 disabled:text-text-dark-100 bg-dark-400 h-[56px] w-full resize-none overflow-hidden rounded-xl border border-transparent p-8 outline-none transition duration-200 hover:border-transparent disabled:cursor-not-allowed"
            :value="inputValue"
            :placeholder="placeholder" />
        </div>
      </template>
    </DatePicker>
  </div>
</template>
<style lang="scss" scoped>
.date-time-picker {
  height: 56px;
  :deep(.vc-popover-caret) {
    display: none;
  }
}
</style>
