<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = defineProps<{
  isActive?: boolean;
  bundleItem?: any;
}>();

const showAction = ref(false);
const hasHover = ref(false);
const title = computed(() => `Buy ${props.bundleItem.quantity}`);

// const displayDeal = computed(() => {
//   const discountType = props.bundleItem.discountType;
//   const discountValue = props.bundleItem.discountValue;
//   if (!discountValue || parseInt(discountValue) <= 0) return 'No discount';
//   switch (discountType) {
//     case 'no_discount':
//       return 'No discount';
//     case 'percentage':
//       return `${discountValue}% off`;
//     case 'fixed_amount':
//       return `${discountValue}$ off`;
//     default:
//       return '';
//   }
// });
</script>

<template>
  <div :class="{ 'border-dark-300 mb-8 overflow-hidden rounded-xl border': isActive }">
    <div
      :class="{
        'mb-8 rounded-xl': !isActive,
        'rounded-t-xl border-b-0': isActive,
      }"
      class="bg-dark-400 group-[.sortable-fallback]/sortable-item:bg-dark-400 group-[&:not(.dragging)]/sortable:hover:bg-dark-250 group-[.sortable-ghost]/sortable-item:bg-dark-250 relative flex h-36 select-none items-center justify-between overflow-hidden pl-8"
      @mouseover="hasHover = true"
      @mouseleave="hasHover = false">
      <div class="relative z-10 flex items-center">
        <span
          class="hover:text-dark-high mr-4 inline-flex cursor-pointer p-4 text-white transition-all duration-200"
          :class="{ 'rotate-180': isActive }">
        </span>
        <span
          class="text-12 text-light-100 truncate"
          :class="{ 'max-w-[114px]': showAction, 'max-w-[160px]': !showAction }"
          v-html="title">
        </span>
      </div>
      <!-- <div v-if="bundleItem" class="text-12 text-light-100 mr-16 truncate">
        {{ displayDeal }}
      </div> -->
    </div>
  </div>
</template>
