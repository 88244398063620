<script lang="ts" setup>
import type { SwatchesOptionValue } from '@gem/common';

defineProps<{
  option: SwatchesOptionValue;
  index: number;
  showError?: boolean;
  isUploading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'handle-change-file', event: Event, index: string): void;
}>();

const handleChangeFile = (event: Event, index: string) => {
  emit('handle-change-file', event, index);
};
</script>

<template>
  <div
    :class="`hover:bg-light-250 active:border-primary-300 border-light-400  text-14 group relative mt-16 flex h-[80px] cursor-pointer items-center justify-between rounded-[12px] border p-12 first:mt-8 ${
      showError && !option.imageUrl ? 'border-yellow-300' : ''
    }`">
    <input
      :class="`absolute left-0 h-full w-full border opacity-0 ${isUploading ? '!cursor-progress' : 'cursor-pointer'}`"
      type="file"
      accept="image/*"
      :disabled="isUploading"
      @change.stop="handleChangeFile($event, index.toString())" />
    <span class="line-clamp-2">{{ option.label }}</span>
    <div
      :class="`pointer-events-none flex h-[56px] w-[56px] shrink-0 justify-center overflow-hidden rounded-[8px] border group-hover:bg-black group-hover:bg-opacity-[0.1] ${
        option.imageUrl ? 'border-light-500' : 'border-dark-100'
      }`">
      <div v-if="isUploading" class="flex items-center">
        <g-base-icon class="animate-[spin_1.5s_linear_infinite]" name="loading" width="20" height="20"></g-base-icon>
      </div>
      <div v-else-if="option.imageUrl" class="relative h-full w-full">
        <div
          class="d bg-overlay-black invisible absolute flex h-full w-full items-center justify-center rounded-[8px] group-hover:visible">
          <g-base-icon name="upload" width="21px" viewBox="0 0 16 16" fill="white" style="color: white"> </g-base-icon>
        </div>
        <img class="h-full w-full !cursor-pointer object-cover hover:bg-black" :src="option.imageUrl" alt="image" />
      </div>
      <g-base-icon v-else name="upload" width="21px" viewBox="0 0 16 16" fill="none"></g-base-icon>
    </div>
  </div>
</template>
