import type { Ref } from 'vue';
import { reactive, watch } from 'vue';
import type { PreVariantSelected, Product } from '../MultiProductModal.vue';
import { getDefaultVariantSelected } from '../helpers';

export const useProductVariantSelected = (props: {
  value?: PreVariantSelected;
  list?: Product[];
  productsSelected?: Ref<Product[]>;
}) => {
  const { value } = props;
  const productVariantSelect = reactive(value ?? {});

  watch(
    () => value,
    (newValue) => {
      if (newValue) {
        Object.assign(productVariantSelect, newValue);
      }
    },
  );

  watch(
    () => props.productsSelected?.value,
    (newValue) => {
      const ids = newValue?.map((el) => el.id);
      Object.keys(productVariantSelect).map((el) => {
        const isExist = ids?.includes(el);
        if (!isExist) delete productVariantSelect[el];
      });
    },
  );

  const handleChangeVariant = (product: Product, optionName?: string, optionValue?: string) => {
    if (!optionName) return;
    if (!productVariantSelect[product?.id])
      productVariantSelect[product?.id] = {
        variantId: '',
        optionSelected: {},
      };
    productVariantSelect[product?.id].optionSelected[optionName] = optionValue ?? '';
    const title = Object.values(productVariantSelect[product?.id].optionSelected).join(' / ');
    const variant = product?.variants?.edges?.find((el: any) => el?.node?.title === title);
    productVariantSelect[product?.id].variantId = variant?.node?.id;
  };

  const handleSelectVariantDefault = (item: Product) => {
    const { variant, variantId } = getDefaultVariantSelected(item);
    productVariantSelect[item.id] = {
      variantId,
      optionSelected: variant,
    };
  };

  const handleRemoveVariantDefault = (item: Product) => {
    delete productVariantSelect[item.id];
  };

  const handleAddVariant = (item: Product) => {
    if (productVariantSelect?.[item.id]) {
      const { variant, variantId } = getDefaultVariantSelected(item);
      productVariantSelect[item.id].variantId = variantId;
      productVariantSelect[item.id].optionSelected = variant;
    }
  };

  const handleDeleteVariant = (item: Product) => {
    if (productVariantSelect?.[item.id]) delete productVariantSelect[item.id];
  };

  return {
    productVariantSelect,
    handleChangeVariant,
    handleSelectVariantDefault,
    handleRemoveVariantDefault,
    handleAddVariant,
    handleDeleteVariant,
  };
};
