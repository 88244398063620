import type { Ref } from 'vue';
import { inject, computed } from 'vue';
import type { TypographyFamily, TypographyFontOption } from '../../../types';
import type { globalStyleFontProps } from '../types';
import { useResolveFontValue } from './useResolveFontValue';

export const useCheckIsRecentFont = (themeFont: Ref<globalStyleFontProps | undefined>) => {
  const resolveFontValue = useResolveFontValue(themeFont);

  const pageMetaRecentlyValueParse = inject<Ref<TypographyFamily>>('pageMetaRecentlyValueParse');

  const recentFonts = computed(() => {
    const value = pageMetaRecentlyValueParse?.value;
    return Array.isArray(value) ? value : [];
  });

  return (font: TypographyFontOption): boolean => {
    if (!recentFonts.value) return false;

    return recentFonts.value.some((recentFont) => {
      const recentFontValue = getRecentFontValue(recentFont);

      if (recentFont.value.type === 'theme') {
        return resolveFontValue(recentFontValue) === font.value;
      }

      return font.value === recentFontValue;
    });
  };
};

export function getRecentFontValue(recentFont: any): string {
  return typeof recentFont.value === 'string' ? recentFont.value : recentFont.value.value;
}
