<script lang="ts" setup>
import type { IconName } from '@gem/uikit/src/v2/types';

type Actions = {
  label?: string | number;
  type?: 'tertiary' | 'primary';
  size?: 'large' | 'medium' | 'small';
  icon?: IconName;
  iconPosition?: 'left' | 'right';
  buttonType?: 'link' | 'action';
  actionHandle?: string;
  link?: string;
};
type Steps = {
  label?: string | number;
  type?: string;
  icon?: IconName;
  value: string;
  actions?: Actions[];
};
type PropsType = {
  steps?: Steps[];
  isPublished?: string;
  openUrlEditorShopify: () => void;
};
const props = defineProps<PropsType>();

const handleClick = (action: Actions) => {
  if (!props.isPublished) return;

  if (action.buttonType == 'link') {
    action.link ? window.open(action.link) : props.openUrlEditorShopify();
    return;
  }
  if (action.buttonType == 'action') {
    switch (action.actionHandle) {
      default:
        break;
    }
  }
  return;
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="rounded-medium text-light-200 bg-dark-400 p-[12px]">
      <template v-if="props?.steps">
        <div v-for="(item, index) in props.steps" :key="index" class="mb-16 flex last:mb-0">
          <div class="h-[18px] w-[18px]" v-html="item.icon"></div>
          <div class="ml-8">
            <p class="text-small font-regular text-text-dark-300 leading-5">{{ item.label }}</p>
            <template v-if="item.actions">
              <div v-for="(action, indexAction) in item.actions" :key="indexAction" class="mt-8">
                <GButtonV2 :disable="!isPublished" :type="action.type" :size="action.size" @click="handleClick(action)">
                  <span v-if="action.iconPosition == 'left'" v-html="action.icon"></span>
                  {{ action.label }}
                  <span
                    v-if="action.iconPosition == 'right'"
                    :class="{ 'opacity-[0.3]': !isPublished }"
                    v-html="action.icon"></span>
                </GButtonV2>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <slot name="info"></slot>
  </div>
</template>
