import type { Ref } from 'vue';

import { computed } from 'vue';

import { isColor } from '../../../helpers/regex';

const DEFAULT_COLOR = ['#121212', '#4F4F4F', '#E2E2E2', '#FFFFFF', '#334FB4'];
export const MAX_COLOR_SIZE = 8;
export const SUGGESTED_COLOR_FOR_YOU = [
  '#00000000',
  '#151515',
  '#764293',
  '#3C96EE',
  '#4D903F',
  '#DFB52C',
  '#F3841D',
  '#D63837',
];

const useMyColor = (currentGlobalStyleColor: Ref<any> | undefined, globalColors: Ref<any>) => {
  const systemColor = computed(() => {
    return [
      currentGlobalStyleColor?.value?.brand,
      currentGlobalStyleColor?.value?.['text-1'],
      currentGlobalStyleColor?.value?.highlight,
      currentGlobalStyleColor?.value?.['text-3'],
      currentGlobalStyleColor?.value?.info,
    ];
  });

  const globalColor = computed(() => {
    if (!currentGlobalStyleColor) return DEFAULT_COLOR.map((c) => c.toLowerCase());

    if (!globalColors.value || !currentGlobalStyleColor.value) {
      return DEFAULT_COLOR.map((c) => c.toLowerCase());
    }

    if (currentGlobalStyleColor.value?.['my-colors'] && currentGlobalStyleColor.value?.['my-colors'].length > 0) {
      const colors = currentGlobalStyleColor.value?.['my-colors']
        .concat(currentGlobalStyleColor.value['theme-colors'] as string[])
        .filter(Boolean);

      return colors.map((c: string) => {
        if (isColor(c)) {
          return c;
        } else {
          return globalColors.value.find((col: any) => col.colorType === c)?.color;
        }
      });
    }

    return DEFAULT_COLOR.map((c) => c.toLowerCase());
  });

  const myColors = computed(() =>
    ([...new Set(globalColor.value)] as string[]).filter((color) => color !== 'transparent').slice(0, MAX_COLOR_SIZE),
  );

  return {
    myColors,
    SUGGESTED_COLOR_FOR_YOU,
    systemColor,
  };
};

export default useMyColor;
