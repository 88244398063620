import type { OptionItem } from '../../type/common';

export const POST_PURCHASE_TEXT_COLOR = [
  {
    label: 'Black',
    value: 'default',
    svgIcon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="9.99994" r="10" fill="#333333"/>
      <circle cx="10" cy="9.99994" r="9.5" stroke="white" stroke-opacity="0.2"/>
      </svg>
    `,
  },
  {
    label: 'Red',
    value: 'critical',
    svgIcon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="9.99994" r="10" fill="#E22222"/>
      <circle cx="10" cy="9.99994" r="9.5" stroke="white" stroke-opacity="0.2"/>
      </svg>
    `,
  },
  {
    label: 'Green',
    value: 'success',
    svgIcon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="9.99994" r="10" fill="#0CA127"/>
      <circle cx="10" cy="9.99994" r="9.5" stroke="white" stroke-opacity="0.2"/>
      </svg>
    `,
  },
  {
    label: 'Yellow',
    value: 'warning',
    svgIcon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="9.99994" r="10" fill="#B88700"/>
      <circle cx="10" cy="9.99994" r="9.5" stroke="white" stroke-opacity="0.2"/>
      </svg>
    `,
  },
];

export const POST_PURCHASE_FONT_SIZE: OptionItem[] = [
  {
    label: 'Default (14px)',
    value: 'default',
  },
  {
    label: 'Small (12px)',
    value: 'small',
  },
  {
    label: 'Medium (16px)',
    value: 'medium',
  },
  {
    label: 'Large (18px)',
    value: 'large',
  },
  {
    label: 'XLarge (24px)',
    value: 'xlarge',
  },
];

export const POST_PURCHASE_FONT_WEIGHTS: OptionItem[] = [
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Bold',
    value: 'bold',
  },
];

export const POST_PURCHASE_TEXT_TRANSFORM: OptionItem[] = [
  {
    label: 'None',
    value: 'none',
    icon: `<svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.75 10C3.75 9.65482 4.02982 9.375 4.375 9.375H15.625C15.9702 9.375 16.25 9.65482 16.25 10C16.25 10.3452 15.9702 10.625 15.625 10.625H4.375C4.02982 10.625 3.75 10.3452 3.75 10Z" fill="currentColor"/>
</svg>
`,
  },
  {
    label: 'AA',
    value: 'uppercase',
  },
  {
    label: 'Aa',
    value: 'capitalize',
  },
  {
    label: 'aa',
    value: 'lowercase',
  },
];
