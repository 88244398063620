<script setup lang="ts">
import { shapeMap } from './shapes';
import Trigger from './Trigger.vue';
import SelectBadgeShape from './Shape.vue';

type PropTypes = {
  id?: string | number;
  value?: string;
};

const props = defineProps<PropTypes>();
const emits = defineEmits<{
  (event: 'controlChange', controlId?: string | number, value?: string): void;
}>();

const onChangeShape = (shapeId: string) => {
  emits('controlChange', props.id, shapeId);
};
</script>

<template>
  <div class="grid w-full justify-end">
    <GPopover
      :has-arrow="false"
      :closeable="true"
      :overlay="false"
      :no-shadow-box="true"
      overlay-container="#root-modal"
      wrapper-class="w-[348px] !left-[63px] "
      placement="bottom-end">
      <template #default="{ open }">
        <Trigger :open="open" :shape="shapeMap.get(value)" />
      </template>
      <template #content="{ close }">
        <SelectBadgeShape :value="value" @close="close" @change="onChangeShape" />
      </template>
    </GPopover>
  </div>
</template>
