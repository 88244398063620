<script lang="ts" setup>
import type { ComponentOptionsMixin } from 'vue';
import type { TriggerEvent, DisplayTrigger } from './type';
import { computed, ref, withDefaults } from 'vue';
import { GBaseIcon, GTooltip, GLoadingPlaceholder } from '@gem/uikit';
import { TriggerType } from './constant';
import DisplayConditionPopover from './DisplayConditionPopover.vue';
import InventoryStatus from './InventoryStatus/InventoryStatus.vue';
import DiscountPrice from './DiscountPrice/DiscountPrice.vue';
import PriceRange from './PriceRange/PriceRange.vue';
import ProductTag from './ProductTag/ProductTag.vue';
import CreationDate from './CreationDate/CreationDate.vue';

const displayTriggerComponent: Record<TriggerType, ComponentOptionsMixin> = {
  [TriggerType.INVENTORY_STATUS]: InventoryStatus,
  [TriggerType.DISCOUNT_PRICE]: DiscountPrice,
  [TriggerType.PRICE_RANGE]: PriceRange,
  [TriggerType.PRODUCT_TAGS]: ProductTag,
  [TriggerType.CREATION_DATE]: CreationDate,
};

type PropsType = {
  id: string;
  value?: DisplayTrigger[];
  productTags?: string[];
  isDisabledTrigger: boolean;
  moneyFormat?: string;
  isLoading?: boolean;
  shopDomain?: string;
};

const props = withDefaults(defineProps<PropsType>(), {
  value: () => [],
});

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: DisplayTrigger[]): void;
  (e: 'controlChangeWithOption', controlId: string, value?: DisplayTrigger[]): void;
  (event: 'onEdit'): void;
  (event: 'onLoadMore'): void;
  (event: 'onSearch', value: string): void;
}>();

const triggerActive = ref('');
const displayTriggerItems = computed(() => {
  const arr = props.value;
  return arr?.sort((a, b) => Number(a?.priority) - Number(b?.priority));
});

const onAddTrigger = (trigger: DisplayTrigger) => {
  triggerActive.value = trigger?.triggerEvent ?? '';
  return handleControlChange([...displayTriggerItems.value, trigger]);
};

const onUpdateTrigger = (data: DisplayTrigger) => {
  return handleControlChange(
    displayTriggerItems.value.map((item) => (item?.triggerEvent === data?.triggerEvent ? data : item)),
  );
};

const onDeleteTrigger = (triggerEvent: TriggerEvent) => {
  return handleControlChange(displayTriggerItems.value.filter((item) => item?.triggerEvent !== triggerEvent));
};

const handleControlChange = (value: DisplayTrigger[]) => {
  return emit('controlChange', props.id, value);
};

const onEdit = () => {
  emit('onEdit');
};

const onLoadMore = () => {
  emit('onLoadMore');
};
</script>

<template>
  <div class="gemx-control">
    <div class="relative mb-4 flex h-[36px] items-center justify-between">
      <div class="!text-14 flex items-center gap-8 whitespace-nowrap font-medium text-white">
        Display conditions
        <GTooltip content-class="w-[252px] !whitespace-normal break-words">
          <GBaseIcon name="question-18" width="16" height="16" class="!text-text-dark-100" viewBox="0 0 18 18" />
          <template #content>
            Badge show on all products in Editor, but only on those meeting all conditions on the live page
          </template>
        </GTooltip>
      </div>
      <DisplayConditionPopover
        :is-loading="isLoading"
        :trigger-event="displayTriggerItems"
        :is-disabled-trigger="isDisabledTrigger"
        @on-add="onAddTrigger" />
    </div>
    <div class="flex flex-col gap-16">
      <template v-if="isLoading">
        <GLoadingPlaceholder
          v-for="n in displayTriggerItems.length || 1"
          :key="n"
          cls="!rounded-xl"
          dark
          width="248px"
          height="72px" />
      </template>
      <template v-else>
        <template v-for="item in displayTriggerItems" :key="item?.triggerEvent">
          <component
            :is="displayTriggerComponent[item.triggerEvent]"
            v-if="item?.triggerEvent"
            :data="item"
            :active="triggerActive === item.triggerEvent"
            :product-tags="productTags"
            :money-format="moneyFormat"
            :is-loading="isLoading"
            :shop-domain="shopDomain"
            @on-edit="onEdit"
            @on-load-more="onLoadMore"
            @on-update="onUpdateTrigger"
            @on-search="(value: string) => emit('onSearch', value)"
            @on-delete="onDeleteTrigger(item.triggerEvent)" />
        </template>
      </template>
    </div>
  </div>
</template>
