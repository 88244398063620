<script lang="ts" setup>
import { computed } from 'vue';
import { checkSortedArray } from '../../helpers/common';
import type { GroupTypeSetting, ScreenType } from '../../types';
import DeviceRecommend from './components/DeviceRecommend.vue';
import GridArrange from '../layout/components/GridArrange.vue';
import { getOrderItem } from './helper';

type ActionSettingInput = {
  groupType: GroupTypeSetting;
  componentUid: string;
  controlType: string;
  controlId: string;
  newValue: any;
  screenId: ScreenType;
  hasDevices?: boolean;
};

type GridSettingInput = Pick<ActionSettingInput, 'newValue' | 'screenId'>;

type ChildItem = {
  uid: string;
  tag: string;
  label?: string;
  settings?: Record<string, any>;
  styles?: Record<string, any>;
  advanced?: Record<string, any>;
  [key: string]: any;
};

type LayoutValue = {
  display?: string;
  cols?: number[];
};

type Props = {
  id: string;
  controlType: string;
  currentScreen?: ScreenType;
  value?: LayoutValue;
  childrens?: ChildItem[];
  maxCol?: number;
  isProductLayout?: boolean;
  devices?: {
    desktop?: {
      default?: LayoutValue;
    };
    tablet?: {
      default?: LayoutValue;
    };
    mobile?: {
      default?: LayoutValue;
    };
  };
};
const props = withDefaults(defineProps<Props>(), {
  maxCol: 2,
  currentScreen: 'desktop',
});

const emit = defineEmits<{
  (e: 'control-change', id: Props['id'], value: Props['value'] | undefined, screenId?: ScreenType): void;
  (e: 'control-on-change', id: Props['id'], value: Props['value'] | undefined, screenId?: ScreenType): void;
  (
    e: 'control-children-change',
    contentSide: 'left' | 'right',
    options?: {
      noRecordHistory: boolean;
    },
  ): void;
  (e: 'layout-change', input: GridSettingInput[]): void;
  (e: 'remove-item', uid: string): void;
  (e: 'copy-item', uid: string): void;
  (e: 'add-item'): void;
}>();

const currentScreen = computed(() => props.currentScreen);
const numberOfCols = computed(() => props.value?.cols?.length || 0);
const columnWidth = computed(() => props.value?.display || 'fill');

const maxColumn = computed(() => {
  return props.devices?.desktop?.default?.cols?.length || 2;
});

const handleChangeLayout = (totalCol: number) => {
  if (columnWidth.value === 'fit') {
    emit('control-change', props.id, {
      ...props.value,
      display: 'fill',
    });
  }
  if (numberOfCols.value === totalCol || !totalCol) return;
  if (props.currentScreen === 'desktop') {
    const input: GridSettingInput[] = [
      {
        newValue: {
          ...props.value,
          display: 'fill',
          cols: Array.from({ length: totalCol }, () => 12 / totalCol),
        },
        screenId: 'desktop',
      },
      { newValue: undefined, screenId: 'tablet' },
      {
        newValue: {
          cols: [12],
          display: 'fill',
        },
        screenId: 'mobile',
      },
    ];
    emit('layout-change', input);
  } else {
    emit('control-change', props.id, {
      ...props.value,
      cols: Array.from({ length: totalCol }, () => 12 / totalCol),
    });
  }
};

const handleOnChangeCol = (cols: number[]) => {
  emit('control-on-change', props.id, {
    ...props.value,
    cols,
  });
};
const handleChangeCol = (cols: number[]) => {
  emit('control-change', props.id, {
    ...props.value,
    cols,
  });
};

const swapOrder = (contentSide: 'left' | 'right') => {
  if (numberOfCols.value < 2) {
    emit('control-children-change', contentSide, { noRecordHistory: true });
    return;
  }
  emit('control-children-change', contentSide);
};

const hasSwapted = computed(
  () =>
    !checkSortedArray(
      props.childrens?.map((item) => getOrderItem(item.styles?.order || {}, currentScreen.value)) || [],
    ),
);

const hiddenRatio = computed(() => (numberOfCols.value || 0) <= 1 || columnWidth.value !== 'fill');
</script>

<template>
  <div class="w-full">
    <slot name="label"></slot>
    <div class="relative flex w-full flex-col">
      <DeviceRecommend
        :value="value"
        :device="currentScreen"
        :max-col="maxColumn"
        :has-swapted="hasSwapted"
        @change="handleChangeLayout"
        @swap-order="swapOrder" />
      <div v-if="!hiddenRatio" class="mt-8">
        <p class="text-12 text-text-dark-300 font-regular h-36 leading-[36px]">Ratio</p>
        <GridArrange :cols="value?.cols" @control-change="handleChangeCol" @control-on-change="handleOnChangeCol" />
      </div>
    </div>

    <slot name="info"></slot>
  </div>
</template>
