<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import RadiusMoreSetting from './RadiusMoreSetting.vue';
import Segment from '../Segment.vue';
import { CornerRadiusPreset } from './types';
import type { CornerRadius } from './types';

type Props = {
  id: string;
  value?: CornerRadius;
  mobileOnly?: boolean;
  presetValue?: {
    large: string | undefined;
    medium: string | undefined;
    small: string | undefined;
  };
};

const CIRCLE_VALUE = 999999;

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value: any): void;
  (e: 'controlOnChange', controlId: string, value: any): void;
}>();

const props = defineProps<Props>();

const isLinkedByValue = computed(() => {
  const bblr = props.value?.bblr || '';
  const bbrr = props.value?.bbrr || '';
  const btlr = props.value?.btlr || '';
  const btrr = props.value?.btrr || '';
  return bblr === bbrr && bblr === btrr && bblr === btlr;
});

const prevRoundedValue = ref();
const linked = ref(isLinkedByValue.value);
const valueArr = computed(() => {
  return [props.value?.bblr, props.value?.bbrr, props.value?.btlr, props.value?.btrr].map((val) => {
    if (!val) return '0px';
    return val;
  });
});
const cornerType = computed(() => {
  if (valueArr?.value.every((val) => val === `0px`)) return 'square';
  else if (valueArr.value.every((val) => parseInt(val || '0px') >= CIRCLE_VALUE)) return 'circle';
  else return 'rounded';
});

const isDisplayMoreSetting = computed(() => {
  return cornerType.value === 'rounded';
});

const cornerOptions = [
  {
    label: 'Square',
    value: 'square',
    type: 'corner',
    viewBox: '0 0 24 14',
    width: '22',
    height: '14',
  },
  {
    label: 'Rounded',
    value: 'rounded',
    type: 'corner',
    viewBox: '0 0 24 14',
    width: '22',
    height: '14',
  },
  {
    label: 'Circle',
    value: 'circle',
    type: 'corner',
    viewBox: '0 0 17 16',
    width: '16',
    height: '16',
  },
];

const disable = ref(false);

const isObjectEmpty = (obj: Record<string, any>): boolean => {
  return Object.values(obj).length === 0;
};

const setPrevRoundedValue = (prevRoundedValue: CornerRadius | undefined, value: CornerRadius | undefined) => {
  const arr = [value?.bblr, value?.bbrr, value?.btlr, value?.btrr].map((val) => {
    if (!val) return '0px';
    return val;
  });
  if (
    arr?.every((val) => parseInt(val || '0px') >= CIRCLE_VALUE) ||
    arr?.every((val) => ['0px', '', undefined].includes(val))
  ) {
    return { ...prevRoundedValue };
  }
  return { ...value };
};

const updateBorderRadius = (preset?: CornerRadiusPreset, value?: CornerRadius) => {
  if (preset === undefined) {
    return;
  }
  const changeValue = {
    ...value,
  };
  prevRoundedValue.value = setPrevRoundedValue(prevRoundedValue.value, value);
  switch (preset) {
    case 'square':
      linked.value = true;
      changeValue.bblr = changeValue.bbrr = changeValue.btlr = changeValue.btrr = '0px';
      break;
    case 'circle':
      linked.value = true;
      changeValue.bblr = changeValue.bbrr = changeValue.btlr = changeValue.btrr = `${CIRCLE_VALUE}px`;
      break;
    case 'rounded':
      if (!isObjectEmpty(prevRoundedValue.value)) {
        changeValue.bblr = prevRoundedValue.value.bblr;
        changeValue.bbrr = prevRoundedValue.value.bbrr;
        changeValue.btlr = prevRoundedValue.value.btlr;
        changeValue.btrr = prevRoundedValue.value.btrr;
        break;
      }
      linked.value = true;
      changeValue.bblr = changeValue.bbrr = changeValue.btlr = changeValue.btrr = '8px';
      break;
  }
  handleEmit('change', changeValue);
};

const handleEmit = (type: 'change' | 'onChange', value: CornerRadius) => {
  value.radiusType = 'custom';
  prevRoundedValue.value = setPrevRoundedValue(prevRoundedValue.value, value);
  if (type === 'change') emit('controlChange', props.id, value);
  else emit('controlOnChange', props.id, value);
};

const updateLinked = (value: boolean) => {
  linked.value = value;
};

watch(isLinkedByValue, () => {
  linked.value = isLinkedByValue.value;
});
</script>

<template>
  <div class="w-full">
    <div class="flex w-full flex-col items-center gap-8">
      <Segment
        id="type"
        :value="cornerType"
        :options="cornerOptions"
        @control-change="(_, presetValue) => updateBorderRadius(presetValue as CornerRadiusPreset, value)">
      </Segment>
      <RadiusMoreSetting
        v-if="isDisplayMoreSetting"
        :value="value"
        :disable="disable"
        :circle-value="CIRCLE_VALUE"
        :is-linked="linked"
        @update-linked="updateLinked"
        @control-change="(value) => handleEmit('change', value)"
        @control-on-change="(value) => handleEmit('onChange', value)" />
    </div>
  </div>
</template>
