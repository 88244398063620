<script setup lang="ts">
import type { PreVariantSelected, Product } from './MultiProductModal.vue';
import OptionSelected from './OptionSelected.vue';

const props = defineProps<{
  item: Product;
  variants: PreVariantSelected;
  list?: Product[];
  productsSelected?: Product[];
}>();

const emits = defineEmits<{
  (e: 'handleChangeVariant', item: Product, optionName?: string, optionValue?: string): void;
  (e: 'handleRemoveVariantDefault', item: Product): void;
  (e: 'handleSelectVariantDefault', item: Product): void;
}>();

const getEnabledVariant = (item: Product) => {
  const isExist = props.productsSelected?.find((i) => i.id === item.id);
  const itemDetail = props.list?.find((i) => i.id === item.id);
  if (!isExist || !itemDetail) return false;
  return itemDetail.variants?.edges?.length > 1;
};
</script>

<template>
  <div class="flex flex-col">
    <h4
      class="text-14 line-clamp-1 text-text-light-500 mr-16 overflow-hidden text-ellipsis font-medium leading-6"
      :class="{
        'mr-[72px]': item.status?.toLowerCase() !== 'active',
        '!mr-[90px]': item.status?.toLowerCase() === 'archived',
      }">
      {{ item?.title }}
    </h4>
    <div v-if="getEnabledVariant(item)" class="mt-4">
      <div v-if="variants[item.id]" class="text-12 flex flex-col gap-4">
        <div class="flex flex-row gap-8">
          <div v-for="(option, optionIdx) in item.options" :key="optionIdx">
            <OptionSelected
              :item="item"
              :variants="variants"
              :list="list"
              :option="option"
              @handle-change-variant="(value) => emits('handleChangeVariant', item, option.name, value)" />
          </div>
        </div>
        <GButtonV2
          justify-content="start"
          type="link"
          size="small"
          class="!font-regular !text-red-300"
          @click.stop="() => emits('handleRemoveVariantDefault', item)"
          >Remove default variant</GButtonV2
        >
      </div>
      <div v-else class="text-12 flex max-h-[20px] flex-row items-center gap-4">
        <p class="text-text-light-300">All variants selected</p>
        <GButtonV2
          type="link"
          size="small"
          class="!font-regular !text-primary-300"
          @click.stop="() => emits('handleSelectVariantDefault', item)"
          >Select variant</GButtonV2
        >
      </div>
    </div>
  </div>
</template>
