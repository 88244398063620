<script setup lang="ts">
const props = defineProps<{
  id: string;
  value?: any;
}>();

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: boolean): void;
}>();

const OPTIONS = [
  {
    label: 'Sync variants & quantity across page',
    value: true,
    icon: 'sync-product-properties-on',
  },
  {
    label: 'Keep variants & quantity independent',
    value: false,
    icon: 'sync-product-properties-off',
  },
];

const handleChange = (value: boolean) => {
  if (props.value != value) {
    emit('controlChange', props.id, value);
  }
};
</script>
<template>
  <div>
    <div
      v-for="(item, index) in OPTIONS"
      :key="index"
      ref="optionItem"
      class="bg-text-light-500 hover:bg-dark-250 relative mb-8 flex h-64 cursor-pointer items-center justify-between overflow-hidden rounded-xl border border-transparent p-[7px]"
      :class="{ 'border-primary-300': value == item.value }"
      @click="handleChange(item.value)">
      <div class="relative z-10 flex items-center">
        <div class="flex h-[48px] w-[64px] items-center">
          <g-base-icon width="64" height="48" viewBox="0 0 64 48" :name="item?.icon"></g-base-icon>
        </div>
        <span class="text-12 text-text-dark-500 ml-8 font-medium"> {{ item.label }}</span>
      </div>
    </div>
  </div>
</template>
