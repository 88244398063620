<script lang="ts" setup>
import type { SwatchesOptionValue } from '@gem/common';
import type { GradientColor, TAB_TYPE } from '../../color-picker/types';

import { ref, watch, provide, computed } from 'vue';

import ColorModalHeader from '../../color-picker/components/ColorModalHeader.vue';
import {
  DEFAULT_START_GRADIENT_COLOR,
  GRADIENT_KEY_WORD,
  GRADIENT_TAB,
  SOLID_TAB,
  TABS,
  TRANSPARENT_COLOR,
  generateDefaultGradient,
  toGradientObject,
  toGradientString,
} from '../../color-picker/helpers';
import ID from '../../../utils/id';
import useGradientColor, { GRADIENT_PROVIDE_KEY } from '../../color-picker/composables/useGradientColor';
import ColorPicker from '../../../control/color-picker/components/ColorPicker.vue';
import InputColor from '../../../control/color-picker/components/InputColor.vue';
import GradientPicker from '../../../control/color-picker/components/GradientPicker/GradientPicker.vue';
import { COLOR_PICKER_MODE, THEME_LIGHT } from '../../color-picker/const';

const emits = defineEmits<{ (e: 'changeColor', index: number, value?: string): void }>();

const props = defineProps<{
  item: SwatchesOptionValue;
  index: number;
  isColor?: boolean;
  color?: string;
  isShowInvalid?: boolean;
}>();

// ==================== Color Control ==================== //
const previousColor = ref<Record<TAB_TYPE, string | undefined>>({
  [GRADIENT_TAB]: undefined,
  [SOLID_TAB]: undefined,
});
const onChangeColor = (value: string) => {
  const newColor = value ? value : TRANSPARENT_COLOR;
  previousColor.value[activeTab.value] = newColor;
  emits('changeColor', props.index, value);
};

// ==================== Share display Control ==================== //
const showTransparentColor = computed(() => !props.color || props.color === TRANSPARENT_COLOR);
const styleObject = computed(() =>
  !props.color
    ? { backgroundColor: 'transparent' }
    : props.color.startsWith('linear-gradient')
    ? { backgroundImage: props.color }
    : { backgroundColor: props.color },
);

// ==================== Popup Control ==================== //
// Somehow when open popup ColorPicker can not get correct dimension so we use this to trigger an additional re-render
const reRenderColorPickerAgainAfterOpenPopupToRecalculateRectSize = ref(false);
const openColorModal = ref(false);
const popOver = ref<any>(null);
const onClose = () => {
  openColorModal.value = false;
  if (popOver.value) {
    popOver.value.close();
    setTimeout(() => {
      reRenderColorPickerAgainAfterOpenPopupToRecalculateRectSize.value =
        !reRenderColorPickerAgainAfterOpenPopupToRecalculateRectSize.value;
    }, 0);
  }
};
const onOpen = () => {
  openColorModal.value = true;
  setTimeout(() => {
    reRenderColorPickerAgainAfterOpenPopupToRecalculateRectSize.value =
      !reRenderColorPickerAgainAfterOpenPopupToRecalculateRectSize.value;
  }, 0);
};

// ==================== Tab Control ==================== //
const tabs = TABS;
const activeTab = ref(props.color?.includes(GRADIENT_KEY_WORD) ? GRADIENT_TAB : SOLID_TAB);
const onChangeTab = (tab: TAB_TYPE) => {
  activeTab.value = tab;
  const firstPointID = ID();
  selectedPointID.value = firstPointID;
  if (activeTab.value === SOLID_TAB) {
    return emits(
      'changeColor',
      props.index,
      previousColor.value[SOLID_TAB] || [...gradientColor.value!.points.values()][0].color.getColor(),
    );
  }

  if (previousColor.value[GRADIENT_TAB]) {
    gradientColor.value = toGradientObject(previousColor.value[GRADIENT_TAB]);
    selectedPointID.value = [...gradientColor.value!.points.keys()][0];
  } else {
    const mainColor = props.color && props.color !== TRANSPARENT_COLOR ? props.color : DEFAULT_START_GRADIENT_COLOR;
    gradientColor.value = generateDefaultGradient(firstPointID, mainColor);
  }

  emits('changeColor', props.index, toGradientString(gradientColor.value!));
};

// ==================== Gradient Picker Control ==================== //
const gradientColor = ref<GradientColor | undefined>(toGradientObject(props.color));
const {
  selectedPointID,
  selectedPoint,
  addPoint,
  updateAngle,
  updateColor,
  updatePosition,
  removePoint,
  selectPoint,
  setSelectedPointID,
} = useGradientColor(gradientColor, onChangeColor);
provide(GRADIENT_PROVIDE_KEY, {
  selectedPointID,
  addPoint,
  updateAngle,
  updateColor,
  updatePosition,
  removePoint,
  selectPoint,
});
watch(openColorModal, (newValue) => {
  if (newValue && activeTab.value === GRADIENT_TAB && !selectedPointID.value && gradientColor.value) {
    setSelectedPointID([...gradientColor.value.points.keys()][0]);
  }
});

// ==================== Solid Color Picker Control ==================== //
const openColorPicker = ref(false);
const onToggleColorPicker = () => (openColorPicker.value = !openColorPicker.value);
</script>

<template>
  <g-popover
    ref="popOver"
    :closeable="true"
    :has-arrow="false"
    :no-shadow-box="true"
    placement="bottom-end"
    class="flex-col"
    wrapper-class="translate-x-[9px] translate-y-[-12px]"
    @close="onClose">
    <div
      class="hover:bg-light-250 border-light-400 group flex h-[64px] cursor-pointer items-center justify-between gap-[12px] rounded-[12px] border p-12 leading-5"
      :class="{
        '!border-primary-300': openColorModal,
        '!border-red-300': isShowInvalid && !color,
      }"
      @click="onOpen">
      <span class="line-clamp-2 text-14">{{ item.label }}</span>
      <div
        :class="`flex h-[40px] w-[40px] shrink-0 justify-center rounded-xl border ${
          color ? 'border-light-500' : 'border-dark-50'
        }`"
        :style="styleObject">
        <g-base-icon v-if="!color" name="plus-bold" width="12.5px" viewBox="0 0 20 20" fill="#212121"></g-base-icon>
      </div>
    </div>
    <template #content="{}">
      <div
        v-if="openColorModal"
        class="rounded-12 z-[999] flex w-[312px] flex-col items-center border bg-white pb-16"
        style="
          box-shadow: 0px 8px 45px 2px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
          border: 1px solid rgba(0, 0, 0, 0.2);
        ">
        <ColorModalHeader
          :theme="THEME_LIGHT"
          :mode="COLOR_PICKER_MODE.BOTH"
          :active-tab="activeTab"
          :tabs="tabs"
          @on-toggle-color-modal="onClose"
          @on-change-tab="onChangeTab" />
        <div class="w-full px-16">
          <template v-if="activeTab === SOLID_TAB">
            <InputColor
              :theme="THEME_LIGHT"
              :value="color"
              :show-transparent-color="showTransparentColor"
              :open-color-picker="true"
              @toggle-color-picker="onToggleColorPicker"
              @change-color="onChangeColor" />
            <ColorPicker
              :key="reRenderColorPickerAgainAfterOpenPopupToRecalculateRectSize.toString()"
              :value="color"
              :is-auto-update-value="true"
              @change-color="onChangeColor" />
          </template>
          <template v-else>
            <GradientPicker :theme="THEME_LIGHT" :gradient-color="gradientColor" @on-remove-point="removePoint" />
            <InputColor
              class="mt-20"
              :theme="THEME_LIGHT"
              :value="selectedPoint?.color.getColor()"
              :show-transparent-color="showTransparentColor"
              :open-color-picker="true"
              @change-color="updateColor" />
            <ColorPicker
              :key="reRenderColorPickerAgainAfterOpenPopupToRecalculateRectSize.toString()"
              :value="selectedPoint?.color.getColor()"
              :is-auto-update-value="true"
              @change-color="updateColor" />
          </template>
        </div>
      </div>
    </template>
  </g-popover>
</template>
