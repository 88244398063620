import type * as Types from '../type/graphql';

export const getUploadCareImageUrlByMimeType = (imageData?: Types.File) => {
  if (imageData?.mimeType == 'image/svg+xml' && imageData?.filePath && imageData?.filePath.includes('ucarecdn')) {
    return `${imageData.filePath}-/format/auto/`;
  }
  return imageData?.filePath || '';
};
export const getImageUrlPreview = (imageData?: Types.File) => {
  const filePath = imageData?.filePath ?? '';
  if (filePath.includes('cdn.shopify.com') && imageData?.mimeType !== 'image/tiff') {
    return filePath.replace(/(\.[^.]+)$/, '_180x180$1');
  } else if (filePath.includes('ucarecdn')) {
    return `${getUploadCareImageUrlByMimeType(imageData)}-/preview/180x180/`;
  } else {
    return filePath;
  }
};
