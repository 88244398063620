<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
  strategy: 'absolute' | 'fixed';
  floating: boolean;
  dropdownPositionX: number;
  dropdownPositionY: number;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'mouse-move'): void;
}>();

const dropdownStyle = computed(() => ({
  position: props.strategy,
  top: props.dropdownPositionY ? `${props.dropdownPositionY}px` : '',
  left: props.floating ? (props.dropdownPositionX ? `${props.dropdownPositionX}px` : '0px') : undefined,
}));

const onClose = () => emit('close');
const onMousemove = () => emit('mouse-move');
</script>

<template>
  <div
    ref="select"
    v-click-away="onClose"
    :style="dropdownStyle"
    class="bg-dark-400 rounded-12 text-16 shadow-2dp sm:text-14 absolute right-0 z-[999] w-[248px] py-8 focus:outline-none"
    @mousemove="onMousemove">
    <slot></slot>
  </div>
</template>
