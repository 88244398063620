<script lang="ts" setup>
import { GButtonV2, GPopover } from '@gem/uikit';
import { ref } from 'vue';

const props = defineProps<{
  title: string;
  showDeleteConfirm: boolean;
  active?: boolean;
}>();

const emit = defineEmits<{
  (event: 'onOpen'): void;
}>();

const isEdit = ref(props.active);

const handleOpen = () => {
  isEdit.value = true;
  emit('onOpen');
};

const handleClose = () => {
  isEdit.value = false;
};
</script>

<template>
  <GPopover
    v-if="!showDeleteConfirm"
    :open="active"
    :has-arrow="false"
    :overlay="false"
    :no-shadow-box="true"
    :closeable="true"
    overlay-container="#root-modal"
    :ignore-outside-class="['compare-select']"
    button-class="!block"
    placement="right-start"
    cls="-top-16 py-4 pl-14"
    :padding-right="-16"
    :class="{
      visible: isEdit,
    }"
    @open="handleOpen"
    @close="handleClose">
    <template #default="{ open }">
      <GButtonV2 icon-size="16" size="normal" :active="open" :type="open ? 'primaryGhost' : 'ghost'" only-icon="edit" />
    </template>

    <template #content="{ open, close }">
      <div class="bg-dark-500 rounded-12 trigger-badge-setting w-[280px] pb-16">
        <div class="flex h-[52px] items-center py-16 pl-16 pr-8">
          <p class="text-text-dark-500 text-14 flex-1 font-semibold leading-6">{{ title }}</p>
          <GButtonV2
            icon-size="16"
            only-icon="close"
            size="semi-medium"
            type="ghost"
            @click="
              () => {
                close();
                handleClose();
              }
            " />
        </div>
        <div class="grid gap-16 px-16">
          <slot v-bind="{ open, close }" />
        </div>
      </div>
    </template>
  </GPopover>
</template>
