<script lang="ts" setup>
import { GButtonV2 } from '@gem/uikit';
import { nanoid } from 'nanoid';
import { ref, onUnmounted } from 'vue';
const props = defineProps<{
  id: string;
  value?: number | string;
  defaultControl: any;
  controlChange?: (id: string, value?: any) => void;
}>();

const timer = ref<number>();
const loading = ref(false);

const change = () => {
  clearTimeout(timer.value);
  loading.value = true;
  props.controlChange?.(props.id, nanoid());
  setTimeout(() => {
    loading.value = false;
  }, 1000);
};
onUnmounted(() => {
  clearTimeout(timer.value);
});
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <GButtonV2
      size="medium"
      type="tertiary"
      button-width="full"
      icon-loading="reload"
      :disable="loading"
      :loading="loading"
      @click.prevent="change">
      {{ defaultControl?.buttonText }}
    </GButtonV2>
    <slot name="info"></slot>
  </div>
</template>
