<script setup lang="ts">
import type { Product } from '@gem/common';
import { replaceImageToSize } from '../../hook/useResizeImage';
import type { PreVariantSelected } from '../modal/MultiProductModal.vue';
import ProductItemTitle from './ProductItemTitle.vue';
import { ref } from 'vue';

const props = defineProps<{
  item: Product;
  isPostPurchase: boolean;
  list?: Product[];
  variantSelected?: PreVariantSelected;
}>();

const emits = defineEmits<{
  (e: 'deleteItem', id: string): void;
  (e: 'openModal'): void;
}>();

const hovered = ref(false);
</script>

<template>
  <div
    class="rounded-medium bg-dark-500 group-[.sortable-fallback]/sortable-item:bg-dark-500 group-[.sortable-ghost]/sortable-item:bg-dark-400 group-[&:not(.dragging)]/sortable:hover:bg-dark-400 group flex h-[48px] w-[248px] cursor-ns-resize items-center gap-8 p-8"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false">
    <img
      v-if="item?.image"
      :alt="item?.title"
      class="rounded-medium flex aspect-square h-32 w-32 shrink-0 overflow-hidden object-cover"
      :src="replaceImageToSize(item?.image ?? '', '100x100') ?? item?.image" />
    <g-base-icon
      v-else
      class="text-light-450 flex-none"
      width="36px"
      height="36px"
      view-box="0 0 24 24"
      name="no-image" />
    <div>
      <p v-if="!isPostPurchase" class="text-light-100 font-regular line-clamp-2 text-12">
        {{ item?.title }}
      </p>
      <ProductItemTitle
        v-else
        :item="item"
        :variant-selected="variantSelected"
        :list="list"
        :hovered="hovered"
        @open-modal="emits('openModal')" />
    </div>
    <GButtonV2
      type="ghost"
      class="text-light-450 ml-auto hidden h-32 w-32 flex-none items-center justify-center !p-0"
      :class="{ 'group-hover:flex': list && list.length > 1 }"
      @click="() => emits('deleteItem', ((item as any)?.id))">
      <g-base-icon name="close-round" width="16" height="16" viewBox="0 0 16 16"></g-base-icon>
    </GButtonV2>
  </div>
</template>
