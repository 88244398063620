<template v-if="active == true">
  <div id="gt_text_format_color" style="position: relative" class="gt_text_format_color item">
    <button
      type="button"
      role="button"
      aria-pressed="false"
      class="gt_text_format_color_scope bg-dark-300 border-dark-200 hover:bg-dark-50 flex items-center justify-center border"
      @click="setFormat('.gt_text_format_color')">
      <svg
        fill="currentColor"
        class="fr-svg"
        focusable="false"
        width="22"
        height="22"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.2,13.494s-3.6,3.9-3.6,6.3a3.65,3.65,0,0,0,7.3.1v-.1C18.9,17.394,15.2,13.494,15.2,13.494Zm-1.47-1.357.669-.724L12.1,5h-2l-5,14h2l1.43-4h2.943A24.426,24.426,0,0,1,13.726,12.137ZM11.1,7.8l1.86,5.2H9.244Z" />
      </svg>
    </button>
    <colorBoardEditor
      ref="colorBoardEditorComponent"
      class-button=".gt_text_format_color"
      type="text-color"
      :color="colorText"
      :position-popup-color="positionPopup"
      @status-display-color-board="statusDisplayColor"
      @set-color-text-board="setColorText"
      @clear-color-text-board="clearColorText" />
  </div>
</template>
<script>
import colorBoardEditor from './popup/colorBoardEditor.vue';
export default {
  name: 'FormatColorText',
  components: {
    colorBoardEditor,
  },
  props: {
    status: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    color: String,
  },
  emits: ['clear-format-text', 'status-format-text'],
  data() {
    return {
      active: false,
      statusDisplayColorBoard: false,
      colorText: '',
      positionPopup: {},
    };
  },
  watch: {
    color(newVal, oldVal) {
      if (oldVal != newVal) {
        this.colorText = newVal;
      }
    },
  },
  created() {
    this.active = this.status;

    this.colorText = this.$props.color;
  },
  mounted() {
    const $optionElement = document.getElementById('gt_text_format_color');
    this.positionPopup = {
      left: $optionElement.offsetLeft - 25,
    };
  },
  methods: {
    statusDisplayColor(value) {
      this.statusDisplayColorBoard = value;
    },
    clearColorText(type) {
      if (type == 'clear_color') {
        this.$emit('clear-format-text', {
          type: 'foreColor',
        });
      }
    },
    setColorText(value) {
      const $parrantButton = event.target.closest('.gt_text_format_color');
      this.$emit('status-format-text', {
        type: 'foreColor',
        classButton: $parrantButton,
        color: value,
        value: value.color_code,
      });
    },
    setFormat() {
      if (this.statusDisplayColorBoard == true) {
        this.$refs.colorBoardEditorComponent.setStatus(false);
      } else {
        this.$refs.colorBoardEditorComponent.setStatus(true);
      }
    },
  },
};
</script>
<style lang="postcss" scoped>
.gt_text_format_color_scope {
  cursor: pointer;
  width: theme('spacing.32');
  height: theme('spacing.32');
  border-radius: theme('borderRadius.medium');
}
</style>
