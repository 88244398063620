const deviceArray = ['desktop', 'tablet', 'mobile'];
type Order = {
  desktop?: number | undefined;
  tablet?: number | undefined;
  mobile?: number | undefined;
};
export const getOrderItem = (order: Order, currentDevice: keyof Order): number => {
  const indexDevice = deviceArray.indexOf(currentDevice);
  if (typeof order?.[currentDevice] !== 'undefined') return order?.[currentDevice] || 0;
  if (indexDevice <= 0) return 0;
  return getOrderItem(order, deviceArray[indexDevice - 1] as keyof Order);
};
