<script lang="ts" setup>
import { computed, ref } from 'vue';
import { GInput } from '@gem/uikit';
import { useInfiniteScroll } from '@vueuse/core';
import type { PageLinkData } from './types';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';

const emits = defineEmits<{
  (e: 'saveSetting', value: { id: string; title: string; link: string }): void;
  (e: 'change-keyword', input: string): void;
  (e: 'load-more'): void;
  (e: 'closeSetting'): void;
}>();

const props = defineProps<{
  id?: string;
  title?: string;
  link?: string;
  pages?: PageLinkData[];
  sections?: PageLinkData[];
}>();

const searchText = ref('');
const searchSectionText = ref('');
const el = ref<HTMLElement>();
const title = ref(props.title);
const link = ref(props.link);

const clear = () => {
  searchText.value = '';
};
const clearSectionSearch = () => {
  searchSectionText.value = '';
};

const filterSections = computed(() => {
  return props.sections?.filter((v) => v.title?.toLowerCase().includes(searchSectionText.value.toLowerCase()));
});

const onSearch = () => {
  emits('change-keyword', searchText.value);
};

const handleSaveSetting = () => {
  emits('saveSetting', { id: props.id ?? '', title: title.value ?? '', link: link.value ?? '' });
};

const close = () => {
  emits('closeSetting');
};

const onPageItemClick = (item: PageLinkData) => {
  if (item.handle) {
    link.value = item.handle;
  }
};

useInfiniteScroll(
  el,
  () => {
    emits('load-more');
  },
  { distance: 10 },
);
</script>

<template>
  <g-modal
    :hide-overlay="true"
    :show-btn-close="false"
    :is-open="true"
    label-ok="Save"
    @cancel="close"
    @ok="handleSaveSetting">
    <template #title>Edit Menu Item</template>
    <div class="w-[650px] px-16">
      <div class="flex flex-col">
        <g-input :value="title" type="text" size="small" @on-change="(val) => (title = val)">
          <template #label> <span class="text-14 mb-8 font-medium">Title</span> </template>
        </g-input>
      </div>
      <div class="my-8 flex flex-col">
        <g-input :value="link" type="text" size="small" @on-change="(val) => (link = val)">
          <template #label> <span class="text-14 mb-8 font-medium">Link</span> </template>
        </g-input>
      </div>
      <div>
        <TabGroup>
          <TabList class="inline-flex gap-8">
            <Tab v-slot="{ selected }" as="template"
              ><button
                class="text-12 hover:text-dark-500 border-b px-32 py-8 outline-none transition-all hover:border-b-green-300"
                :class="{
                  'text-dark-500 border-b-green-300': selected,
                  'text-text-dark-300 border-b-transparent': !selected,
                }">
                Pages
              </button></Tab
            >
            <Tab v-slot="{ selected }" as="template"
              ><button
                class="text-12 hover:text-dark-500 border-b px-32 py-8 outline-none transition-all hover:border-b-green-300"
                :class="{
                  'text-dark-500 border-b-green-300': selected,
                  'text-text-dark-300 border-b-transparent': !selected,
                }">
                Scroll To
              </button></Tab
            >
          </TabList>
          <TabPanels>
            <TabPanel>
              <div>
                <div class="relative w-full">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-16">
                    <g-base-icon name="search" width="16" height="16"></g-base-icon>
                  </div>
                  <input
                    v-model="searchText"
                    type="text"
                    class="text-12 bg-light-400 text-text-light-100 block h-[44px] w-full p-8 pl-[48px] outline-none"
                    placeholder="Search ..."
                    @input="onSearch" />
                  <button
                    type="button"
                    class="absolute inset-y-0 right-0 flex cursor-pointer items-center px-16 transition-all"
                    :class="{
                      'visible opacity-100': !!searchText.length,
                      'invisible opacity-0': !searchText.length,
                    }"
                    @click="clear">
                    <svg
                      class="h-16 w-16 text-gray-500 dark:text-gray-400"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 11L21 21M21 11L11 21" stroke="#676767" stroke-width="2" stroke-linecap="round" />
                      <circle cx="16" cy="16" r="13" stroke="#676767" stroke-width="2" />
                    </svg>
                  </button>
                </div>
                <perfect-scrollbar ref="el">
                  <div class="h-[320px]">
                    <ul>
                      <li
                        v-for="item in pages"
                        :key="item.id"
                        class="text-14 flex h-[50px] cursor-pointer items-center gap-4 border px-8 even:bg-slate-50 hover:border-green-300"
                        :class="{
                          'border-green-300': item.handle === link,
                          'border-transparent': item.handle !== link,
                        }"
                        @click="onPageItemClick(item)">
                        <svg width="32" height="32" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M17.75 2a2.25 2.25 0 0 1 2.245 2.096L20 4.25v15.498a2.25 2.25 0 0 1-2.096 2.245l-.154.005H6.25a2.25 2.25 0 0 1-2.245-2.096L4 19.748V4.25a2.25 2.25 0 0 1 2.096-2.245L6.25 2h11.5Zm0 1.5H6.25a.75.75 0 0 0-.743.648L5.5 4.25v15.498c0 .38.282.694.648.743l.102.007h11.5a.75.75 0 0 0 .743-.648l.007-.102V4.25a.75.75 0 0 0-.648-.743L17.75 3.5ZM7 15.749a.75.75 0 0 1 .75-.75h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Zm0-8a.75.75 0 0 1 .75-.75h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Zm0 4a.75.75 0 0 1 .75-.75h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Z" />
                        </svg>
                        <span class="block">{{ item.title }}</span>
                      </li>
                    </ul>
                  </div>
                </perfect-scrollbar>
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <div class="relative w-full">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-16">
                    <g-base-icon name="search" width="16" height="16"></g-base-icon>
                  </div>
                  <input
                    v-model="searchSectionText"
                    type="text"
                    class="text-12 bg-light-400 text-text-light-100 block h-[44px] w-full p-8 pl-[48px] outline-none"
                    placeholder="Search ..." />
                  <button
                    type="button"
                    class="absolute inset-y-0 right-0 flex cursor-pointer items-center px-16 transition-all"
                    :class="{
                      'visible opacity-100': !!searchText.length,
                      'invisible opacity-0': !searchText.length,
                    }"
                    @click="clearSectionSearch">
                    <svg
                      class="h-16 w-16 text-gray-500 dark:text-gray-400"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 11L21 21M21 11L11 21" stroke="#676767" stroke-width="2" stroke-linecap="round" />
                      <circle cx="16" cy="16" r="13" stroke="#676767" stroke-width="2" />
                    </svg>
                  </button>
                </div>
                <perfect-scrollbar ref="el">
                  <div class="h-[320px]">
                    <ul>
                      <li class="flex w-full items-center bg-white px-16">
                        <div class="text-primary-300 mr-20 h-24 w-24">
                          <g-base-icon name="info" width="24px" height="24px" />
                        </div>
                        <div class="my-16 flex flex-col items-start gap-16 overflow-hidden">
                          <p class="text-14 font-regular text-text-light-500 w-full truncate">
                            You can use a link to scroll to any section on current page.
                          </p>
                          <a
                            class="text-14 font-regular text-text-light-500 inline-block truncate underline"
                            href="https://help.gempages.net/articles/create-anchor-link"
                            target="_blank">
                            Watch Tutorials
                          </a>
                        </div>
                      </li>
                      <li
                        v-for="item in filterSections"
                        :key="item.id"
                        class="text-14 flex h-[50px] cursor-pointer items-center gap-4 border px-8 even:bg-slate-50 hover:border-green-300"
                        :class="{
                          'border-green-300': `#${item.id}` === link,
                          'border-transparent': `#${item.id}` !== link,
                        }"
                        @click="onPageItemClick({ handle: `#${item.id}`, id: item.id })">
                        <svg width="32" height="32" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M17.75 2a2.25 2.25 0 0 1 2.245 2.096L20 4.25v15.498a2.25 2.25 0 0 1-2.096 2.245l-.154.005H6.25a2.25 2.25 0 0 1-2.245-2.096L4 19.748V4.25a2.25 2.25 0 0 1 2.096-2.245L6.25 2h11.5Zm0 1.5H6.25a.75.75 0 0 0-.743.648L5.5 4.25v15.498c0 .38.282.694.648.743l.102.007h11.5a.75.75 0 0 0 .743-.648l.007-.102V4.25a.75.75 0 0 0-.648-.743L17.75 3.5ZM7 15.749a.75.75 0 0 1 .75-.75h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Zm0-8a.75.75 0 0 1 .75-.75h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Zm0 4a.75.75 0 0 1 .75-.75h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Z" />
                        </svg>
                        <span class="block">{{ item.title }}</span>
                      </li>
                    </ul>
                  </div>
                </perfect-scrollbar>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  </g-modal>
</template>
