<script setup lang="ts">
import { shapes } from './shapes';

defineProps<{
  value?: string;
}>();

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'change', shapeId: string): void;
}>();

const onChange = (shapeId: string) => {
  emits('change', shapeId);
  emits('close');
};
</script>

<template>
  <div class="bg-dark-400 rounded-xxl w-[248px]">
    <div class="list-badge grid h-[320px] grid-cols-1 gap-8 overflow-y-auto p-8">
      <div
        v-for="shape in shapes"
        :key="shape.id"
        class="size-[100px] flex h-[60px] cursor-pointer items-center overflow-hidden rounded-xl border-2 hover:bg-white/20"
        :data-shape="shape.id"
        :class="[shape.id === value ? 'border-primary-300 border' : 'border-dark-300']"
        @click="onChange(shape.id)">
        <component :is="shape.image" />
        <p class="text-12 ml-8 text-xs">{{ shape.name }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="postcss" scoped>
.list-badge::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

.list-badge::-webkit-scrollbar-thumb:vertical:hover {
  width: 12px;
  cursor: pointer;
}
.list-badge::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
  width: 16px;
}

.list-badge::-webkit-scrollbar-thumb {
  min-height: 20px;
}

.list-badge::-webkit-scrollbar-thumb {
  border-radius: 10px;
  @apply bg-text-dark-300;
}
</style>
