<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import { icons } from './icons';
import type { SpacingOption } from './types';
import { createInitVal, createNumber } from './commons';
import { inject } from 'vue';
import { MouseUpKey, StateListKey } from './keys';
import { changePointerEvent } from '../../helpers/common';

type Prop = {
  id?: string;
  item: {
    class: string;
    pos: string;
    property: string;
  };
  options: SpacingOption[];
  value?: string;
  isOpenSelectSpacing?: boolean;
  fillInside?: string;
};

const props = defineProps<Prop>();
const emits = defineEmits(['inside-mouseleave', 'inside-mouseover', 'change-diff-value', 'click']);
const { changeStateListOnFocus } = inject(StateListKey, { stateList: {}, changeStateListOnFocus: () => {} });
const { detectMouseUp } = inject(MouseUpKey, { mouseUp: ref({}), detectMouseUp: () => {} });
const initVal = ref(createInitVal(props.value)); // initial value
const valueGlobal = computed(() => props.options.find((item) => item.id === initVal.value));
const number = ref(createNumber(initVal.value, valueGlobal.value));

function onMouseDown(event: MouseEvent) {
  changeStateListOnFocus(props.item?.property ?? '');
  changePointerEvent();
  if ((event.target as any).tagName !== 'INPUT') {
    window.addEventListener('mousemove', onMouseMove);
  }

  window.addEventListener('mouseup', onMouseUp);
}

function onMouseMove(event: MouseEvent) {
  const speedY = event.movementY;
  number.value = (number.value === 'Auto' ? 0 : +number.value - speedY * 2).toString();

  emits('change-diff-value', props.item?.property, number.value);
}

function onMouseUp() {
  changePointerEvent(false);
  detectMouseUp(props.item?.property ?? '');
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', onMouseUp);
}

function withIcon(iconName: string) {
  return icons[iconName as keyof typeof icons];
}

function handleMouseOver() {
  emits('inside-mouseover', props.item?.property);
}

function handleMouseLeave() {
  emits('inside-mouseleave', props.item?.property);
}
function handleClick() {
  emits('click', props.item?.property);
}

watch(
  () => props.value,
  (newVal) => {
    initVal.value = createInitVal(newVal);
    number.value = createNumber(initVal.value, valueGlobal.value);
  },
);
</script>

<template>
  <component
    :is="withIcon(item.property)"
    :color="fillInside"
    @click="handleClick"
    @mousedown="onMouseDown"
    @mouseleave.stop="handleMouseLeave"
    @mouseover.stop="handleMouseOver" />
</template>

<style scoped>
path:hover,
g:hover {
  fill: #333333 !important;
}
</style>
