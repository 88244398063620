const cssUnit = '(v[h|w|min|max]|p[c|t|x]|[re|e|c|m]m|[l|c]h|%|in|Q|ex)';

const cssUnitRegex = new RegExp(cssUnit);

export const DEFAULT_UNIT = ['px', 'em', 'rem', '%', 'vh'];
export const DEFAULT_MONEY_CURRENCY = ['$', '%'];

export function isCssValue(key: string | number): boolean {
  return cssUnitRegex.test(parseUnit(key)[1]);
}

export function parseUnit(key?: string | number): [number | string | undefined, string] {
  if (key === undefined) return [undefined, ''];
  if (typeof key === 'number') return [key, ''];
  key = String(key);
  if (key.toLowerCase() === 'auto') return ['Auto', ''];
  if (key.toLowerCase() === 'original') return ['Original', ''];
  if (key.toLowerCase() === 'any') return ['Any', ''];
  const parseVal = parseFloat(key);
  const num = isNaN(parseVal) ? undefined : parseVal;
  const unit = (key.match(/[\d.\-+]*\s*(.*)/) as RegExpMatchArray)[1];

  return [num, unit];
}

export default parseUnit;
